import GuppyChatManager from "../../resources/controller";
import jQuery from 'jquery'
import Vue from "vue";
import RestApiManager from "../../resources/RestApiController";
export const GroupState = {
    groupMemberOffset  : 0,
    groupId       	   : true,
    noMoreGroupMemeber : false,
    groupMemberFlag    : false,
    isEditGroup        : false,
    groupMembers       : [],
    initGroupMembers   : [],
    deleteGroupMember : {
        'userName': '',
        'memberId'  : '',
        'groupId' : '',
    },
    groupDetail : {
        'disableReply'  : '',
        'groupTitle'    : '',
        'memberIds'     : [],
        'adminIds'      : [],
        'userId'        : window.wpguppy_scripts_vars.userId,
        'isEditGroup'   : false
    }
}
export const GroupMutations = {
  updateGroupChatData(state, payload){
    let chatId = payload.chatId;

    if( state.messagesList[chatId] ){
      state.messagesList[chatId].messages.push(payload.chatData);
      if(jQuery('#message-wrap_'+chatId).length){
        jQuery('#message-wrap_'+chatId).animate({ scrollTop: jQuery('#message-wrap_'+chatId)[0].scrollHeight}, 800);
      }
    }

    if( state.groupConversation[chatId] ){
      let  isMute = state.groupConversation[chatId].muteNotification;
      if(!state.userProfile.muteNotification && !isMute){
        GuppyChatManager.playNotificationBell();
      }
    }
  },
  editGroupDetail(state, payload){
      let chatInfo = '';
      if(state.isMessangerChat){
        chatInfo = state.chatInfo
      } else if( payload.chatId ){
        chatInfo = state.chatInfo[payload.chatId];
      }
      if(payload.resetGroup){
        let groupInfo = {
          'disableReply'  : false,
          'groupTitle'    : '',
          'memberIds'     : [],
          'adminIds'      : [],
          'userId'        : this.userId,
          'isEditGroup'   : false,
          'removeImage'   : 0,
        }
        Vue.set(state, 'groupDetail', groupInfo)
      } else {
        Vue.set(state, 'groupId', Math.floor(Math.random() * 100000))
      
				if(state.initGroupMembers.length){
          Vue.set(state, 'groupMembers', state.initGroupMembers)
				} else if( !state.groupMembers.length || !state.groupMemberFlag){
					RestApiManager.getGroupMemberList(false); //isScrolling
				}

        let memberIds= [];
        let adminIds = [];
        if( chatInfo.groupDetail ) {
          let memberAvatars = Object.values(chatInfo.groupDetail.memberAvatars)
          let userAdmins  = memberAvatars.filter(member => Number(member.groupRole) == 2 );
              memberIds   = memberAvatars.map(member => member.userId);
              if( userAdmins.length ) {
                adminIds = userAdmins.map( user => user.userId )
              }
        }

        let groupData = {
          'disableReply'  : chatInfo.groupDetail ? chatInfo.groupDetail.disableReply : '',
          'groupTitle'    : chatInfo.groupTitle,
          'memberIds'     : memberIds,
          'adminIds'      : adminIds,
          'userId'        : state.userId,
          'isEditGroup'   : true,
          'removeImage'   : 0,
        }
        Vue.set(state, 'groupDetail', groupData)
      }
  },
}
export const GroupActions = {
}