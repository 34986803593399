export const stickyButtonStyle = () => {
    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        zIndex: '1',
        margin: "0",
        position: "absolute",
        left: "11px",
        height: "calc(100% - 2px)",
        width: "58px",
        cursor: "pointer",
        borderRight: "1px solid #ddd",
        justifyContent: "center",
    };
};
export const emojiPickerStyle = () => {
    return {
        position: "absolute",
        bottom: "100%",
        left: "0",
        zIndex: "2",
        width: "280px",
        backgroundColor : '#ffffff !important'
    };
};
export const composerIconStyle = (isBlockedByMe) => {
    return {
        width: "100%",
        height: "100%",
        paddingLeft: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: isBlockedByMe ? 0.4 : 1,
        img: {
            width: "23px",
            height: "23px",
            display: "block",
            cursor: isBlockedByMe ? "default" : "pointer",
        },
    };
};
