<template>
  <li class="at-userbar" 
  :class="{ 'active' : chatInfo.chatId == ConversationItemProps.chatId } " @click.prevent="selectUserChat()">
        <!-- for group chat avatars -->
        <figure v-if="ConversationItemProps.chatType == 2" class="at-userbar_profile" :class="avatarImageClass" >
            <template v-if="ConversationItemProps.groupImage">
                <img :src="ConversationItemProps.groupImage" :alt="ConversationItemProps.groupTitle" />
            </template>
            <template v-else-if="ConversationItemProps.groupDetail">
                <template v-if="ConversationItemProps.groupDetail.totalMembers > 4">
                    <img 
                        v-for="(image, index) in groupMembersList.slice(0,3)" 
                        :key="index+ '_' + Math.floor(Math.random() * 99999999)" 
                        :src="image.userAvatar ? image.userAvatar : defaultAvatar" 
                        :alt="image.userName" />
                </template>
                <template v-else>
                    <img 
                        v-for="(image, index) in groupMembersList" 
                        :key="index+ '_' + Math.floor(Math.random() * 99999999)" 
                        :src="image.userAvatar ? image.userAvatar : defaultAvatar" 
                        :alt="image.userName" />
                </template>
                <em v-if="ConversationItemProps.groupDetail.totalMembers > 4" class="at-notify">+</em>
            </template>
        </figure>
        <!-- for one-to-one chat avatar -->
        <figure v-else class="at-userbar_profile" :class="{'at-blockuser' : ConversationItemProps.isBlocked}">
            <i v-if="ConversationItemProps.isBlocked" class="guppy-slash"></i>
            <span v-else class="at-userstatus" :class="ConversationItemProps.isOnline ? 'online' : 'offline' "></span>
            <img :src="ConversationItemProps.userAvatar ? ConversationItemProps.userAvatar : defaultAvatar" alt="profile image" />
        </figure>
        <!-- for group display name and slogan message -->
        <div class="at-userbar_title" v-if="ConversationItemProps.chatType == 2">
            <h3 v-if="ConversationItemProps.groupTitle"> {{ConversationItemProps.groupTitle}} </h3>
            <!-- when clear chat is false -->
            <template v-if="!ConversationItemProps.clearChat">
                <span v-if="ConversationItemProps.messageType == 0" >
                    <template v-if="ConversationItemProps.isSender">
                        <template v-if="ConversationItemProps.messageStatus == 2">
                            <i class="guppy-slash"></i>{{TRANS.deleted_message}}
                        </template>
                        <template v-else>
                            {{TRANS.you_txt}}:&nbsp;{{ ConversationItemProps.message }}
                        </template>
                    </template>
                    <template v-else>
                        <!-- memberDisable kery for leave group and show left message -->
                        <template v-if="ConversationItemProps.memberDisable">
                            {{ ConversationItemProps.message }}
                        </template>
                        <template v-else-if="ConversationItemProps.messageStatus == 2">
                            <i class="guppy-slash"></i>{{TRANS.deleted_message}}
                        </template>
                        <template v-else>
                            {{ConversationItemProps.userName}}:&nbsp;{{ ConversationItemProps.message }}
                        </template>
                    </template>
                </span>
                <span v-if="ConversationItemProps.messageType == 1" >
                    <i class="guppy-paperclip"></i>
                    {{ConversationItemProps.isSender ? TRANS.you_sent_attachment : TRANS.grp_sent_attachment.replace('((username))',ConversationItemProps.userName) }}
                </span>
                <span v-if="ConversationItemProps.messageType == 2" >
                    <i class="guppy-map-pin"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_location : TRANS.grp_sent_location.replace('((username))',ConversationItemProps.userName) }}
                </span>	
                <span v-if="ConversationItemProps.messageType == 3">
                    <i class="guppy-mic"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_voice_note : TRANS.you_sent_voice_note.replace('((username))',ConversationItemProps.userName) }}
                </span>
                <!-- for notify messages  -->
                <span v-if="Number(ConversationItemProps.messageType) == 4"> 
                    <span>{{notifyMessage(ConversationItemProps)}}</span>
                </span>
            </template>
        </div>
        <!-- for user display name and slogan message -->
        <div v-else class="at-userbar_title">
            <h3 v-if="ConversationItemProps.userName">
                {{ConversationItemProps.userName}}
            </h3>
            <!-- when clear chat is false -->
            <template v-if="!ConversationItemProps.clearChat">
                <span v-if="Number(ConversationItemProps.messageStatus) == 2 "><i class="guppy-slash"></i>{{TRANS.deleted_message}}</span>	
                <span v-else-if="Number(ConversationItemProps.messageType) == 0 ">{{ConversationItemProps.message}}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 1 "><i class="guppy-paperclip"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_attachment : TRANS.sent_you_attachment }}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 2 "><i class="guppy-map-pin"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_location : TRANS.sent_you_location }}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 3 "><i class="guppy-mic"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_voice_note : TRANS.sent_you_voice_note }}</span>	
                <!-- for notify messages  -->
                <span v-if="Number(ConversationItemProps.messageType) == 4"> 
                    <span v-html="notifyMessage(ConversationItemProps)"></span>
                </span>
            </template>
        </div>
        <div v-if="ConversationItemProps.clearChat == false" class="at-userbar_right">
            <span v-if="ConversationItemProps.timeStamp">{{getMessageTime(ConversationItemProps.timeStamp)}}</span>
            <em v-if="Number(ConversationItemProps.UnreadCount) > 0 && Number(ConversationItemProps.UnreadCount) < 100" class="at-notify">{{ConversationItemProps.UnreadCount}}</em>
            <em v-else-if="Number(ConversationItemProps.UnreadCount) > 99" class="at-notify">99+</em>
        </div>
    </li>
</template>

<script>
import GuppyChatManager from "../../../resources/controller";
import { mapState } from "vuex";
import {GUPPYCHAT_CONSTANTS} from "../../../resources/constants";
export default {
    name : "ConversationListItem",
    props:['ConversationItemProps'],
    data(){
        return {
            defaultAvatar : GUPPYCHAT_CONSTANTS.AVATAR,
        }
    },
    computed :{
        groupMembersList() {
            let info = this.ConversationItemProps;
            let activeMembers = [];
            if (info.groupDetail ) {
                var members  = Object.values(info.groupDetail.memberAvatars);
                activeMembers  = members.filter(member => Number(member.memberStatus) == 1 );
            }
            return activeMembers;
        },
        avatarImageClass: function(){
            let data = this.ConversationItemProps;
            let imageClass = '';
            if(data.chatType == 2 ){
                if(!data.memberDisable){
                    if( !data.groupImage ){
                        if(data.groupDetail.totalMembers > 2){
                            imageClass = 'at-groupuser_twoplus';
                        } else if(data.groupDetail.totalMembers == 2){
                            imageClass = 'at-groupuser_img';
                        }
                    }
                }
            }
            return imageClass;
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.ConversationItemProps.chatId)
        },
        ...mapState({
            userProfile: state => state.userProfile,
        })
    },
    methods:{
        deleteBPGroup(){
            return this.$store.getters.deleteBPGroup();
        },
        notifyMessage(data){
            let chatType          = data.chatType;
            let memberDisable     = data.memberDisable
            let notifymessageData = data.message;
            let notifyType        = Number(notifymessageData.type);
            let translation       = this.TRANS;
            let membersUpdate     = data.membersUpdate;
            let textMessage       = '';
            if( chatType == 2 ) {
                if( notifyType == 1 || notifyType == 6 ){  // 1- for create new group, 6- update group and 8-> for delete bp group
                    let transText = notifyType == 1 ? translation.group_created_notify : translation.group_updated_notify; // last for 6 type
                    if(data.isSender){
                        return transText.replace('((username))', translation.you)
                    } else {
                        return transText.replace('((username))',data.userName)
                    }
                } else if( notifyType == 2 || notifyType == 3 || notifyType == 5 || notifyType == 7 ){ // 2-add group member, 3-removeMembers, 5-Update member role and 7- join group member
                    let notifyMemberIds     = notifymessageData.memberIds;
                    let totalRemovedMember  = notifyMemberIds.length; 
                    let userName            = '';
                    let transText           = notifyType == 2 ? translation.add_grp_member_txt :notifyType == 5 ? translation.updt_grp_role : notifyType == 7 ? translation.join_grp_member_txt : translation.remove_grp_member_txt; // last for typ 3
                    notifyMemberIds.forEach( (userId, index) => {
                        if( membersUpdate[userId] ){
                            if( index < 4 ){
                                userName += membersUpdate[userId];
                                if( index <= ( totalRemovedMember - 4 ) ){
                                    userName +=', '
                                }
                            } 
                            if(index == 4 ){
                                userName += translation.grp_other_membert_txt.replace('((counter))', totalRemovedMember-4);
                            }
                        }
                    });
                    textMessage = transText.replace('“((username))”', userName);

                    if( data.isSender ) {
                        textMessage = textMessage.replace('Admin', translation.you);
                    }
                    return  textMessage;
                } else if( notifyType == 4 ) { // Leave Group Member
                    let notifyMemberIds     = notifymessageData.memberIds;
                    if( data.isSender ) {
                        return translation.leave_grp_member_txt.replace('“((username))”', translation.you);
                    } else {
                        return translation.leave_grp_member_txt.replace('“((username))”', membersUpdate[notifyMemberIds[0]]);
                    }
                } else if(memberDisable){
                    return translation.remove_grp_member_txt.replace('“((username))”', this.TRANS.you.toLowerCase())
                }
            } else {  // for one to one chat
                if( notifyType == 1 ){
                    let transText   = data.isSender ? translation.auto_inv_sender_msg : translation.auto_inv_receiver_msg;
                    transText = transText.replace(/\\/g, '');
                    transText = transText.replace('((username))', data.userName)
                    transText = transText.replace('((sender_name))', translation.you);
                    return transText
                }
            }

            return ''

        },
        selectUserChat(){
            let data = this.ConversationItemProps;
            if(data.UnreadCount > 0){
                let dataValues = {
                    chatId      : data.chatId,
                    userId      : this.userId,
                    userType    : this.userType,
                    chatType    : data.chatType,
                }
                this.$store.commit('updateUnseenCounter', this.ConversationItemProps);
                GuppyChatManager.updateSeenMessagesCounter(dataValues);
            }

            if( Object.keys(this.chatInfo).length ) {
                let groupMembers = [];
                if( data.chatType == 2 && data.groupDetail ){
                    for (let [id] of Object.entries(data.groupDetail.memberAvatars)) {
                        if(this.userId != id){
                            groupMembers.push(id);
                        }
                    }
                }

                let payload = {
                        userName   : this.userProfile.userName,
                        chatType   : data.chatType,
                        chatId     : data.chatId,
                        senderId   : this.userId,
                        userType   : this.userType,
                        text       : '',
                        groupMembers,
                    }
                this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
            }
            this.$store.commit('updateChatInfo', {data, userClick : true});
        },
        getMessageTime(messageTimeStamp) {
            return GuppyChatManager.getLongDateTime(messageTimeStamp)
        },
    },
    created(){

    }
}
</script>