import Vue from 'vue';
import GuppyChatManager from "../../resources/controller";
export const WidgetChatState = {
    selectedChatId : '',
}
export const WidgetChatGetters = {
    
}
export const WidgetChatMutations = {
    updateSelectedChatId(state, payload){
        Vue.set( state, 'selectedChatId', payload.chatId )
    },
    closeWidgetChatbox( state, payload ){
        Vue.delete(state.widgetChatMessageInput, payload.chatId)
        Vue.delete(state.chatInfo, payload.chatId);
        Vue.set(state,'messageInput', '');
        Vue.delete(state.quoteMessageId, payload.chatId);
    },
    minimizeWidgetChatbox( state, payload ){
        let chatId = payload.chatId;
        if(payload.isMinimize){
            let record = { ...state.chatInfo[chatId] }
            record.isMinimize = true;
            Vue.delete(state.widgetChatMessageInput, chatId);
            Vue.delete(state.chatInfo, chatId);
            Vue.set(state.chatInfo, chatId, record )
        }else{
            let windowWidth = state.windowWidth;
            let totalWindows = 3;
            if(windowWidth < 1141){
              totalWindows = 1;
            }else if(windowWidth < 1601){
              totalWindows = 2;
            } 
            let openChatWindows = Object.values(state.chatInfo).filter(chat => chat.isMinimize == false);
            if(openChatWindows.length == totalWindows){
                Vue.set( state.chatInfo[openChatWindows[0].chatId],'isMinimize',  true);               
            }
            if(state.chatInfo[chatId].UnreadCount > 0){
                let  userId = state.userId;
                let  userType = state.userType;
                let data = {
                    chatId,
                    chatType    : state.chatInfo[chatId].chatType,
                    userId,
                    userType
                }
                GuppyChatManager.updateSeenMessagesCounter(data);
            }
            let record = { ...state.chatInfo[chatId] }
            record.isMinimize = false;
            Vue.delete(state.chatInfo, chatId);
            Vue.set(state.chatInfo, chatId, record );
            if( state.chatInfo['00_4'] ){
                Vue.delete(state.chatInfo, '00_4');
                Vue.delete(state.widgetChatMessageInput, '00_4');
            }
        }
    }
}