<template>
    <div v-if="MicPermission"  @click.prevent="toggleVoiceRec()" :class="{'at-startrecording' : recording }" class="at-replay_audio record">
        <a href="javascript:void(0);"><i class="guppy-mic"></i></a>
    </div>
    <div v-else @click.prevent="micConnectionAlert()" class="at-replay_audio at-recorddisabled">
        <a href="javascript:void(0);"><i class="guppy-mic"></i></a>
    </div>
</template>

<script>
import RestApiManager   from "../../../resources/RestApiController";
import GuppyChatManager from "../../../resources/controller";

export default {
    name : 'GuppyChatSendVoiceMessage',
    props : {
      'chatId':{
        type: String,
        required : false,
        default : ()=>{
          return null
        }
      },
    },
    data(){
        return {
            MicPermission   : true,
            recording       : false,
            chunks          : [],
            mediaRecorder   : '',
            base64AudioText : '',
        }
    },
    computed:{
        quoteMessage(){
            return this.$store.getters.getQuoteMessage(this.chatId)
        },
        quoteMessageId(){
            return this.$store.getters.getQuoteMessageId(this.chatId)
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.chatId)
        },
    },
    methods:{
        micConnectionAlert(){
            this.alertBox('error', this.TRANS.error_title, this.TRANS.microphone_connection_desc)
        },
        toggleVoiceRec(){
            if(this.MicPermission) {
                this.recording = this.recording ? false : true;
                if( this.recording ) {
                    this.voiceRecord();
                } else {
                    this.stopRecording();
                }
            }
        },
        stopRecording() {
            this.mediaRecorder.stop();
        },
        voiceRecord(){
            if (navigator.mediaDevices.getUserMedia) {
                const constraints = { audio: true };
                navigator.mediaDevices.getUserMedia(constraints).then( this.onSuccess, this.onError);
            } else {
                this.alertBox('error', this.TRANS.error_title, this.TRANS.microphone_connection_desc)
            }
        },
        async sendMessage(data){
            let response = await RestApiManager.sendMessage(data,this.chatInfo.chatId)
            if(response.type == 'error'){
                this.alertBox('error', this.TRANS.error_title, response.message_desc)
            }
        },
        onSuccess(stream) {
            let _this = this;
            _this.mediaRecorder = new MediaRecorder(stream);
            _this.mediaRecorder.start();
            _this.MicPermission = true;
            _this.mediaRecorder.onstop = function() {
            var blobData      = new Blob( _this.chunks);
                blobData      = blobData.slice(0, -1, "audio/aac");
                blobData.name = "voiceNote"+Math.floor(Math.random() * 99999)+".aac";
            var reader        = new FileReader();
                reader.addEventListener("load",() => {
            const newFile = new File([reader.result], blobData.name,blobData);
            let quoteMessageId = _this.quoteMessageId;
            let groupId        = Number(_this.chatInfo.chatType) == 2 ? _this.chatInfo.chatId : '';
            let postId         = Number(_this.chatInfo.chatType) == 0 ? _this.chatInfo.postId : '';
            let randomNum      = Math.floor(Math.random() * 999999999);
            let formData       = new FormData();
                formData.append('userId',       _this.userId);
                formData.append('receiverId',   [1,3].includes(Number(_this.chatInfo.chatType)) ? _this.chatInfo.chatId.split('_')[0] :  Number(_this.chatInfo.chatType) == 0 ? _this.chatInfo.postReceiverId : '');
                formData.append('chatType',     Number(_this.chatInfo.chatType));
                formData.append('groupId',      groupId);
                formData.append('postId',       postId);
                formData.append('messageType',  3);
                formData.append('replyId',      quoteMessageId);
                formData.append('0',            newFile);
                formData.append('userType',      _this.userType)
                formData.append("metaData[randNum]" , randomNum);
                _this.isLoading = true;
                GuppyChatManager.loadMediaMessage(randomNum, _this.chatInfo.chatId)
                
                _this.sendMessage( formData );
                _this.$store.commit('removeQuote', {chatId : _this.chatInfo.chatId});
                _this.$store.commit('updateMessageInput', {text : '', chatId : _this.chatInfo.chatId});
          },false );
          reader.readAsArrayBuffer(blobData);
          _this.chunks = [];         
        }
        this.mediaRecorder.ondataavailable = function(e) {
          _this.chunks.push(e.data);
        }
      },
      onError(err) {
        this.MicPermission = false;
        console.warn('The following warning occured: ' + err);
        this.micConnectionAlert()
      },
    }
}
</script>

<style>

</style>