<template>
  <div class="at-message at-video-message" :class="responsiveClass" :id="'message_'+messageProps.messageId">
    <h5 v-if="!messageProps.isSender && (messageProps.chatType == 2 || messageProps.chatType == 0)">{{messageProps.userName}}</h5>
    <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
      <video-player class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname" >
      </video-player>
      <template v-slot:message_actions v-if="!disableReply">
        <guppy-chat-message-actions 
          @deleteMessageEvent="deleteMessage($event)"
          :isDownloadProps="true"
          :downloadData="messageProps.attachmentsData.attachments"
          :messageProps="messageProps"
        />
      </template>
    </guppy-chat-quote-message-list>
    <template v-else>
      <video-player class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname" >
      </video-player>
      <guppy-chat-message-actions 
        v-if="!disableReply"
        @deleteMessageEvent="deleteMessage($event)"
        :isDownloadProps="true" 
        :downloadData="messageProps.attachmentsData.attachments"
        :messageProps="messageProps"
      />
    </template>
  </div>
</template>

<script>
import GuppyChatMessageActions from "../GuppyChatMessageActions/GuppyChatMessageActions.vue";
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from "vuex";

export default {
    name : "GuppyChatVideoMessage",
    components : {
      GuppyChatMessageActions,
      GuppyChatQuoteMessageList
    },
    props:[ 'messageProps', 'disableReply' ],
    data(){
        return {
          responsiveClass: '',
            playerOptions: {
              height: '200',
              width:'400',
              autoplay: false,
              muted: true,
              language: 'en',
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [{
                type: this.messageProps.attachmentsData.attachments[0].fileType.split('/')[0] == 'video' ? this.messageProps.attachmentsData.attachments[0].fileType : "video/"+this.messageProps.attachmentsData.attachments[0].fileType,
                src: this.messageProps.attachmentsData.attachments[0].file
              }],
            }
        }
    },
    computed:mapState({
      isMessangerChat : state => state.isMessangerChat
    }),
    methods:{
      deleteMessage( data){
        this.$emit('deleteMsgEvt', data )
      },
    },
    mounted(){
      let contentId = document.getElementById('message_'+this.messageProps.messageId);
      if(contentId){
        let contentWidth = contentId.clientWidth
        if(this.isMessangerChat){
          if(contentWidth >= 220 ){
            this.responsiveClass = 'at-chat-msg-220'
          }
        } else {
          if(contentWidth >= 270 ){
            this.responsiveClass = 'at-chat-msg-270'
          } else if(contentWidth >= 190 ){
            this.responsiveClass = 'at-chat-msg-190'
          } else if(contentWidth >= 110 ){
            this.responsiveClass = 'at-chat-msg-110'
          }
        }
      }
    }
  }
</script>

<style>
.at-message.at-video-message{
  padding: 20px;
  vertical-align: middle;
}
.at-video-message .at-messageoption {
    top: 30px;
    right: 36px;
}
.at-video-message > .at-messageoption > a {
    color: #fff;
}
.video-js.vjs-fullscreen{
  padding: 0 !important;
}
.video-js{
  border-radius: 3px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.video-js .vjs-big-play-button{
  top: auto !important;
  left: auto !important;
  height: 58px !important;
  line-height: 58px !important;
  position: relative !important;
  border: 0 !important;
  border-radius: 50% !important;
  width: 58px !important;
  background-color: #fff !important;
}
.video-js:hover .vjs-big-play-button{background-color: #fff !important;}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
  content: "\e9be";
  font-size: 22px;
  font-family: 'guppy-icomoon';
  color: var(--secguppycolor);
}
.vjs-poster{border-radius: 3px;}
</style>