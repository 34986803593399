<template>
  <div class="at-replay_message">
    <div class="at-message-qoute">
      <div class="at-message-qoute_content">
          <div v-if="Number(quoteMsgProps.messageType) == 0" class="at-sendfile">
            <span>{{quoteMsgProps.message}}</span>
          </div>
          <!-- 3 for voiceNote -->
          <div v-else-if="Number(quoteMsgProps.messageType) == 3" class="at-sendfile"> 
            <i class="guppy-mic"></i>
            <span>{{TRANS.voice_note}}</span>
          </div>
          <!-- 2 for location -->
          <l-map v-else-if="Number(quoteMsgProps.messageType) == 2" style="height: 200px; width:400px; border-radius: 3px;" :zoom="zoom" :center="getMapLocation(quoteMsgProps.message)">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="getMapLocation(quoteMsgProps.message)"></l-marker>
          </l-map>
           <!-- 2 for attachment -->
           <template v-else>
              <div class="at-sendfile" v-if="quoteMsgProps.attachmentsData.attachmentType == 'video'">
                <i class="guppy-play"></i>
                <span>{{quoteMsgProps.attachmentsData.attachments[0].fileName}}</span>
              </div>
              <ul v-if="quoteMsgProps.attachmentsData.attachmentType == 'images'" class="at-message_imgs">
                <li v-for="( image, index ) in computedReplyMessage" :key="index+ '_' + Math.floor(Math.random() * 9999)" @click="previewImage(index)">
                  <figure>
                    <img :src="image.thumbnail" alt="attachment image" />
                    <span v-if="quoteMsgProps.attachmentsData.attachments.length > showImages && index == (showImages-1)"> +{{quoteMsgProps.attachmentsData.attachments.length-showImages}}</span>
                  </figure>
                </li>
              </ul>
              <div class="at-sendfile" v-if="quoteMsgProps.attachmentsData.attachmentType == 'audio'">
                <i class="guppy-music"></i>
                <span>{{quoteMsgProps.attachmentsData.attachments[0].fileName}}</span>
              </div>
              <div class="at-sendfile" v-if="quoteMsgProps.attachmentsData.attachmentType == 'file'">
                <i class="guppy-file"></i>
                <span>{{quoteMsgProps.attachmentsData.attachments[0].fileName}}</span>
              </div>
           </template>
      </div>
      <span class="at-remove-quotes" @click.prevent="removeQuoteMessage()"> <i class="guppy-x" /> </span>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';

require("leaflet-fullscreen/dist/leaflet.fullscreen.css");
require("leaflet-fullscreen/dist/Leaflet.fullscreen");
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("@/resources/marker.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  name : "GuppyChatQuotedMessages",
  props:['quoteMsgProps'],
  components : {
    'l-map': LMap,
    'l-tile-layer' : LTileLayer,
    'l-marker': LMarker
  },
  data(){
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [],
      markerLatLng: []
    }
  },
  computed:{
    showImages(){
      let showImages = 3;
      if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
        showImages = 4
      }
      return showImages;
    },
    computedReplyMessage() {
      if(this.quoteMsgProps.attachmentsData && this.quoteMsgProps.attachmentsData.attachmentType == 'images'){
        let showImages = 3;
        if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
          showImages = 4
        }
        return this.quoteMsgProps.attachmentsData.attachments.filter((item, index) => index < showImages);
      } else {
        return null;
      }
    },
  },
  methods:{
    previewImage(index){
      this.$store.commit('updateSelectedChatId', {chatId : this.quoteMsgProps.chatId})
      this.$root.$emit('previewImage', {messageId : `${this.quoteMsgProps.messageId}_${index+1}`})
    },
    getMapLocation(data){
      return [data.latitude, data.longitude];
    },
    removeQuoteMessage(){
        this.$emit('removeQuoteMessage');
    }
  }
}
</script>

<style>
.at-replay_message {
  background-color: #F7F7F7;
  padding: 10px 20px;
  margin: 0 130px 10px 70px;
  border-radius: 10px;
}
.at-replay_message .at-message-qoute {
  margin: 0;
}
.at-message-qoute_content{
  padding: 14px;
  background: #fff;
  border-radius: 3px;
}
.at-message-qoute_content .at-sendfile{
  background: transparent;
  padding: 0;
}
.at-message-qoute .at-sendfile span {
  font-size: 12px;
  line-height: 16px;
  display: block;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>