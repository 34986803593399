<template>
    <div>
      <div class="at-message-qoute">
        <div class="at-message-qoute_content" :class="mediaMessageClass">
        <!-- For media message -->
          <template v-if=" Number(messageProps.messageType) == 1"> 
            <template v-if="messageProps.attachmentsData.attachmentType == 'images'">
              <ul class="at-message_imgs at-messagequote">
                <li v-for="(image, index) in computedReplyMessage" :key="index+ '_' + Math.floor(Math.random() * 999)" @click="previewImage(index)">
                    <figure>
                        <img :src="image.thumbnail" :alt="image.fileName" class="mCS_img_loaded">
                        <span v-if="messageProps.attachmentsData.attachments.length > showImages && index == (showImages-1)"> +{{ messageProps.attachmentsData.attachments.length - showImages}}</span>
                    </figure>
                </li>
              </ul>
            </template>
            <template v-if="messageProps.attachmentsData.attachmentType == 'video'">
              <video-player  class="video-player-box"
                ref="videoPlayer"
                :options="playerOption()"
                :playsinline="true"
                customEventName="customstatechangedeventname" >
              </video-player>
            </template>
            <template v-if="messageProps.attachmentsData.attachmentType == 'audio'">
              <div class="at-sendfile">
                <i class="guppy-music"></i>
                <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
              </div>
            </template>
            <template v-if="messageProps.attachmentsData.attachmentType == 'file'">
              <div class="at-sendfile">
                  <i class="guppy-file"></i>
                  <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
              </div>
            </template>
          </template>
          <template v-else-if=" Number(messageProps.messageType) == 3">
            <guppy-chat-audio :messageId="messageProps.messageId+'_reply'" :audioSource="messageProps.attachmentsData.attachments[0].file" />
          </template>
          <template v-else-if="Number(messageProps.messageType) == 2">
            <l-map style="height: 200px; width:400px; border-radius: 3px;" :zoom="zoom" :center="getMapLocation(messageProps.message)">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker :lat-lng="getMapLocation(messageProps.message)"></l-marker>
            </l-map>
          </template>
          <span v-else>{{messageProps.message}}</span> 
        </div>
      </div>
      <slot></slot>
    </div> 
</template>

<script>
import GuppyChatAudio from "../GuppyChatVoiceMessage/GuppyChatAudio.vue";

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';

require("leaflet-fullscreen/dist/leaflet.fullscreen.css");
require("leaflet-fullscreen/dist/Leaflet.fullscreen");
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("@/resources/marker.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
    name : "GuppyChatQuoteMessageList",
    props:['messageProps', 'chatId'],
    components : {
      'l-map': LMap,
      'l-tile-layer' : LTileLayer,
      'l-marker': LMarker,
      GuppyChatAudio
    },
    data(){
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 15,
            center: [],
            markerLatLng: []
        }
    },
    computed:{
      mediaMessageClass(){
        let className = ''
        if(Number(this.messageProps.messageType) == 2){
          className = 'at-reply-location';
        } else if(Number(this.messageProps.messageType) == 3){
          className = 'at-reply-voice';
        } else if(this.messageProps.attachmentsData){
          let attachmentType = this.messageProps.attachmentsData.attachmentType;
           if( attachmentType == 'video'){
             className = 'at-reply-video';
           } else if( attachmentType == 'file'){
             className = 'at-reply-file';
           } else if( attachmentType == 'images'){
             className = 'at-reply-images';
           }
        }
        return className
      },
      computedReplyMessage() {
          if(this.messageProps.attachmentsData && this.messageProps.attachmentsData.attachmentType == 'images'){
            let showImages = 3;
            if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
              showImages = 4
            } 
              return this.messageProps.attachmentsData.attachments.filter((item, index) => index < showImages);
          } else {
              return null;
          }
      },
      showImages(){
        let showImages = 3;
        if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
          showImages = 4
        }
        return showImages;
      },
    },
    methods:{
      previewImage(index){
        this.$store.commit('updateSelectedChatId', {chatId : this.chatId})
        this.$root.$emit('previewImage', {messageId : `${this.messageProps.messageId}_${index+1}`})
      },
      playerOption(){
          let data = this.messageProps;
          let videoData = data.attachmentsData.attachments[0];
          let srcFileTtype = videoData.fileType.split('/')[0] == 'video' ? videoData.fileType : "video/"+videoData.fileType;

          let playerOptions = {
              height: '200',
              width:'400',
              autoplay: false,
              muted: true,
              language: 'en',
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [{
              type: srcFileTtype,
              src: videoData.file
              }],
          }
          return playerOptions
          
      },
      getMapLocation(data){
          return [data.latitude, data.longitude];
      }
    }
}
</script>