<template>
    <div class="at-message at-locationmap" :class="responsiveClass" :id="'message_'+messageProps.messageId">
      <h5>{{messageProps.userName}}</h5>
      <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
        <l-map style="height: 200px; width:400px; border-radius: 3px;" @ready="loadFullscreen()" :zoom="zoom" :center="center" ref="mymap">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </guppy-chat-quote-message-list>
      <template v-else>
        <l-map  style="height: 200px; width:400px; border-radius: 3px;" @ready="loadFullscreen()" :zoom="zoom" :center="center" ref="mymap">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </template>
    </div>
</template>

<script>
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from "vuex";

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';

require("leaflet-fullscreen/dist/leaflet.fullscreen.css");
require("leaflet-fullscreen/dist/Leaflet.fullscreen");
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("@/resources/marker.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  name: "GuppyLocationMessage",
  components: {
    GuppyChatQuoteMessageList,
    'l-map': LMap,
    'l-tile-layer' : LTileLayer,
    'l-marker': LMarker
  },
  props:['messageProps', 'disableReply'],
  data(){
    return{
      responsiveClass: '',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [ this.messageProps.message.latitude, this.messageProps.message.longitude],
      markerLatLng: [ this.messageProps.message.latitude, this.messageProps.message.longitude]
    }
  },
  computed:mapState({
    isMessangerChat : state => state.isMessangerChat
  }),
  methods:{
    loadFullscreen(){
      const map = this.$refs.mymap.mapObject;
      map.addControl(new window.L.Control.Fullscreen());
    },
    deleteMessage( data){
      this.$emit('deleteMsgEvt', data )
    },
  },
  mounted(){
    let contentId = document.getElementById('message_'+this.messageProps.messageId);
    if(contentId){
      let contentWidth = contentId.clientWidth
      if(this.isMessangerChat){
        if(contentWidth >= 220 ){
          this.responsiveClass = 'at-chat-msg-220'
        }
      } else {
        if(contentWidth >= 270 ){
          this.responsiveClass = 'at-chat-msg-270'
        } else if(contentWidth >= 190 ){
          this.responsiveClass = 'at-chat-msg-190'
        } else if(contentWidth >= 110 ){
          this.responsiveClass = 'at-chat-msg-110'
        }
      }
    }
  }
};
</script>

<style>
.at-locationmap{
  padding: 20px;
  position: relative;
}
.at-locationmap .vue2leaflet-map::before{
  left: 0;
  top: 0;
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  content: '';
  width: 100%;
  height: 100%;
}
.at-locationmap .at-messageoption {
    top: 30px;
    right: 36px;
    position: absolute;
    z-index: 1111;
}
.at-locationmap > .at-messageoption > .at-messageoption_btn{color: #fff;}
</style>