<template>
    <div class="at-leftgroupinfo">
        <span v-html="messageText"></span>
    </div>
</template>

<script>
import {GUPPYCHAT_TRANSLATION} from "../../../resources/constants";
export default {
    name:'GuppyChatNotifyMessage',
    props:['messageProps', 'chatInfo'],
    computed:{
        messageText: function(){
            let data              = this.messageProps;
            let chatType          = data.chatType;
            let notifymessageData = this.messageProps.message;
            let notifyType        = Number(notifymessageData.type);
            let translation       = GUPPYCHAT_TRANSLATION;
            let membersUpdate     = this.messageProps.membersUpdate;
            let textMessage       = '';
            if( chatType == 2 ) {
                if( notifyType == 1 || notifyType == 6){  // 1->for create new group, 6-> group update group and 8-> for delete bp group
                    let transText = notifyType == 1 ? translation.group_created_notify : translation.group_updated_notify; // last for 6 type
                    if(this.messageProps.isSender){
                        return transText.replace('((username))', translation.you)
                    } else {
                        return transText.replace('((username))',"<strong>"+this.messageProps.userName+"</strong>")
                    }
                } else if( notifyType == 2 || notifyType == 3 || notifyType == 5 || notifyType == 7 ){ // 2-> add new group member, 3-> remove group members, 5-> Update group member role and 7-> join group member
                    let notifyMemberIds     = notifymessageData.memberIds;
                    let totalRemovedMember  = notifyMemberIds.length; 
                    let userName            = ''; 
                    let transText           = notifyType == 2 ? translation.add_grp_member_txt : notifyType == 5 ? translation.updt_grp_role : notifyType == 7 ? translation.join_grp_member_txt :  translation.remove_grp_member_txt;
                    notifyMemberIds.forEach( (userId, index, array) => {
                        if( membersUpdate[Number(userId)] ){ 
                            if( index < 4 ){
                                userName += membersUpdate[userId];
                                if( index !== array.length - 1 ){
                                    userName +=', '
                                }
                            } 
                            if(index == 4 ){
                                userName += translation.grp_other_membert_txt.replace('((counter))', totalRemovedMember-4);
                            }
                        }
                    });

                    textMessage = transText.replace('“((username))”', userName);
                    if( this.messageProps.isSender ) {
                        textMessage = textMessage.replace('Admin', translation.you);
                    }
                    return  textMessage;
                } else if( notifyType == 4 ) { // 4-> Leave Group Member 
                    let notifyMemberIds     = notifymessageData.memberIds;
                    if( this.messageProps.isSender ) {
                        return translation.leave_grp_member_txt.replace('((username))', translation.you);
                    } else {
                        return translation.leave_grp_member_txt.replace('((username))', membersUpdate[notifyMemberIds[0]]);
                    }
                }
             } else { // for one to one chat
                if( notifyType == 1 ){
                    let transText = data.isSender ? translation.auto_inv_sender_msg : translation.auto_inv_receiver_msg;
                    transText = transText.replace(/\\/g, '');
                    transText = transText.replace('((sender_name))', translation.you);
                    return transText.replace('((username))', this.chatInfo.userName);
                }
             }
            return ''
        },
    },
    methods:{

    }
}
</script>

<style>
</style>