<template>
  <li class="at-userbar" :class="{ 'active' : chatInfo.chatId == ConversationItemProps.chatId} " @click.prevent="selectUserPostChat()">
       
        <figure class="at-userpost_profile" :class="{'at-blockuser' : ConversationItemProps.isBlocked, 'at-statusonline':ConversationItemProps.isOnline, 'at-statusoffline' : !ConversationItemProps.isOnline}">
            <img :src="ConversationItemProps.userAvatar" class="at-postuserimg" alt="post image" />
            <img :src="ConversationItemProps.postImage" alt="post image" />
        </figure>
        <!-- for user display name and slogan message -->
        <div class="at-userbar_title">
            <h3 v-if="ConversationItemProps.postTitle">
                {{ConversationItemProps.postTitle}}
            </h3>
            <!-- when clear chat is false -->
            <template v-if="!ConversationItemProps.clearChat">
                <span v-if="Number(ConversationItemProps.messageStatus) == 2 "><i class="guppy-slash"></i>{{TRANS.deleted_message}}</span>	
                <span v-else-if="Number(ConversationItemProps.messageType) == 0 "> {{ConversationItemProps.isSender ?  TRANS.you_txt : ConversationItemProps.userName}}:&nbsp;{{ConversationItemProps.message}}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 1 ">{{ConversationItemProps.isSender ?  TRANS.you_txt : ConversationItemProps.userName}}:&nbsp; <i class="guppy-paperclip"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_attachment : TRANS.sent_you_attachment }}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 2 ">{{ConversationItemProps.isSender ?  TRANS.you_txt : ConversationItemProps.userName}}:&nbsp; <i class="guppy-map-pin"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_location : TRANS.sent_you_location }}</span>
                <span v-else-if="Number(ConversationItemProps.messageType) == 3 ">{{ConversationItemProps.isSender ?  TRANS.you_txt : ConversationItemProps.userName}}:&nbsp; <i class="guppy-mic"></i>{{ConversationItemProps.isSender ? TRANS.you_sent_voice_note : TRANS.sent_you_voice_note }}</span>	
                <!-- for notify messages  -->
                <span v-if="Number(ConversationItemProps.messageType) == 4"> 
                    <span v-html="notifyMessage(ConversationItemProps)"></span>
                </span>
            </template>
        </div>
        <div v-if="ConversationItemProps.clearChat == false" class="at-userbar_right">
            <span v-if="ConversationItemProps.timeStamp">{{getMessageTime(ConversationItemProps.timeStamp)}}</span>
            <em v-if="Number(ConversationItemProps.UnreadCount) > 0 && Number(ConversationItemProps.UnreadCount) < 100" class="at-notify">{{ConversationItemProps.UnreadCount}}</em>
            <em v-else-if="Number(ConversationItemProps.UnreadCount) > 99" class="at-notify">99+</em>
        </div>
    </li>
</template>

<script>
    import GuppyChatManager from "../../../resources/controller";
    import { mapState } from "vuex";
    export default {
        name : "postConversationListItem",
        props:['ConversationItemProps'],
        computed :{
            chatInfo(){
                return this.$store.getters.getChatInfo(this.ConversationItemProps.chatId)
            },
            ...mapState({
                userProfile: state => state.userProfile,
            })
        },
        methods:{
            notifyMessage(data){
                let notifymessageData = data.message;
                let notifyType        = Number(notifymessageData.type);
                let translation       = this.TRANS;
                if( notifyType == 1 ){
                    let transText   = data.isSender ? translation.auto_inv_sender_msg : translation.auto_inv_receiver_msg;
                    transText = transText.replace(/\\/g, '');
                    return transText.replace('((username))', data.userName)
                }
                return ''
            },
            selectUserPostChat(){
                let dataValues = this.ConversationItemProps;
                if(dataValues.UnreadCount > 0){
                    
                    let params = {
                        chatId      : dataValues.chatId,
                        userId      : this.userId,
                        userType    : this.userType,
                        senderId    : dataValues.postReceiverId,
                        chatType    : dataValues.chatType,
                    }
                    GuppyChatManager.updateSeenMessagesCounter(params);
                    this.$store.commit('updateUnseenCounter', this.ConversationItemProps);
                }
                let data = this.ConversationItemProps;
                
                if( Object.keys(this.chatInfo).length && this.chatInfo.chatId != data.chatId ){
                    let groupMembers = [];
                    let payload = {
                    userName   : this.userProfile.userName,
                    chatType   : this.chatInfo.chatType,
                    chatId     : this.chatInfo.chatId,
                    senderId   : this.userId,
                    userType   : this.userType,
                    text       : '',
                    groupMembers,
                    }
                    this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
                }
                this.$store.commit('updateChatInfo', {data, userClick : true});
            },
            getMessageTime(messageTimeStamp) {
                return GuppyChatManager.getLongDateTime(messageTimeStamp)
            },
        }
    }
</script>
<style scoped>
    .at-userpost_profile{
        margin: 0;
        flex: none;
        width: 44px;
        height: 44px;
        display: flex;
        position: relative;
        align-items: flex-start;
    }
    .at-userpost_profile + .at-userbar_title{
        margin-left: 15px;
    }
    .at-userpost_profile img:not(.at-postuserimg){
        width: 100%;
        max-width: 36px;
        margin-top: auto;
        border-radius: 50%;
        margin-left: auto;
    }
    .at-chat_previewfloat .at-userpost_profile img:not(.at-postuserimg){
        max-width: 34px; 
    }
    .at-chat_previewfloat .at-postuserimg{
        max-width: 18px;
        height: 18px;
    }
     .at-chat_previewfloat .at-statusoffline:after {
        width: 10px;
        height: 10px;
    }
    .at-chat_previewfloat .at-groupuser_img img {
        margin: 0;
        width: 24px;
        height: 24px;
    }
    .at-postuserimg{
        margin: 0;
        top: 0px;
        left: 2px;
        z-index: 1;
        height: 20px;
        max-width: 20px;
        border-radius: 50%;
        position: absolute;
        vertical-align: top;
        display: inline-block;
        outline: 4px solid #fff;
    }
    .at-postuserimg img{
        max-width: 30.5px;
        border: 4px solid #fff;
    }
    .at-statusonline:after{
        z-index: 1;
        right: -3px;
        content: '';
        bottom: -3px;
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        background: #22C55E;
        border: 2px solid #F7F7F7;
    }
    .at-statusoffline:after{
        z-index: 1;
        right: -3px;
        content: '';
        bottom: -3px;
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        background: #DDDDDD;
        border: 2px solid #F7F7F7;
    }
</style>