<template>
    <div class="at-replay_upload">
        <a href="javascript:void(0);"><i class="guppy-share"></i></a>
        <ul class="at-uploadoption">
          <li>
            <input type="file" :id="`videoupload${chatInfo.chatId}`" :accept="uploadVideoExt.join(',')" @change="handleFileUpload($event, 'uploadVideo')"/>
            <label :for="`videoupload${chatInfo.chatId}`"><i class="guppy-film"></i>{{TRANS.video}}</label>
          </li>
          <li>
            <input type="file" :id="`audio-upload${chatInfo.chatId}`" :accept="uploadAudioExt.join(',')" @change="handleFileUpload($event, 'uploadAudio')"/>
            <label :for="`audio-upload${chatInfo.chatId}`"><i class="guppy-headphones"></i>{{TRANS.audio}}</label>
          </li>
          <li>
            <input type="file" :id="`photo-upload${chatInfo.chatId}`" multiple :accept="uploadImgExt.join(',')" @change=" handleFileUpload($event, 'uploadImg')"/>
            <label :for="`photo-upload${chatInfo.chatId}`"><i class="guppy-image"></i>{{TRANS.photo}}</label>
          </li>
          <li>
            <input type="file" :id="`document-upload${chatInfo.chatId}`" :accept="uploadDocExt.join(',')" @change=" handleFileUpload($event, 'uploadDoc')"/>
            <label :for="`document-upload${chatInfo.chatId}`"><i class="guppy-file"></i>{{TRANS.document}}</label>
          </li>
          <li v-if="shareLocation">
            <a href="javascript:void(0);" @click.prevent="uploadType = 'location', sendLocationMessage()"><i class="guppy-map-pin"></i>{{TRANS.location}}</a>
          </li>
        </ul>
    </div>
</template>

<script>
import { GUPPYCHAT_SETTINGS } from "../../../resources/constants";
import GuppyChatManager from "../../../resources/controller";
import RestApiManager from "../../../resources/RestApiController";
export default {
    name:"GuppyChatSendMediaMessage",
    props : {
      'chatId':{
        type: String,
        required : false,
        default : ()=>{
          return null
        }
      },
    },
    data(){
        return {
            files               : '',
            shareLocation       : GUPPYCHAT_SETTINGS.shareLocation,
            uploadDocExt        : GUPPYCHAT_SETTINGS.fileExt,
            uploadImgExt        : GUPPYCHAT_SETTINGS.imgExt,
            uploadAudioExt      : GUPPYCHAT_SETTINGS.audioExt,
            uploadVideoExt      : GUPPYCHAT_SETTINGS.videoExt,
            uploadDocSize       : GUPPYCHAT_SETTINGS.maxFileSize,
            uploadAudioSize     : GUPPYCHAT_SETTINGS.maxAudioSize,
            uploadImgSize       : GUPPYCHAT_SETTINGS.maxImageSize,
            uploadVideoSize     : GUPPYCHAT_SETTINGS.maxVideoSize,
            maxFileUploads      : window.wpguppy_scripts_vars.maxFileUploads,
        }
    },
    computed: {
      chatInfo(){
        return this.$store.getters.getChatInfo(this.chatId)
      },
      quoteMessage(){
        return this.$store.getters.getQuoteMessage(this.chatId)
      },
      quoteMessageId(){
        return this.$store.getters.getQuoteMessageId(this.chatId)
      }
    },
    methods:{
        handleFileUpload(e, type){
        let formData  = new FormData();
        this.files    = e.target.files;

        if(this.files.length > Number(this.maxFileUploads)){
          this.alertBox('error', this.TRANS.error_title, this.TRANS.max_file_uploads_msg);
          e.target.value = '';
          return ;
        }

        for ( let [key, file] of Object.entries( this.files ) ) {
          let fileExt =  file.name.split('.').pop();
              fileExt = fileExt ? fileExt.toLowerCase() : '';
          let allowFileSize = this[type+'Size']*1024;
          let fileSize = file.size
          if( this[type+'Ext'].includes('.'+fileExt) && fileSize <= allowFileSize){
           formData.append(key, file);
          } else {
            this.alertBox('error', this.TRANS.error_title, this.TRANS.invalid_input_file);
            e.target.value = '';
            return;
          }
        }
        let chatType    = Number(this.chatInfo.chatType);
        let postId      = Number(this.chatInfo.chatType) == 0 ? this.chatInfo.postId : '';
        let receiverId  = [1,3].includes(Number(this.chatInfo.chatType)) ? this.chatInfo.chatId.split('_')[0] :  Number(this.chatInfo.chatType) == 0 ? this.chatInfo.postReceiverId : '';
        let groupId     = Number(this.chatInfo.chatType) == 2 ? this.chatInfo.chatId.split('_')[0] : '';
        let randomNum   = Math.floor(Math.random() * 999999999);
        formData.append('userType',  this.userType);
        formData.append('messageType',  1);
        formData.append('userId',       this.userId);
        formData.append('receiverId',   receiverId);
        formData.append('chatType',     chatType);
        formData.append('groupId',      groupId);
        formData.append('postId',       postId);
        formData.append('replyId',      this.quoteMessageId);
        formData.append('message',      '');
        formData.append("metaData[randNum]" , randomNum);
        this.$store.commit('updateMessageInput', {text : '', chatId : this.chatInfo.chatId});
        this.isLoading    = true;
        GuppyChatManager.loadMediaMessage(randomNum, this.chatInfo.chatId)
        this.sendMessage(formData)
        this.scrollListToBottom();
        this.$store.commit('removeQuote', { chatId : this.chatInfo.chatId });
        e.target.value = '';
      },
      async sendMessage(data){
        let response = await RestApiManager.sendMessage(data,this.chatInfo.chatId)
        if(response.type == 'error'){
          this.alertBox('error', this.TRANS.error_title, response.message_desc)
        }
      },
      sendLocationMessage(){
        if( navigator.geolocation ) {
          let _this = this;
          let quoteMessageId  = _this.quoteMessageId;
          let groupId         = Number(this.chatInfo.chatType) == 2 ? this.chatInfo.chatId : '';
          let postId          = Number(this.chatInfo.chatType) == 0 ? this.chatInfo.postId : '';
				  navigator.geolocation.getCurrentPosition(function(position) {
            let randomNum     =  Math.floor(Math.random() * 999999999);
            let formData      = new FormData();
            formData.append('userId',       _this.userId);
            formData.append('receiverId',   [1,3].includes(Number(_this.chatInfo.chatType)) ? _this.chatInfo.chatId.split('_')[0] :  Number(_this.chatInfo.chatType) == 0 ? _this.chatInfo.postReceiverId : '');
            formData.append('chatType',     _this.chatInfo.chatType);
            formData.append('groupId',      groupId);
            formData.append('postId',       postId);
            formData.append('latitude',     position.coords.latitude);
            formData.append('longitude',    position.coords.longitude);
            formData.append('replyId',      quoteMessageId);
            formData.append('messageType',  2);
            formData.append("metaData[randNum]" , randomNum);
            _this.$store.commit('updateMessageInput', {text : '', chatId : _this.chatInfo.chatId});
            _this.isLoading     = true;
            GuppyChatManager.loadMediaMessage(randomNum, _this.chatInfo.chatId);
            _this.sendMessage(formData)
            _this.$store.commit('removeQuote', {chatId : _this.chatInfo.chatId});
				  }, 
          (error) => {
            console.log(error.message);
          });
        } else {
          this.alertBox('warning', this.TRANS.error_title, this.TRANS.geo_location_error_txt)
        }

      },
      scrollListToBottom(scrollHeight = 0) {
        GuppyChatManager.messagesScrollList(scrollHeight, this.chatInfo.chatId);
      },
    }
}
</script>

<style>

</style>