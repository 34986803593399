<template>
    <div id="messages" class="at-userlist_tab">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <div v-if="Object.keys(conversation).length" class="at-deleteselected">
            <div class="at-groupuserbar at-groupuserbarvtwo">
                <input type="checkbox" id="at_select_groups_chats" name="check" @change.stop="selectAll" v-model="selectAllItems">
                <label for="at_select_groups_chats" class="at-groupuserbar_content">
                     <h6>{{TRANS.select_all_chats_txt}}</h6>
                </label>
            </div>
            <div class="at-deletecheck" v-if="deleteConversations.length" @click.prevent="removeItems()">
                <a href="#">{{TRANS.cancel_txt}}</a>
            </div>
        </div>
        <ul v-if="Object.keys(conversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-conversation-list-item 
                v-for="(item, index) in conversation" 
                :itemProps="item" 
                :key="index+ '_' + Math.floor(Math.random() * 99999999)"
                @deleteItem="deleteItem($event)"
                :deleteConversations="deleteConversations"
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :svgIcon="true" listIcon="group"/> 
        </template>
        <div class="at-redbtnwrapper" v-if="deleteConversations.length && !isLoading" @click.prevent="deleteAllItems()"> 
            <button class="at-btn at-btnred" :disabled="isSavingUpdt" :class="{'at-disable-btn' : isSavingUpdt }"> {{TRANS.delete_select_txt}} <i class="guppy-trash-2"></i></button>
        </div>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatConversationListItem from "./GuppyConversationListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../../resources/RestApiController";
import GuppyChatManager from "../../../../resources/controller";

import { mapState } from 'vuex';
export default {
    name: "GuppyChatGroupList",
    components:{ 
        GuppyChatConversationListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search :'',
            timer  : null,
            selectAllItems  : false,
            deleteConversations : [],
            isSavingUpdt : false
        }
    },
    computed :mapState({
      conversation:        state => state.groupConversation,
      groupConvFlag:     state => state.groupConvFlag,
      groupChatOffset:   state => state.groupChatOffset,
      noMoreGroupChat:   state => state.noMoreGroupChat,
      groupChatSearch:   state => state.groupChatSearch,
    }),
    methods:{
         deleteAllItems(){
             this.$swal({
               icon: 'error',
                text: this.TRANS.are_you_sure,
                showCancelButton: true,
                confirmButtonText: this.TRANS.delet_grp_member_btn,
                confirmButtonColor: '#EF4444',
                cancelButtonText: this.TRANS.not_right_now,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading  = true;
                    let response    = await RestApiManager.deleteChats(this.deleteConversations);
                    this.isLoading  = false;
                    if (response.data.type == "success") { 
                        this.$store.commit('deleteConversation', { 
                            chatIds: this.deleteConversations, 
                            listType  : 'groupConversation',
                            });
                            this.deleteConversations = [];
                            this.$store.commit('toggleMessagesScr',{ isEmptyView : true, isOpenMessagesScr : false });
                            if(!Object.keys(this.conversation).length){
                                this.getConversation()
                            }
                    } else {
                        this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                    }
                }
            })
        },
        selectAll(){
            if(this.selectAllItems){
                let chatIds = Object.keys(this.conversation);
                this.deleteConversations =[ ...new Set([...this.deleteConversations, ...chatIds]) ];
            } else {
                this.deleteConversations = [];
            }
        },
        removeItems(){
            this.deleteConversations = [];
            this.selectAllItems = false;
        },
        deleteItem(chatId){
            let existItem = this.deleteConversations.find(item => item == chatId);
            let action = existItem ? 'remove' : 'add';
            if(action == 'add'){
                this.deleteConversations.push(chatId)
            } else {
                let index = this.deleteConversations.findIndex(item => item == chatId);
                if(index > -1 ){
                    this.deleteConversations.splice(index, 1);
                }
            }
        },
        getGroupMembers(){
            GuppyChatManager.updateGroupMembers();
        },
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMoreGroupChat', RecordListValue: false});
            this.isScrolling        = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'groupChatOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'groupChatSearch', searchValue: this.search});
                this.getConversation();
                this.updateOffset();
            }, 800)
        },
        updateOffset(){
            let currentOffset = this.groupChatOffset + this.RecordLimit;
                this.$store.commit('updateOffset', {offset:'groupChatOffset', offsetValue: currentOffset});
        },
        async getConversation(){
            if ( !this.noMoreGroupChat ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getConversation(this.groupChatOffset, this.search, 2); // 2-for group chat
                this.isLoading  = false;

                if (response.data.type == "success") { 
                    let currentMoreRecord = Object.keys(response.data.guppyMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMoreGroupChat', RecordListValue: currentMoreRecord});
                    if(!this.groupConvFlag){
                        this.$store.commit('updateListFlag', {flag: 'groupConvFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateListRecord( recList ){
            let data = {
                'list'      : recList,
                'listType'  : 'groupConversation',
                'isScrolling' : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getConversation();
                this.updateOffset();
            }
        },
    },
    created(){
        if( !Object.keys(this.conversation).length || !this.groupConvFlag){
            this.getConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.groupChatSearch;
    },
};
</script>