<template>
  <div class="at-userinfo">
    <div class="at-userinfo_title">
      <a href="javascript:void(0);" @click.prevent="backToTabList()" class="at-backtolist"><i class="guppy-chevron-left"></i></a>
      <figure v-if="chatInfo.chatType == 0" class="at-userinfo_title_img">
        <img  :src="chatInfo.postImage" alt="post image" />
      </figure>
      <figure v-else-if="[1,3].includes( Number(chatInfo.chatType))" class="at-userinfo_title_img">
        <img :src="chatInfo.userAvatar ? chatInfo.userAvatar : defaultAvatar" alt="profile image" />
      </figure>
      <figure v-else-if="chatInfo.chatType == 2" class="at-userinfo_title_img" :class="avatarImageClass" >
        <img v-if="chatInfo.groupImage" :src="chatInfo.groupImage" alt="profile image" />
        <template v-else-if="chatInfo.groupDetail">
          <template v-if="!chatInfo.groupImage && chatInfo.groupDetail.totalMembers > 4">
            <img v-for="(avatar,index) in groupMembersList.activeMembers.slice(0,3)" :key="index+ '_' + Math.floor(Math.random() * 9999)" :src="avatar.userAvatar ? avatar.userAvatar : defaultAvatar" alt="profile image" />
          </template>
          <template v-else>
            <img v-for="(avatar,index) in groupMembersList.activeMembers" :key="index+ '_' + Math.floor(Math.random() * 9999)" :src="avatar.userAvatar ? avatar.userAvatar : defaultAvatar" alt="profile image" />
          </template>
        </template>
        <em v-if="!chatInfo.groupImage && chatInfo.groupDetail.totalMembers > 4" class="at-notify">+</em>
      </figure>
      <div class="at-userinfo_title_name">
        <h3 v-if="chatInfo.chatType == 0">{{chatInfo.postTitle}}</h3>
        <h3 v-else-if="[1,3].includes(Number(chatInfo.chatType))">{{chatInfo.userName | capitalize }}</h3>
        <!-- for group case -->
        <h3 v-else-if="chatInfo.chatType == 2">{{chatInfo.groupTitle | capitalize }}</h3>
        <span v-if="[0,1,3].includes(Number(chatInfo.chatType))" class="at-userstatus" :class="chatInfo.isOnline ? 'online' : 'offline'">
          {{chatInfo.isOnline ? TRANS.online : TRANS.offline}}
        </span>
        <!-- for group case  -->
        <p v-else-if="chatInfo.chatType == 2">
          <span v-html="groupMembersList.membersText"></span>
        </p>
      </div>
    </div>
    <template v-if="chatInfo">
      <div class="at-userinfo_settings">
        <a href="javascript:void(0);"><i class="guppy-sliders"></i></a>
      </div>
      <guppy-chat-sidebar-setting />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GuppyChatSidebarSetting from "./GuppyChatSidebarSetting.vue";
import { GUPPYCHAT_TRANSLATION, GUPPYCHAT_CONSTANTS } from "../../../resources/constants";
export default {
  name: "GuppyChatMessagesHeader",
  components: { GuppyChatSidebarSetting },
  data(){
    return {
      defaultAvatar : GUPPYCHAT_CONSTANTS.AVATAR
    }
  },
  computed:{
    chatInfo(){
      return this.$store.getters.getChatInfo();
    },
    ...mapState({
      avatarImageClass: state => {
        let data = state.chatInfo;
        let imageClass = '';
        if(data.chatType == 2 ){
            if(!data.memberDisable){
                if( !data.groupImage ){
                    if(data.groupDetail.totalMembers > 2){
                        imageClass = 'at-groupuser_twoplus';
                    } else if(data.groupDetail.totalMembers == 2){
                        imageClass = 'at-groupuser_img';
                    }
                }
            }
        }
        return imageClass;
      },
      groupMembersList : state => {
        let info = state.chatInfo;
        var memebersName = '';
        var data = {
          'membersText' : '',
          'activeMembers'  : []
        }
        if ( info.chatType == 2 && info.groupDetail && !info.memberDisable ) {
          var members  = Object.values(info.groupDetail.memberAvatars);
          let _activeMembers  = members.filter(member => Number(member.memberStatus) == 1 );
          data.activeMembers  = _activeMembers
          _activeMembers      = _activeMembers.slice(0,5)
          _activeMembers.forEach( (member, index, array) => {
            if(index < 4){
              memebersName +=member.userName;
              if(index !== array.length - 1){
                memebersName +=', ';
              }
            } else if( index == 4 ) {
              memebersName += '<a href="javascript:void(0);" class="at-moreuser">+';
              memebersName += Number(info.groupDetail.totalMembers)-4 +GUPPYCHAT_TRANSLATION.more_text+ '</a>';
            }
          });
          data.membersText = memebersName;
        }
        return data;
      },
    })
  },
  methods:{
    backToTabList(){
      let data = { isEmptyView : true, isOpenMessagesScr : false}
      this.$store.dispatch('toggleMessagesScr', data)
      this.$store.commit('updateChatInfo', {})
    }
  },
};
</script>

<style>
  .at-userinfo {
  padding: 16px 20px;
  display: flex;
  background-color: #fff;
  align-items: center;
  box-shadow: inset 0px -1px 0px #EEEEEE;
}
.at-userinfo_title {
  display: flex;
  align-items: center;
}
.at-userinfo_title_name{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.at-userinfo_title_img {
  flex: none;
  margin:0 15px 0 0 !important;
  max-width: 50px;
}
.at-userinfo_title_img img {
  display: block;
  width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: 50% !important;
}
.at-userinfo_title_name h3 {
  width: 100%;
  word-break: break-word;
  margin: 0;
  font-size: 18px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.at-userinfo_title_name p{
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #999999;
  margin: 0;
}
.at-userinfo_title_name p > span{
  display: block;
}
.at-userinfo_title_name p a{
  color: #3C57E5 !important;
  outline: none !important;
}
.at-userinfo_settings {
  margin-left: auto;
}
.at-userinfo_settings a {
  width: 42px;
  display: block;
  text-align: center;
  line-height: 57px;
  color: var(--secguppycolor);
  font-size: 24px;
  text-decoration: none;
}
.at-userinfo_settings a:hover{color: var(--secguppycolor);}
.at-userstatus {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}
.at-userstatus:before {
  margin-right: 5px;
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.at-userstatus.online:before {
  background-color: #22C55E;
}
.at-userstatus.offline:before {
  background-color: #ddd;
}
.at-userstatus.away:before {
  background-color: #EAB308;
}
.at-userstatus.notify:before {
  background-color: #FF7300;
}
.at-backtolist{
    font-size: 22px;
    color: #000;
    margin-right: 15px;
    line-height: 0.8;
    display: none;
}
.at-chat991 .at-backtolist {
    display: block;
}
</style>