<template>
  <div class="at-chat at-admin-chat" :class="{'wpguppy-rtl' : isRtl}">
    <guppy-chat-sidebar/>
    <guppy-chat-messages />
     <!-- Media gallery -->
    <template>
       <LightBox ref="galleryLightbox" :closeText="TRANS.gallery_close_btn" :previousText="TRANS.gallery_previous_btn" :nextText="TRANS.gallery_next_btn" :nThumbs="15" :showLightBox="false" :media="media"></LightBox>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import GuppyChatSidebar       from "./components/GuppyChatSidebar/GuppyChatSidebar.vue";
import GuppyChatMessages      from "./components/GuppyChatMessages/GuppyChatMessages/GuppyChatMessages.vue";
import { GUPPYCHAT_SETTINGS } from "../resources/constants";
import GuppyChatManager       from "../resources/controller";
import jQuery from "jquery";
import LightBox from 'vue-it-bigger'
require('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
Vue.component('LightBox', LightBox)

export default {
  name: 'AdminApp',
  components: { 
    GuppyChatSidebar,
    GuppyChatMessages,
  },
  data(){
    return {
      isRtl : false,
      windowWidth : '',
      startAt : 0,
      media : []
    }
  },
  computed:{
    galleryItems(){
      return this.$store.getters.galleryItems();
    },
    css: function(){
      return "<style> :root { --primguppycolor: " + GUPPYCHAT_SETTINGS.primaryColor+ "; --secguppycolor: " + GUPPYCHAT_SETTINGS.secondaryColor + "; --terguppycolor: " + GUPPYCHAT_SETTINGS.textColor + "; } </style>"
    }
  },
  watch: {
    windowWidth(newVal, oldVal){
      if(newVal != oldVal && oldVal != ''){
        this.guppyAddClass()
      }
    }
  },
  methods : {
    previewImage(messageId){
      this.media  = this.galleryItems
      let index   = this.galleryItems.findIndex(item => item.messageId == messageId);
      if(index > -1) {
        this.startAt = index;
        this.$refs.galleryLightbox.showImage(index)
      }
    },
    responsive(){
      jQuery(document).on("click",'.conversation-list .at-userbar',function() {
        jQuery(".at-chat_sidebarsetting").removeClass("at-chat_sidebarsettingopen");
        jQuery(".at-chat_messages").removeClass("at-chat_messagesslide");
      });
    },
    guppyAddClass(){
    let isRtlTrue = this.isRtl ? 'wpguppy-rtl' : '';
    jQuery('.at-chat').addClass(isRtlTrue);
	  },
    async startChating(chatId){
      let data = {
            'sendTo'       : chatId,
            'userId'       : this.userId,
            'startChat'    : 1
          }
      this.isLoading = true;
      let response = await GuppyChatManager.sendGuppyInvite(data);
      if(response){
        if( response.data.type == "success" ) {
          if(response.data.autoInvite){
            if(!GUPPYCHAT_SETTINGS.socketEnable){
              this.$store.commit('startChat', {friendData: response.data.friendData, messagelistData : response.data.messagelistData});
            }
          }
        } else if( response.data.type == "error" ) {
          this.$store.commit('toggleMessagesScr',{ isEmptyView : true, isOpenMessagesScr : false });
          let description = response.data.message_desc ? response.data.message_desc : this.TRANS.input_params_err; 
          this.alertBox('error', this.TRANS.error_title, description)
        }
      }
    },
    invalidParamsError(){
      this.alertBox('error', this.TRANS.error_title, this.TRANS.input_params_err)
    }
  },
  mounted(){
    this.responsive();
    this.guppyAddClass();
    this.$root.$on('previewImage', (data)=>{
      this.previewImage(data.messageId)
    });
  },
  
  created(){
    this.$store.state.isAdminChat = true;
    // to open chat in widget boxes
    this.$store.commit('updateWidgetType', { isMessangerChat : true });

    window.addEventListener("resize", ()=>{
      this.windowWidth = jQuery('.at-chat').width();
    });

    jQuery('head').prepend(this.css);
    this.isRtl = GUPPYCHAT_SETTINGS.isRtl;

    jQuery(document).on("click",'.at-admin-chat ul.at-chat_sidebar_footer li',function() {
          jQuery('ul.at-chat_sidebar_footer').css('width','70px');
          jQuery('.at-chat_sidebar').removeClass('at-ovelay');
        });
  }
}
</script>
<style scoped>
  @import url('./components/resources/adminstyle.css');
  @import url('./components/resources/newstyle.css');
  @import url('./components/resources/rtl.css');
</style>
