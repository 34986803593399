<template>
  <div class="at-floatchat_title">
    <figure v-if="chatInfo.chatType == 0" class="at-singleimg">
      <img :src="chatInfo.postImage" alt="profile image" />
      <span class="at-userstatus" :class="chatInfo.isOnline ? 'online' : 'offline'"></span>
    </figure>
    <figure v-else-if="[1,3].includes(Number(chatInfo.chatType))" class="at-singleimg">
      <img :src="chatInfo.userAvatar ? chatInfo.userAvatar : defaultAvatar" alt="profile image" />
      <span class="at-userstatus" :class="chatInfo.isOnline ? 'online' : 'offline'"></span>
    </figure>
    <figure v-else-if="chatInfo.chatType == 2" class="at-singleimg" :class="{'at-multipleimg': !chatInfo.groupImage}" >
      <img v-if="chatInfo.groupImage" :src="chatInfo.groupImage" alt="profile image" />
      <template v-else-if="chatInfo.groupDetail">
        <template v-if="!chatInfo.groupImage && chatInfo.groupDetail.totalMembers > 2">
          <img v-for="(avatar,index) in Object.values(groupMembersList.activeMembers).slice(0,2)" :key="index" :src="avatar.userAvatar" alt="profile image" />
        </template>
        <template v-else>
          <img v-for="(avatar,index) in Object.values(groupMembersList.activeMembers)" :key="index" :src="avatar.userAvatar" alt="profile image" />
        </template>
      </template>
      <span v-if="!chatInfo.groupImage && chatInfo.groupDetail.totalMembers > 2">
          {{chatInfo.groupDetail.totalMembers-2}}+
      </span>
    </figure>
    <h2><em>{{title}}</em></h2>
    <div class="at-floatchat_title-right">
      <div class="at-floatchatdropdownholder">
        <a href="javascript:void(0);" class="at-floatchatdrop" v-click-outside="closeMenu" @click.prevent="toggleMenu()"><i class="guppy-chevron-down"></i></a>
        <div class="at-floatchatdropdown" :id="`dropdown_${chatId}`" style="display:none">
          <div class="at-chat_sidebarsettingcontent at-openmsg">
            <a :href="messangerPage" target="_blank"><i class="guppy-external-link"></i>{{TRANS.open_in_messenger}}</a>
          </div>
          <template v-if="chatInfo.chatType != 3">
            <div class="at-chat_sidebarsettingcontent">
              <h4>{{TRANS.actions}}</h4>
              <template v-if="chatInfo.chatType == 2 && ( getUserRole == 1 || getUserRole == 2 ) && !chatInfo.memberDisable && !isBPGroup" >
                <a @click.prevent="editGroup()" guppy-data-target="#creategroup" href="javascript:void(0);" >{{TRANS.edit_group}}</a>
              </template>
              <a href="javascript:void(0);" @click.prevent="muteNotification()" :class="{'at-disable-btn' : notificationLoader }" > 
                {{muteChatNotify ? TRANS.unmute_conversation : TRANS.mute_conversation}}
              </a>
            </div>
            <div class="at-chat_sidebarsettingcontent">
              <h4>{{TRANS.privacy_settings}}</h4>
              <template v-if="chatInfo.chatType == 0 || chatInfo.chatType == 1">
                <template v-if="chatInfo.isBlocked">
                  <a v-if="chatInfo.blockedId != userId" href="javascript:void(0);" class="at-danger" @click.prevent="updateSelectChatId()" guppy-data-target="#blockuser">
                    {{TRANS.unblock_user}}
                  </a>
                </template>
                <template v-else>  
                  <a href="javascript:void(0);" @click.prevent="updateSelectChatId()" class="at-danger" guppy-data-target="#blockuser">
                    {{TRANS.block_user}}
                  </a>
                </template>
                <a href="javascript:void(0);" v-if="isAllowReportOption" class="at-danger" @click.prevent="updateSelectChatId()" guppy-data-target="#reportchat">
                  {{TRANS.report_user}}
                </a>
              </template>
              <template v-else-if="chatInfo.chatType == 2">
                <a href="javascript:void(0);" v-if="chatInfo.memberDisable" class="at-danger" @click.prevent="updateSelectChatId()" guppy-data-target="#deletegroup">{{TRANS.delete_group_txt}}</a>
                <a href="javascript:void(0);" v-else-if="!isBPGroup" class="at-danger" @click.prevent="updateSelectChatId()" guppy-data-target="#leavegroup">{{TRANS.leave_group_txt}}</a>

                <a href="javascript:void(0);" class="at-danger" @click.prevent="updateSelectChatId()" v-if="isAllowReportOption" guppy-data-target="#reportchat">{{TRANS.report_group}}</a>
              </template>
              <a href="javascript:void(0);" v-if="isAllowClearChat" class="at-danger" guppy-data-target="#clearchat" @click.prevent="updateSelectChatId()">
                {{TRANS.clear_chat}}
              </a>
            </div>
          </template>
        </div>
      </div>
      <a href="javascript:void(0);" class="at-minimize" v-if="!isMobileView" @click.prevent="minimizeWidgetChatbox()"><i class="guppy-minus" :id="'guppy-minus'+chatId"></i></a>
      <a href="javascript:void(0);" class="at-removechat"><i class="guppy-x" @click.prevent="closeWidgetChatbox()" ></i></a>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import { GUPPYCHAT_SETTINGS, GUPPYCHAT_CONSTANTS } from "../../resources/constants";
import RestApiManager from "../../resources/RestApiController";

import { mapState } from "vuex"
export default {
  name : "GuppywidgetChatBoxHeader",
  props:['chatInfo'],
  data(){
    return {
      chatId              : this.chatInfo.chatId,
      defaultAvatar       : GUPPYCHAT_CONSTANTS.AVATAR,
      notificationLoader  : false,
      messangerPage       : GUPPYCHAT_SETTINGS.messangerPage !='' ?  GUPPYCHAT_SETTINGS.messangerPage+GUPPYCHAT_SETTINGS.messangerPageSeprator+'chat_type='+this.chatInfo.chatType+'&chat_id='+this.chatInfo.chatId+'&type=messanger' : window.location.href,
    }
  },
  computed:{
    ...mapState({
      isMobileView        : state => state.isMobileView,
    }),
    getUserRole(){
      return this.$store.getters.getUserRole(this.chatId)
    },
    muteChatNotify(){
      return this.$store.getters.muteChatNotify(this.chatId);
    },
    getMessageInput(){
      return this.$store.getters.getMessageInput(this.chatId);
    },
    isBPGroup(){
      if(this.chatInfo.groupDetail){
        return this.chatInfo.groupDetail.isbpgroup;
      }
      return false;
    },
    title(){
      if( this.chatInfo.chatType == 0 ){
        return this.chatInfo.postTitle
      } else if ( [1,3].includes( Number(this.chatInfo.chatType)) ){
        return this.chatInfo.userName
      } else if( this.chatInfo.chatType == 2){
        return this.chatInfo.groupTitle
      }
      return '';
    },
    groupMembersList(){
      let info = this.chatInfo;
      var data = {
        'membersText' : '',
        'activeMembers'  : []
      }
      if ( info.chatType == 2 && info.groupDetail && !info.memberDisable ) {
        var members  = Object.values(info.groupDetail.memberAvatars);
        let _activeMembers  = members.filter(member => Number(member.memberStatus) == 1 );
        data.activeMembers  = _activeMembers
        _activeMembers      = _activeMembers.slice(0,5)
      }
      return data;
    },
    isAllowClearChat(){
      return GUPPYCHAT_SETTINGS.clearChatOption
    },
    isAllowReportOption(){
      return GUPPYCHAT_SETTINGS.reportUserOption
    }
  },
  methods:{
    updateSelectChatId() {
      this.$store.commit('updateSelectedChatId', {chatId : this.chatInfo.chatId})
      this.toggleMenu();
    },
    closeWidgetChatbox(){
      jQuery(`#floatchat_${this.chatId}`).addClass('at-minimizefloatchat');
      if(this.getMessageInput){
        let groupMembers = [];
        if( this.chatInfo.chatType == 2 && this.chatInfo.groupDetail ){
            for (let [id] of Object.entries(this.chatInfo.groupDetail.memberAvatars)) {
                if(this.userId != id){
                    groupMembers.push(id);
                }
            }
        }
        let payload = {
              userName   : this.$store.state.userProfile.userName,
              chatType   : this.chatInfo.chatType,
              chatId     : this.chatInfo.chatId,
              senderId   : this.userId,
              userType   : this.userType,
              text       : '',
              groupMembers,
            }
        this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
      }
      
      this.$store.commit('closeWidgetChatbox', {chatId: this.chatId })
      if(this.userType == 0){
        this.$root.$emit('toggleSignInWindow', false)
      }
    },
    minimizeWidgetChatbox(){
      jQuery('#guppy-minus'+this.chatId).parents('.at-floatchat').addClass('at-minimizefloatchat');
      this.$store.commit('minimizeWidgetChatbox', {chatId: this.chatId, isMinimize:true });
      if(this.userType == 0){
        this.$root.$emit('toggleSignInWindow', false)
      }
    },
    toggleMenu(){
      jQuery(`#dropdown_${this.chatId}`).slideToggle();
    },
    closeMenu(){
      jQuery(`#dropdown_${this.chatId}`).slideUp();
    },
     editGroup(){
      this.$store.commit('updateSelectedChatId', {chatId : this.chatInfo.chatId})
      this.$store.commit('editGroupDetail', { chatId : this.chatId, resetGroup : false });
      this.toggleMenu();
      },
    async muteNotification(){
        let actionTo = this.chatInfo.chatId.split('_')[0];
				let data = {
					muteType    : 0,
					postId      : this.chatInfo.chatType == 0 ? actionTo : '',
					userId      : this.userId,
					chatId      : this.chatInfo.chatId,
					chatType    : this.chatInfo.chatType,
          groupId     : this.chatInfo.chatType == 2 ? actionTo : '',
          actionTo    : this.chatInfo.chatType == 0 ? this.chatInfo.postReceiverId : actionTo,
				}
				this.notificationLoader = true;
        let response = await RestApiManager.muteNotification(data);
        this.notificationLoader = false;
        if( response.data.type == 'error'){
          this.alertBox('error', this.TRANS.error_title, response.data.desc)
        }
			}
  },

}
</script>