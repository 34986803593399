<template>
    <div class="at-message at-message_gallery" :class="responsiveClass" :id="'message_'+messageProps.messageId">
      <h5>{{messageProps.userName}}</h5>
      <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" :chatId="messageProps.chatId" v-if="messageProps.replyMessage">
        <ul class="at-message_imgs">
          <li v-for="( image, index ) in computedMessage" :key="index" @click="previewImage(index)">
            <figure>
              <img :src="image.thumbnail" :alt="image.fileName" />
              <span v-if="totalRecord > showImages && index == (showImages-1)"> +{{totalRecord-showImages}}</span>
            </figure>
          </li>
        </ul>
      </guppy-chat-quote-message-list>
      <template v-else>
        <ul class="at-message_imgs">
          <li v-for="( image, index ) in computedMessage" :key="index+ '_' + Math.floor(Math.random() * 999)" @click="previewImage(index)">
            <figure>
              <img :src="image.thumbnail" alt="attachment image" />
              <span v-if="totalRecord > showImages && index == (showImages-1)"> +{{totalRecord-showImages}}</span>
            </figure>
          </li>
        </ul>
      </template>
    </div>
</template>

<script>
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from "vuex";
export default {
  name: "GuppyImageMessage",
  props : ['messageProps', 'totalRecord', 'disableReply'],
  components: {
    GuppyChatQuoteMessageList
  },
  data(){
      return {
          responsiveClass: '',
      }
  },
  computed:{
    showImages(){
      let showImages = 3;
      if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
        showImages = 4
      }
      return showImages;
    },
    computedMessage() {
      let showImages = 3;
      if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
        showImages = 4
      } 
      return this.messageProps.attachmentsData.attachments.filter((item, index) => index < showImages);
    },
    computedReplyMessage() {
      if(this.messageProps.replyMessage.attachmentsData.attachmentType == 'images'){
        let showImages = 3;
        if( this.$store.state.isMessangerChat && !this.$store.state.isMobileView) {
          showImages = 4
        }
        return this.messageProps.replyMessage.attachmentsData.attachments.filter((item, index) => index < showImages);
      } else {
        return null;
      }
    },
    computedReplyTotalRecord() {
      if( this.messageProps.replyMessage.attachmentsData.attachmentType == 'images' ){
        return this.messageProps.replyMessage.attachmentsData.attachments.length;
      } else {
        return 0;
      }
    },
    ...mapState({
      isMessangerChat : state => state.isMessangerChat
    })
  },
  methods:{
    previewImage(index){
      this.$store.commit('updateSelectedChatId', {chatId : this.messageProps.chatId})
      this.$root.$emit('previewImage', {messageId : `${this.messageProps.messageId}_${index+1}`})
    },
    deleteMessage( data){
      this.$emit('deleteMsgEvt', data )
    },
  },
  mounted(){
    let contentId = document.getElementById('message_'+this.messageProps.messageId);
    if(contentId){
      let contentWidth = contentId.clientWidth
      if(this.isMessangerChat){
        if(contentWidth >= 220 ){
          this.responsiveClass = 'at-chat-msg-220'
        }
      } else {
        if(contentWidth >= 270 ){
          this.responsiveClass = 'at-chat-msg-270'
        } else if(contentWidth >= 190 ){
          this.responsiveClass = 'at-chat-msg-190'
        } else if(contentWidth >= 110 ){
          this.responsiveClass = 'at-chat-msg-110'
        }
      }
    }
  }
};
</script>

<style>
.at-message img {
  vertical-align: middle;
}
.at-message_imgs {
  list-style: none;
  margin: -5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
}
.at-message_imgs li {
  margin: 0;
  padding: 5px;
  list-style-type: none;
  max-width: 25% !important;
  min-width: 130px;
}
.at-message_imgs figure {
  margin: 0;
  width: 120px;
  background: #f7f7f7;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.at-message_imgs figure img {
  margin: 0;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  max-height: 100%;
  border-radius: 3px;
}
.at-message_imgs figure span {
  border-radius: 3px;
  position: absolute;
  left: 0;
  color: #fff;
  font: 700 26px/30px var(--primchatfont);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.at-message_gallery{
  padding: 20px;
}
.at-message_gallery .at-messageoption{
  top: 30px;
  right: 36px;
  z-index: 1;
}
.at-message_gallery .at-messageoption > a {
    color: #fff;
}
.at-message_gallery .at-messageoption:before{
  position: absolute;
  content: '';
  top: -10px;
  border-radius: 3px 3px 0 0;
  right: -16px;
  width: 120px;
  height: 50px;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 98%,rgba(0,0,0,0) 99%);
}
.at-message_gallery .at-message-qoute_content + .at-messageoption:before{
  display: none;
}
.at-message_gallery .at-message-qoute_content + .at-messageoption > a{color: var(--terguppycolor);}
</style>