<template>
    <div class="at-replay_msg gp-replay_msg">
        <textarea 
          :id="`input-text-message-${chatInfo.chatId}`" 
          ref="messageInputRef" 
          type="text" 
          v-model.trim="messageInput" 
          name="replay" 
          :placeholder="TRANS.type_message"
          autocomplete="off"
          :class="{'at-share-emoji': shareEmoji, 'gp-form-control' : chatId == '00_4', 'at-form-control at-form-replay' : chatId != '00_4'}"
          ></textarea>
        <div v-if="shareEmoji" class="at-emojibar" :style="styles.stickyButton" ref="emojiPickerWrapperRef">
            <picker
                set="twitter"
                emoji="point_up"
                :data="emojiIndex"
                v-if="showEmojiPicker"
                :style="styles.emojiPicker"
                :title="STRINGS.PICK_YOUR_EMOJI"
                @select="emojiClicked"
            />
            <div :title="STRINGS.EMOJI" :style="styles.composerIcon" @click="toggleEmojiPicker" >
                <img :alt="STRINGS.INSERT_EMOTICON" :style="styles.composerIcon.img" :src="icons[showEmojiPicker ? 'close' : 'emoji']" />
            </div>
        </div>
        <template v-if="!isMobileDevice">
            <a v-if="isMessangerChat" @click.prevent="sendMessage()" href="javascript:void(0)" class="at-sendmsg" ><i class="guppy-send"></i></a>
        </template>
      </div>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import { mapState } from "vuex";
import { GUPPYCHAT_SETTINGS, GUPPYCHAT_CONSTANTS } from "../../../resources/constants";
import GuppyChatManager from "../../../resources/controller";
import RestApiManager   from "../../../resources/RestApiController";
import closeIcon        from "./resources/close.png";
import emojiIcon        from "./resources/emoji.png";
import * as style       from "./style";
import jQuery from "jquery";
let emojiIndex = new EmojiIndex(data);
export default {
    name:'GuppyChatSendTextMessage',
    components :{ Picker },
    props : {
      'chatId':{
        type: String,
        required : false,
        default : ()=>{
          return null
        }
      },
    },
    data(){
        return {
            isMobileDevice      : GUPPYCHAT_SETTINGS.isMobileDevice,
            emojiIndex          : emojiIndex,
            showEmojiPicker     : false,
        }
    },
    computed:{
        shareEmoji(){
            if( this.chatId == '00_4'){
                return false
            } else if( GUPPYCHAT_SETTINGS.shareEmoji ) {
                return true
            } else {
                return false
            }
        },
        STRINGS() {
            return GUPPYCHAT_CONSTANTS;
        },
        messageInput : {
            get () {
                return this.$store.getters.getMessageInput(this.chatId)
            },
            set (value) {
                this.$store.commit('updateMessageInput', { 'text' : value, 'chatId' : this.chatId })
            }
        },
        quoteMessage(){
            return this.$store.getters.getQuoteMessage(this.chatId)
        },
        quoteMessageId(){
            return this.$store.getters.getQuoteMessageId(this.chatId)
        },
        CurrentDate: function(){
            var myDate = new Date();
            let monthsList = ['January ', 'February ', 'March ', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
            let date = myDate.getDate();
            let month = monthsList[myDate.getMonth()];
            let year = myDate.getFullYear();
            return `${month} ${date}, ${year}`;
        },
        icons() {
            return {
                close: closeIcon,
                emoji: emojiIcon,
            }
        },
        styles(){
            return {
                stickyButton: style.stickyButtonStyle(),
                emojiPicker: style.emojiPickerStyle(),
                composerIcon: style.composerIconStyle(this.isBlockedByMe),
            }
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.chatId)
        },
        ...mapState({
            userProfile     : state => state.userProfile,
            isMessangerChat : state => state.isMessangerChat,
            isWhatsappChat : state => state.isWhatsappChat,
            isBlockedByMe   : state => {
                return state.chatInfo.isBlocked
            }
        })
    },
    watch:{
        chatInfo(newVal, oldVal){
            if( this.isMessangerChat && (newVal.chatId != oldVal.chatId)){
                this.isTyping(oldVal);
            }
        },
        isBlockedByMe(newValue) {
            if (newValue) {
            this.showEmojiPicker = false;
            this.showStickerPicker = false;
            }
        },
        messageInput() {
            this.isTyping();
        }
    },
    updated() {
        this.autoHeightInput();
    },
    methods:{
        autoHeightInput(){
            let inputtextId = `#input-text-message-${this.chatInfo.chatId}`;
            var elmnt = document.getElementById(`input-text-message-${this.chatInfo.chatId}`);
            if(elmnt){
                let paddingTop = jQuery(inputtextId).css('padding-top').split('px')[0];
                let paddingBottom = jQuery(inputtextId).css('padding-bottom').split('px')[0];
                let padding = Number(paddingTop) + Number(paddingBottom);
                jQuery(inputtextId).height(0).height( elmnt.scrollHeight - padding);
                if (jQuery(inputtextId).height() >= 60) {
                    jQuery('textarea.at-form-replay').css("overflow", "auto");
                }else {
                    jQuery('textarea.at-form-replay').css("overflow", "hidden");
                }
            }
        },
        isTyping( chatInfoData = this.chatInfo){
            let groupMembers = []; 
            if(chatInfoData.chatType== 2 && chatInfoData.groupDetail){
                for (let [id] of Object.entries(chatInfoData.groupDetail.memberAvatars)) {
                    if(this.userId != id){
                    groupMembers.push(id);
                    }
                }
            }
           
            let payload = {
                userName   : this.userProfile.userName,
                chatType   : chatInfoData.chatType,
                chatId     : chatInfoData.chatId,
                senderId   : this.userId,
                userType   : this.userType,
                text       : this.messageInput,
                groupMembers,
            }
            this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
        },
        toggleEmojiPicker() {
            if(this.isBlockedByMe) return;
            this.showEmojiPicker = !this.showEmojiPicker;
        },
        emojiClicked(emoji) {
            this.$nextTick(() => {
            try {
                if (this.$refs && this.$refs.messageInputRef) {
                const element = this.$refs.messageInputRef;
                var val       = element.value;
                var start     = element.selectionStart, end = element.selectionEnd;
                element.value = val.slice(0, start) + emoji.native + val.slice(end);
                element.focus();
                var caretPos  = start + emoji.native.length;
                element.setSelectionRange(caretPos, caretPos);
                this.setMessageInput(element);
                }
            } catch (error) {
                this.logError("Error in emoji click", error);
            }
            });
        },
        setMessageInput(element) {
            this.messageInput = element.value;
        },
        sendMessage(){
            if( this.chatId == '00_4' ){
                this.openWhatsappChat()
            } else {
                this.sendTextMessage()
            }
        },
        openWhatsappChat(){
            window.open(`https://wa.me/${this.chatInfo.userContact}?text=${this.messageInput}`, '_blank');
        },
        async sendTextMessage(){
            let data = {
                receiverId    : [1,3].includes(Number(this.chatInfo.chatType)) ? this.chatInfo.chatId.split('_')[0] :  Number(this.chatInfo.chatType) == 0 ? this.chatInfo.postReceiverId : '',
                userId        : this.userId,
                chatId        : this.chatInfo.chatId,
                groupId       : Number(this.chatInfo.chatType) == 2 ? this.chatInfo.chatId : '',
                postId        : Number(this.chatInfo.chatType) == 0 ? this.chatInfo.postId : '',
                chatType      : Number(this.chatInfo.chatType),
                message       : this.messageInput,
                isSender      : true,
                replyTo       : '',
                latitude      : '',
                longitude     : '',
                messageType   : 0,
                messageStatus : 0,
                replyId       : this.quoteMessageId,
                timeStamp     : Math.floor(new Date().getTime()/1000),
                userType      : this.userType,
                messageSentTime : this.CurrentDate,
                metaData        : { randNum       : Math.floor(Math.random() * 999999999) },
            }

            if( this.messageInput ) {
                if( this.showEmojiPicker ) {
                    this.showEmojiPicker = false;
                }

                if( this.quoteMessage ) {
                    data["replyMessage"] = this.quoteMessage;
                }

                this.$store.commit("appendMessage", data);
                this.$store.commit('removeQuote', {chatId : this.chatInfo.chatId});
                this.scrollListToBottom();

                this.$store.commit('updateMessageInput', { 'text' : '', 'chatId' : this.chatInfo.chatId });
                let messageInputId = document.getElementById(`input-text-message-${this.chatInfo.chatId}`);
                if(messageInputId){
                    messageInputId.focus();
                }
                let response = await RestApiManager.sendMessage(data, this.chatInfo.chatId);
                if(response.type == 'error'){
                    this.alertBox('error', this.TRANS.error_title, response.message_desc)
                }
            }
        },
        scrollListToBottom(scrollHeight = 0) {
            GuppyChatManager.messagesScrollList(scrollHeight, this.chatInfo.chatId);
        },
    },
    mounted(){
        const ele = document.getElementById(`input-text-message-${this.chatInfo.chatId}`);
        let _this = this;
        ele.addEventListener('keydown', function (e) {
        let keyCode = e.which || e.keyCode;
            if (keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                _this.sendMessage();
            }
        });

        this.$root.$on('sendMessage', (data)=>{
            if(data.chatId == this.chatInfo.chatId){
                this.sendTextMessage()
            }
        })
        this.$root.$on('openWhatsapp', (data)=>{
            if(data.chatId == this.chatInfo.chatId){
                this.openWhatsappChat()
            }
        })
    }
}
</script>

<style scoped>
.at-chat .at-whatsappchat-responsive .at-replay_msg .at-form-replay{
    height: 40px;
    padding: 6px 20px;
    min-height: 40px !important;
}
</style>