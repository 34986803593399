<template>
  <div class="at-message at-messagetext" :class="responsiveClass" :id="'message_'+messageProps.messageId">
    <h5>{{messageProps.userName}}</h5>
    <span class="at-msgload" v-if="messageProps.replyMessage && !messageProps.messageId" ><i class="at-spinericon"></i></span>
    <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
     <span v-linkified>{{messageProps.message}}</span>
    </guppy-chat-quote-message-list>
    <template v-else>
      <span v-linkified>{{messageProps.message}}</span>
    </template>
    
  </div>
</template>

<script>
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from "vuex";
import Vue from 'vue'
import linkify from 'vue-linkify';
Vue.directive('linkified', linkify);
export default {
    name : "GuppyChatTextMessage",
    components : { 
      GuppyChatQuoteMessageList
    },
    props : [ 'messageProps', 'disableReply' ],
    data(){
      return {
        responsiveClass :''
      }
    },
    computed:mapState({
      isMessangerChat : state => state.isMessangerChat
    }),
    methods:{
      deleteMessage( data){
        this.$emit('deleteMsgEvt', data )
      },
      getMapLocation(data){
        return [data.latitude, data.longitude];
      }
    },
    mounted(){
      let contentId = document.getElementById('message_'+this.messageProps.messageId);
      if(contentId){
          let contentWidth = contentId.clientWidth
          if(this.isMessangerChat){
            if(contentWidth >= 220 ){
              this.responsiveClass = 'at-chat-msg-220'
            }
          } else {
            if(contentWidth >= 270 ){
              this.responsiveClass = 'at-chat-msg-270'
            } else if(contentWidth >= 190 ){
              this.responsiveClass = 'at-chat-msg-190'
            } else if(contentWidth >= 110 ){
              this.responsiveClass = 'at-chat-msg-110'
            }
          }
        }
    }

}
</script>

<style>

.at-messages .at-groupuserbar.at-groupuserbarvtwo {
  left: 0;
  top: 20px;
  position: absolute;
}
.at-messages.at-message_sender .at-groupuserbarvtwo {
    top: 20px;
    left: auto;
}
.at-admin-chat .at-message_sender .at-message {border-radius: 20px 20px 0 20px;}
.at-admin-chat .at-message_sender .at-message.at-deletemsg{margin: 0 20px 0 0;}
.at-admin-chat .at-message_sender .at-message_time svg {margin-right: 7px;}
.at-admin-chat .at-message_sender .at-message_time:before {content: none;}
</style>