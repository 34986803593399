<template>
  <li class="at-userbar" :class="{ 'active' : chatInfo.chatId == itemProps.chatId }" @click.stop="selectUserChat()">
        <div class="at-groupuserbar at-groupuserbarvtwo" @click="_stopPropagation">
            <input type="checkbox" @click.self="selectItem" :id="itemProps.chatId" name="check" :checked="itemProps.chatId == deleteItem" :value="itemProps.chatId">
            <label :for="itemProps.chatId" class="at-groupuserbar_content"></label>
        </div>
        <!-- for group chat avatars -->
        <figure v-if="itemProps.chatType == 2" class="at-userbar_profile" :class="avatarImageClass" >
            <template v-if="itemProps.groupImage">
                <img :src="itemProps.groupImage" :alt="itemProps.groupTitle" />
            </template>
            <template v-else-if="itemProps.groupDetail">
                <template v-if="itemProps.groupDetail.totalMembers > 4">
                    <img 
                        v-for="(image, index) in groupMembersList.slice(0,3)" 
                        :key="index+ '_' + Math.floor(Math.random() * 999)" 
                        :src="image.userAvatar ? image.userAvatar : defaultAvatar" 
                        :alt="image.userName"
                        :class="'at-userimage'+(index+1)" />
                </template>
                <template v-else>
                    <img 
                        v-for="(image, index) in groupMembersList" 
                        :key="index+ '_' + Math.floor(Math.random() * 999)" 
                        :src="image.userAvatar ? image.userAvatar : defaultAvatar" 
                        :alt="image.userName" />
                </template>
                <em v-if="itemProps.groupDetail.totalMembers > 4" class="at-notify">+</em>
            </template>
        </figure>
        <!-- for one-to-one chat avatar -->
        <figure v-else class="at-userbar_profile at-multiple-images">
            <img :src="itemProps.senderAvatar ? itemProps.senderAvatar : defaultAvatar" alt="profile image"  class="at-userimage1"/>
            <img :src="itemProps.receiverAvatar ? itemProps.receiverAvatar : defaultAvatar" alt="profile image" class="at-userimage2"/>
        </figure>
        <!-- for group display name and slogan message -->
        <div class="at-userbar_title" v-if="itemProps.chatType == 2">
            <h3 v-if="itemProps.groupTitle"> {{itemProps.groupTitle}} </h3>
            <!-- when clear chat is false -->
            <span v-if="itemProps.messageType == 0" >
                <template v-if="itemProps.isSender">
                    <template v-if="itemProps.messageStatus == 2">
                        <i class="guppy-slash"></i>{{TRANS.deleted_message}}
                    </template>
                    <template v-else>
                        {{TRANS.you_txt}}:&nbsp;{{ itemProps.message }}
                    </template>
                </template>
                <template v-else>
                    <!-- memberDisable kery for leave group and show left message -->
                    <template v-if="itemProps.memberDisable">
                        {{ itemProps.message }}
                    </template>
                    <template v-else-if="itemProps.messageStatus == 2">
                        <i class="guppy-slash"></i>{{TRANS.deleted_message}}
                    </template>
                    <template v-else>
                        {{itemProps.senderName}}:&nbsp;{{ itemProps.message }}
                    </template>
                </template>
            </span>
            <span v-if="itemProps.messageType == 1" >
                <i class="guppy-paperclip"></i>
                {{TRANS.grp_sent_attachment.replace('((username))',itemProps.senderName) }}
            </span>
            <span v-if="itemProps.messageType == 2" >
                <i class="guppy-map-pin"></i>{{TRANS.grp_sent_location.replace('((username))',itemProps.senderName) }}
            </span>	
            <span v-if="itemProps.messageType == 3">
                <i class="guppy-mic"></i>{{TRANS.you_sent_voice_note.replace('((username))',itemProps.senderName) }}
            </span>
            <!-- for notify messages  -->
            <span v-if="Number(itemProps.messageType) == 4"> 
                <span>{{notifyMessage(itemProps)}}</span>
            </span>
           
        </div>
        <!-- for private user display name and slogan message -->
        <div v-else class="at-userbar_title">
            <h3>{{itemProps.senderName | capitalize }} / {{itemProps.receiverName | capitalize }}</h3>
            <!-- when clear chat is false -->
            <span v-if="Number(itemProps.messageStatus) == 2 ">
                <i class="guppy-slash"></i>{{TRANS.deleted_message}}
            </span>	
            <span v-else-if="Number(itemProps.messageType) == 0 ">
                {{itemProps.senderName}}:&nbsp;{{ itemProps.message }}
            </span>
            <span v-else-if="Number(itemProps.messageType) == 1 ">
                <i class="guppy-paperclip"></i>
                {{TRANS.grp_sent_attachment.replace('((username))',itemProps.senderName) }}
            </span>
            <span v-else-if="Number(itemProps.messageType) == 2 ">
                <i class="guppy-map-pin"></i>
                {{TRANS.grp_sent_location.replace('((username))',itemProps.senderName) }}
            </span>
            <span v-else-if="Number(itemProps.messageType) == 3 ">
                <i class="guppy-mic"></i>
                {{TRANS.you_sent_voice_note.replace('((username))',itemProps.senderName) }}
            </span>	
            <!-- for notify messages  -->
            <span v-if="Number(itemProps.messageType) == 4"> 
                <span v-html="notifyMessage(itemProps)"></span>
            </span>
        </div>
        <div class="at-userbar_right" v-if="itemProps.timeStamp">
            <span>{{getMessageTime(itemProps.timeStamp)}}</span>
        </div>
    </li>
</template>

<script>
import GuppyChatManager from "../../../../resources/controller";
import {GUPPYCHAT_CONSTANTS} from "../../../../resources/constants";
import { mapState } from "vuex";
export default {
    name : "ConversationListItem",
    props:['itemProps', 'deleteConversations'],
    data(){
        return {
            defaultAvatar : GUPPYCHAT_CONSTANTS.AVATAR
        }
    },
    computed :{
         groupMembersList() {
            let info = this.itemProps;
            let activeMembers = [];
            if (info.groupDetail ) {
                var members  = Object.values(info.groupDetail.memberAvatars);
                activeMembers  = members.filter(member => Number(member.memberStatus) == 1 );
            }
            return activeMembers;
        },
        avatarImageClass: function(){
            let data = this.itemProps;
            let imageClass = '';
            if(data.chatType == 2 ){
                if(!data.memberDisable){
                    if( !data.groupImage ){
                        if(data.groupDetail.totalMembers > 2){
                            imageClass = 'at-groupuser_twoplus';
                        } else if(data.groupDetail.totalMembers == 2){
                            imageClass = 'at-groupuser_img';
                        }
                    }
                }
            }
            return imageClass;
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.itemProps.chatId)
        },
        deleteItem : function() { 
            let conversations = this.deleteConversations;
            let existItem     = conversations.find(item => item == this.itemProps.chatId);
            return existItem ? existItem : ''
        },
        ...mapState({
            userProfile         : state => state.userProfile,
        })
    },
    methods:{
        _stopPropagation(e){
            e.stopPropagation();
        },
        selectItem(e){
            this.$emit('deleteItem', e.target.value)
        },
        deleteBPGroup(){
            return this.$store.getters.deleteBPGroup();
        },
        notifyMessage(data){
            let chatType          = data.chatType;
            let memberDisable     = data.memberDisable
            let notifymessageData = data.message;
            let notifyType        = Number(notifymessageData.type);
            let translation       = this.TRANS;
            let membersUpdate     = data.membersUpdate;
            let textMessage       = '';

            if( chatType == 2 ) {
                if( notifyType == 1 || notifyType == 6 ){  // 1- for create new group, 6- update group and 8-> for delete bp group
                    let transText = notifyType == 1 ? translation.group_created_notify : translation.group_updated_notify; // last for 6 type
                     return transText.replace('((username))',data.senderName+":")
                } else if( notifyType == 2 || notifyType == 3 || notifyType == 5 || notifyType == 7 ){ // 2-add group member, 3-removeMembers, 5-Update member role and 7- join group member
                    let notifyMemberIds     = notifymessageData.memberIds;
                    let totalRemovedMember  = notifyMemberIds.length; 
                    let userName            = '';
                    let transText           = notifyType == 2 ? translation.add_grp_member_txt :notifyType == 5 ? translation.updt_grp_role : notifyType == 7 ? translation.join_grp_member_txt : translation.remove_grp_member_txt; // last for typ 3
                    notifyMemberIds.forEach( (userId, index) => {
                        if( membersUpdate[userId] ){
                            if( index < 4 ){
                                userName += membersUpdate[userId];
                                if( index <= ( totalRemovedMember - 4 ) ){
                                    userName +=', '
                                }
                            } 
                            if(index == 4 ){
                                userName += translation.grp_other_membert_txt.replace('((counter))', totalRemovedMember-4);
                            }
                        }
                    });
                    textMessage = transText.replace('“((username))”', userName);
                    return  textMessage;
                } else if( notifyType == 4 ) { // Leave Group Member
                    let notifyMemberIds     = notifymessageData.memberIds;
                    return translation.leave_grp_member_txt.replace('“((username))”', membersUpdate[notifyMemberIds[0]]);
                } else if(memberDisable){
                    return translation.remove_grp_member_txt.replace('“((username))”', this.TRANS.you.toLowerCase())
                }
            } else {  // for one to one chat
                if( notifyType == 1 ){
                    let transText   = translation.auto_inv_sender_msg;
                    transText = transText.replace(/\\/g, '');
                    transText = transText.replace('((username))', data.receiverName);
                    transText = transText.replace('((sender_name))', data.senderName);
                    return transText
                }
            }
            return ''
        },
        selectUserChat(){
            let data = this.itemProps;
            if(data.UnreadCount > 0){
                let dataValues = {
                    chatId      : data.chatId,
                    userId      : this.userId,
                    userType    : this.userType,
                    chatType    : data.chatType,
                }
                this.$store.commit('updateUnseenCounter', this.itemProps);
                GuppyChatManager.updateSeenMessagesCounter(dataValues);
            }

            if( Object.keys(this.chatInfo).length ) {
                let groupMembers = [];
                if( data.chatType == 2 && data.groupDetail ){
                    for (let [id] of Object.entries(data.groupDetail.memberAvatars)) {
                        if(this.userId != id){
                            groupMembers.push(id);
                        }
                    }
                }

                let payload = {
                        userName   : this.userProfile.userName,
                        chatType   : data.chatType,
                        chatId     : data.chatId,
                        senderId   : this.userId,
                        userType   : this.userType,
                        text       : '',
                        groupMembers,
                    }
                this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
            }
            this.$store.commit('updateChatInfo', {data, userClick : true});
        },
        getMessageTime(messageTimeStamp) {
            return GuppyChatManager.getLongDateTime(messageTimeStamp)
        },
    },
    created(){

    }
}
</script>