<template>
    <svg :width="imageWidth" :height="imageHeight" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
        <template v-if="ImageColor == 'pin'">
            <path d="M2 1H18V2.42105H2V1Z" :fill=" isActive ? secondaryColor : '#999999' "/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1333 7.56011V2.42105H7.86667V7.56011L4.8633 11.8947H15.1367L12.1333 7.56011ZM18 13.3158H2L6.26667 7.1579V1H13.7333V7.1579L18 13.3158Z" :fill=" isActive ? secondaryColor : '#999999' "/>
            <path d="M9.46667 13.3158H11.0667V18.2895C11.0667 18.6819 10.7085 19 10.2667 19C9.82484 19 9.46667 18.6819 9.46667 18.2895V13.3158Z" :fill=" isActive ? secondaryColor : '#999999' "/>
        </template>
        <template v-else-if="ImageColor =='group'">
            <path d="M1.8 19.3002C1.7 19.3002 1.6 19.3002 1.5 19.2002C1.2 19.1002 1 18.8002 1 18.4002V8.2002C1 6.8002 2.1 5.7002 3.5 5.7002H12.2C13.6 5.7002 14.7 6.8002 14.7 8.2002V13.5002C14.7 14.9002 13.6 16.0002 12.2 16.0002H5.5L2.4 19.1002C2.2 19.2002 2 19.3002 1.8 19.3002ZM3.5 7.3002C3 7.3002 2.7 7.70019 2.7 8.10019V16.4002L4.6 14.5002C4.8 14.3002 5 14.3002 5.2 14.3002H12.2C12.7 14.3002 13 13.9002 13 13.5002V8.2002C13 7.7002 12.6 7.4002 12.2 7.4002H3.5V7.3002Z" :fill=" isActive ? secondaryColor : '#999999' "/>
            <path d="M17.2563 2.44231H5.30005V1H17.2563C18.385 1 19.3 1.86099 19.3 2.92308V11H17.7672V2.92308C17.7672 2.65756 17.5384 2.44231 17.2563 2.44231Z" :fill=" isActive ? secondaryColor : '#999999' "/>
            <path d="M5.30005 1H6.80005V4H5.30005V1Z" :fill=" isActive ? secondaryColor : '#999999' "/>
            <path d="M19.3 10V11.5H16.3V10H19.3Z" :fill=" isActive ? secondaryColor : '#999999' "/>
        </template>
        <path v-else :d="imagePath" :fill="fill"/>
    </svg>
</template>

<script>
    import { GUPPYCHAT_SETTINGS } from "../../../resources/constants";
export default {
    name : 'WhatsappImage',
    props : {
        ImageColor:{
            type : String,
            default : '#25D366',
            required : false
        },
        isActive : {
            type : Boolean,
            default : false,
            required : false
        }
    },
    data(){
        return {
            secondaryColor : GUPPYCHAT_SETTINGS.secondaryColor
        }
    },
    computed:{
        fill : function(){
            return this.ImageColor == 'white' || this.ImageColor == 'lightGreen' ? 'white' : '#25D366';
        },
        imageWidth : function(){
            return this.ImageColor == 'white' ? 44 : this.ImageColor == 'lightGreen' ? 47 : this.ImageColor == 'pin' ? 20 : 20;
        },
        imageHeight : function(){
            return this.ImageColor == 'white' ? 44 : this.ImageColor == 'lightGreen' ? 60 : this.ImageColor == 'pin' ? 20 : 20;
        },
        viewBox: function(){ 
            return this.ImageColor == 'white' ? '0 0 44 44' : this.ImageColor == 'lightGreen' ? '0 0 47 60' : this.ImageColor == 'pin' ? '0 0 20 20' : '0 0 20 20';
        },
        imagePath : function(){
            let path = 'M17.0045 2.90625C15.1339 1.03125 12.6429 0 9.99554 0C4.53125 0 0.0848214 4.44643 0.0848214 9.91071C0.0848214 11.6562 0.540179 13.3616 1.40625 14.8661L0 20L5.25446 18.6205C6.70089 19.4107 8.33036 19.8259 9.99107 19.8259H9.99554C15.4554 19.8259 20 15.3795 20 9.91518C20 7.26786 18.875 4.78125 17.0045 2.90625ZM9.99554 18.1563C8.51339 18.1563 7.0625 17.7589 5.79911 17.0089L5.5 16.8304L2.38393 17.6473L3.21429 14.6071L3.01786 14.2946C2.19196 12.9821 1.75893 11.4688 1.75893 9.91071C1.75893 5.37054 5.45536 1.67411 10 1.67411C12.2009 1.67411 14.2679 2.53125 15.8214 4.08929C17.375 5.64732 18.3304 7.71429 18.3259 9.91518C18.3259 14.4598 14.5357 18.1563 9.99554 18.1563ZM14.5134 11.9866C14.2679 11.8616 13.0491 11.2634 12.8214 11.183C12.5938 11.0982 12.4286 11.058 12.2634 11.308C12.0982 11.558 11.625 12.1116 11.4777 12.2813C11.3348 12.4464 11.1875 12.4688 10.942 12.3438C9.48661 11.6161 8.53125 11.0446 7.57143 9.39732C7.31696 8.95982 7.82589 8.99107 8.29911 8.04464C8.37946 7.87946 8.33929 7.73661 8.27679 7.61161C8.21429 7.48661 7.71875 6.26786 7.51339 5.77232C7.3125 5.29018 7.10714 5.35714 6.95536 5.34821C6.8125 5.33929 6.64732 5.33929 6.48214 5.33929C6.31696 5.33929 6.04911 5.40179 5.82143 5.64732C5.59375 5.89732 4.95536 6.49554 4.95536 7.71429C4.95536 8.93304 5.84375 10.1116 5.96429 10.2768C6.08929 10.442 7.70982 12.942 10.1964 14.0179C11.7679 14.6964 12.3839 14.7545 13.1696 14.6384C13.6473 14.567 14.6339 14.0402 14.8393 13.4598C15.0446 12.8795 15.0446 12.3839 14.9821 12.2813C14.9241 12.1696 14.7589 12.1071 14.5134 11.9866Z';
            if( this.ImageColor == 'white' ){
                path = 'M37.4098 6.39375C33.2946 2.26875 27.8143 0 21.9902 0C9.96875 0 0.186607 9.78214 0.186607 21.8036C0.186607 25.6437 1.18839 29.3955 3.09375 32.7054L0 44L11.5598 40.9652C14.742 42.7036 18.3268 43.617 21.9804 43.617H21.9902C34.0018 43.617 44 33.8348 44 21.8134C44 15.9893 41.525 10.5187 37.4098 6.39375ZM21.9902 39.9437C18.7295 39.9437 15.5375 39.0696 12.758 37.4196L12.1 37.0268L5.24464 38.8241L7.07143 32.1357L6.63929 31.4482C4.82232 28.5607 3.86964 25.2312 3.86964 21.8036C3.86964 11.8152 12.0018 3.68304 22 3.68304C26.842 3.68304 31.3893 5.56875 34.8071 8.99643C38.225 12.4241 40.3268 16.9714 40.317 21.8134C40.317 31.8116 31.9786 39.9437 21.9902 39.9437ZM31.9295 26.3705C31.3893 26.0955 28.708 24.7795 28.2071 24.6027C27.7063 24.4161 27.3429 24.3277 26.9795 24.8777C26.6161 25.4277 25.575 26.6455 25.2509 27.0187C24.9366 27.3821 24.6125 27.4312 24.0723 27.1562C20.8705 25.5554 18.7688 24.2982 16.6571 20.6741C16.0973 19.7116 17.217 19.7804 18.258 17.6982C18.4348 17.3348 18.3464 17.0205 18.2089 16.7455C18.0714 16.4705 16.9813 13.7893 16.5295 12.6991C16.0875 11.6384 15.6357 11.7857 15.3018 11.7661C14.9875 11.7464 14.6241 11.7464 14.2607 11.7464C13.8973 11.7464 13.308 11.8839 12.8071 12.4241C12.3062 12.9741 10.9018 14.2902 10.9018 16.9714C10.9018 19.6527 12.8563 22.2455 13.1214 22.6089C13.3964 22.9723 16.9616 28.4723 22.4321 30.8393C25.8893 32.3321 27.2446 32.4598 28.9732 32.2045C30.0241 32.0473 32.1946 30.8884 32.6464 29.6116C33.0982 28.3348 33.0982 27.2446 32.9607 27.0187C32.833 26.7732 32.4696 26.6357 31.9295 26.3705Z';
            } else if(this.ImageColor == 'lightGreen'){
                path="M38.0134 8.71875C32.4018 3.09375 24.9286 0 16.9866 0C0.593748 0 -12.7455 13.3393 -12.7455 29.7321C-12.7455 34.9688 -11.3795 40.0848 -8.78125 44.5982L-13 60L2.76339 55.8616C7.10268 58.2321 11.9911 59.4777 16.9732 59.4777H16.9866C33.3661 59.4777 47 46.1384 47 29.7455C47 21.8036 43.625 14.3437 38.0134 8.71875ZM16.9866 54.4688C12.5402 54.4688 8.1875 53.2768 4.39732 51.0268L3.5 50.4911L-5.84821 52.942L-3.35714 43.8214L-3.94643 42.8839C-6.42411 38.9464 -7.72321 34.4062 -7.72321 29.7321C-7.72321 16.1116 3.36607 5.02232 17 5.02232C23.6027 5.02232 29.8036 7.59375 34.4643 12.2679C39.125 16.942 41.9911 23.1429 41.9777 29.7455C41.9777 43.3795 30.6071 54.4688 16.9866 54.4688ZM30.5402 35.9598C29.8036 35.5848 26.1473 33.7902 25.4643 33.5491C24.7812 33.2946 24.2857 33.1741 23.7902 33.9241C23.2946 34.6741 21.875 36.3348 21.433 36.8438C21.0045 37.3393 20.5625 37.4062 19.8259 37.0312C15.4598 34.8482 12.5938 33.1339 9.71428 28.192C8.95089 26.8795 10.4777 26.9732 11.8973 24.1339C12.1384 23.6384 12.0179 23.2098 11.8304 22.8348C11.6429 22.4598 10.1562 18.8036 9.54018 17.317C8.9375 15.8705 8.32143 16.0714 7.86607 16.0446C7.4375 16.0179 6.94196 16.0179 6.44643 16.0179C5.95089 16.0179 5.14732 16.2054 4.46428 16.942C3.78125 17.692 1.86607 19.4866 1.86607 23.1429C1.86607 26.7991 4.53125 30.3348 4.89286 30.8304C5.26786 31.3259 10.1295 38.8259 17.5893 42.0536C22.3036 44.0893 24.1518 44.2634 26.5089 43.9152C27.942 43.7009 30.9018 42.1205 31.5179 40.3795C32.1339 38.6384 32.1339 37.1518 31.9464 36.8438C31.7723 36.5089 31.2768 36.3214 30.5402 35.9598Z";
            }

            return path
        },
    }
}
</script>