import dateFormat from "dateformat";

import { GUPPYCHAT_SETTINGS, GUPPYCHAT_TRANSLATION as TRANS } from "../resources/constants"
import moment from "moment";
import Vue from 'vue';
import store from '../vuex/store.js';
import RestApiManager from "./RestApiController";
export default class GuppyChatManager {
  userId      = store.state.userId;
  static updateGroupMembers(){
    store.commit('editGroupDetail', { resetGroup : true });
    store.state.groupId = Math.floor(Math.random() * 100000);
    if( store.state.initGroupMembers.length ){
      store.state.groupMembers = store.state.initGroupMembers;
    } else if( !store.state.groupMembers.length || !this.groupMemberFlag){
      RestApiManager.getGroupMemberList(false); //isScrolling
    }
  }

  static async updateUserStatus( data ) {
    try {
      let response =  await Vue.axios.post('update-user-status', data);
      if(response.data.type =="success"){
        let userData = JSON.parse(JSON.stringify(response.data.userData));
        if(data.statusType == 4 || data.statusType == 3){
          userData['senderUserName']            = store.state.userProfile.userName
          userData['senderUserAvatar']          = store.state.userProfile.userAvatar
          userData['receiverMuteNotification']  = data.muteNotification
          let payload = { userData }
          store.dispatch('triggerSocketEvents', {'event':'updateUser', payload});
        }
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  static async updatePostUserStatus( data ) {
    try {
      let response =  await Vue.axios.post('update-post-user-status', data);
      if(response.data.type =="success"){
        let userData = JSON.parse(JSON.stringify(response.data.userData));
        if(data.statusType == 4 || data.statusType == 3){
          userData['senderUserName']            = store.state.userProfile.userName
          userData['senderUserAvatar']          = store.state.userProfile.userAvatar
          userData['receiverMuteNotification']  = data.muteNotification
          let payload = { userData }
          store.dispatch('triggerSocketEvents', {'event':'updateUser', payload});
        }
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  static async sendGuppyInvite(data){
    try {
      let response =  await Vue.axios.post('send-guppy-invite', data);
      if(response.data.type =="success"){
        if( response.data.autoInvite 
            && response.data.messagelistData 
            && response.data.friendData 
            && response.data.resendRequest ){
          store.commit('startChat', {friendData: response.data.friendData, messagelistData : response.data.messagelistData});
        }
        if(response.data.messagelistData && response.data.chatData){
          let messagelistData = JSON.parse(JSON.stringify(response.data.messagelistData));
          let chatData        = JSON.parse(JSON.stringify(response.data.chatData));
          let userName        = response.data.userName;
          let userAvatar      = response.data.userAvatar;
          let chatType        = response.data.chatData.chatType;
          let groupMembers    = [];
          let userId          = 0;
          let payload = { chatData, messagelistData, groupMembers, userName, userAvatar, userId, chatType }
          store.dispatch('triggerSocketEvents', {'event':'sendMessage', payload});
        }
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  static async getMessangerChatInfo(data){
    try {
      let response =  await Vue.axios.get('get-messenger-chat-info?chatType='+data.chatType+'&chatId='+data.chatId+'&userId='+data.userId);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  static downloadFile( file, fileName){
    var a = document.createElement('a');
    a.href = file;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static getMessageTime(messageTimeStamp) {
    let dateTime = null;
    if( messageTimeStamp ){
      let timeFormatOption  = GUPPYCHAT_SETTINGS.timeFormat;
      let displayFormat     = timeFormatOption == '24hrs' ? 'HH:MM' : 'h:MM tt';
          dateTime          = dateFormat(new Date( messageTimeStamp * 1000), displayFormat);
          if( timeFormatOption == '12hrs' ) {
            dateTime = dateTime.replace('am', TRANS.am_time_txt);
            dateTime = dateTime.replace('pm', TRANS.pm_time_txt);
          }
    }

    return dateTime;
  }

  static getLongDateTime(timeStamp){
    let   timestamp         = null;
    const messageTimestamp  = new Date( timeStamp * 1000);
    var currentMessageDate  = moment(messageTimestamp); 
    var today               = moment().endOf('day');
    let dateDifference      = today.diff(currentMessageDate, 'days');
    if(dateDifference == 0){
      let timeFormatOption  = GUPPYCHAT_SETTINGS.timeFormat;
      let displayFormat     = timeFormatOption == '24hrs' ? 'HH:MM' : 'h:MM tt';
          timestamp         = dateFormat(messageTimestamp, displayFormat);
          if( timeFormatOption == '12hrs' ) {
            timestamp = timestamp.replace('am', TRANS.am_time_txt);
            timestamp = timestamp.replace('pm', TRANS.pm_time_txt);
          }

    } else if( dateDifference == 1 ) {
      timestamp = TRANS.yesterday_time_txt;
    } else if( dateDifference > 1 && dateDifference < 7) {
      timestamp = dateFormat(messageTimestamp, "ddd");
      let days  = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday'];
      days.forEach( day =>{
        timestamp = timestamp.replace( day.slice(0, 3), TRANS[day.toLowerCase()].slice(0, 3) );
      });
    } else {
      timestamp = new Date( timeStamp * 1000).toLocaleDateString();
    }

    return timestamp;
  }

  static updateSeenMessagesCounter(data){
    Vue.axios.post("update-guppy-message",data).then((response) => {
      if( response.data.type == 'success'){
        if( response.data.chatType == 0 
            || response.data.chatType == 1
            || response.data.chatType == 3
            ){
          if(Object.keys(response.data.messageIds).length){
            let payload = {
              senderId          : response.data.senderId,
              chatId            : response.data.chatId,
              messageIds        : response.data.messageIds,
              messageCounter    : response.data.messageCounter,
              chatType          : response.data.chatType,
            }
            store.dispatch('triggerSocketEvents', {'event':'updateMsgStatus', payload});
          }
        }else if(response.data.chatType == 2){
          if(Object.keys(response.data.messageSenders).length ){
            let payload = {
              chatId          : response.data.chatId,
              messageSenders  : response.data.messageSenders,
              chatType        : response.data.chatType,
              messageCounter  : response.data.messageCounter,
              userId          : data.userId,
            }
            store.dispatch('triggerSocketEvents', {'event':'updateMsgStatus', payload});
          }
        } 
      }
    }).catch((errors) => {
      console.log(errors);
    });
  }
    
  static validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  static messagesScrollList(scrollHeight = 0, chatId ) {
    Vue.nextTick(() => {
      let containerId= document.getElementById('message-wrap_' + chatId);
      if (containerId) {
        containerId.scrollTop = containerId.scrollHeight - scrollHeight;
      }
    });
  }

  static playNotificationBell(){
    let bellSrc = GUPPYCHAT_SETTINGS.notificationBellUrl;
    var audio = document.createElement("audio");
    audio.autoplay = true;
    audio.load()
    audio.addEventListener("load", ()=> { audio.play(); }, true);
    audio.src = bellSrc;
  }
  static getChatType( _type ){
    if(typeof _type == 'string' ){
      if( _type == 'private' )
        return 1;
      if( _type == 'group' )
        return 2;
      if( _type == 'post' )
        return 0;
    } else {
      if( _type == 1 )
        return 'private';
      if( _type == 2 )
        return 'group';
      if( _type == 0)
        return 'post';
    }
  }

  static getChatId( chatInfo ){
    let chatId = '';
    let chatType = chatInfo.chatType;
    if(chatType == 1)
      chatId = chatInfo.userId;
    else if(chatType == 2)
      chatId = chatInfo.chatId;
    else if( chatType == 0)
      chatId = chatInfo.postId;

    return Number(chatId);
  }

  static loadMediaMessage( randNum, chatId = "" ){
    let chatInfo = {}
      if(store.state.isMessangerChat){
        chatInfo = { ...store.state.chatInfo };
      } else if( chatId ){
        chatInfo = {...store.state.chatInfo[chatId]};
      }
    let loadingMediaMessage = {
      chatId      : chatInfo.chatId,
      chatType    : Number(chatInfo.chatType),
      messageType : 5,
      isSender    : true,
      timeStamp   : Math.floor(new Date().getTime()/1000),
      metaData    : { randNum },
    }
    if( store.getters.getQuoteMessage ){
      loadingMediaMessage["replyMessage"] = store.getters.getQuoteMessage;
    }
    store.commit("appendMessage", loadingMediaMessage);
  }

  static getCookie( name ) {
    var pair = document.cookie.split('; ').find(x => x.startsWith(name+'='));
    if (pair)
      return pair.split('=')[1]
  }
}
