<template>
    <div id="posts" class="at-userlist_tab at-userlist_tabv2">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <ul v-if="Object.keys(postConversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-posts-conversation-list-item 
                v-for="item in postConversation" 
                :ConversationItemProps="item" 
                :key="item.chatId" 
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :svgIcon="true" listIcon="pin"/> 
        </template>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatPostsConversationListItem from "./GuppyChatPostsConversationListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../resources/RestApiController";
import { mapState } from 'vuex';
export default {
    name: "GuppyChatPostsConversationList",
    components:{ 
        GuppyChatPostsConversationListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search :'',
            timer  : null,
        }
    },
    computed :mapState({
      postConversation          : state => state.postConversation,
      postListFlag      : state => state.postListFlag,
      postListOffset    : state => state.postListOffset,
      noMorePostUsers   : state => state.noMorePostUsers,
      postUserSearch    : state => state.postUserSearch,
    }),
    methods:{
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMorePostUsers', RecordListValue: false});
            this.isScrolling        = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'postListOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'postUserSearch', searchValue: this.search});
                this.getPostConversation();
                this.updateOffset();
            }, 800)
        },
        async getPostConversation(){
            if ( !this.noMorePostUsers ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getpostList(this.postListOffset,this.search)
                this.isLoading  = false;
                if (response.data.type == "success") {
                    let currentMoreRecord = Object.keys(response.data.guppyPostMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMorePostUsers', RecordListValue: currentMoreRecord});
                    if(!this.postListFlag){
                        this.$store.commit('updateListFlag', {flag: 'postListFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyPostMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateOffset(){
            let currentOffset = this.postListOffset + this.RecordLimit;
            this.$store.commit('updateOffset', {offset:'postListOffset', offsetValue: currentOffset});
        },
        updateListRecord( recList ){
            let data = {
                'list'      : recList,
                'listType'  : 'postConversation',
                'isScrolling' : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getPostConversation();
                this.updateOffset();
            }
        },
    },
    created(){
        if( !Object.keys(this.postConversation).length || !this.postListFlag){
            this.getPostConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.postUserSearch;
    },
};
</script>