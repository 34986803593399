<template>
  <div :class="isWhatsappChat ? 'gp-chat_sidebar' : 'at-chat_sidebar'">
    <whatsapp-List v-if="isWhatsappChat"/>
    <template v-else-if="userId && Number(userType)" >
      <guppy-caht-user-list v-if="activeTab == 'contacts'" />
      <guppy-chat-friend-list v-else-if="activeTab == 'friends'" />
      <guppy-chat-block-user-list v-else-if="activeTab == 'blocked'" />
      <guppy-chat-private-list v-else-if="activeTab == 'private_chats' || activeTab == 'messages'" />
      <guppy-chat-group-list v-else-if="activeTab == 'groups'" />
      <guppy-chat-posts-conversation-list v-else-if="activeTab == 'posts'" />
      <customer-support v-else-if="activeTab == 'customer_support'"/>
      <guppy-chat-profile-setting v-else-if="activeTab == 'profile'" />
      <guppy-chat-sidebar-footer />
    </template>
    <template v-else-if="userId && !Number(userType)">
      <customer-support/>
      <guppy-chat-sidebar-footer />
    </template>
  </div>
</template>

<script>
import GuppyChatPostsConversationList     from "./GuppyChatPostsConversationList/GuppyChatPostsConversationList.vue";
import GuppyChatWhatsappConversationList  from "./GuppyChatWhatsappConversationList/GuppyChatWhatsappConversationList.vue";
import GuppyChatSidebarFooter     from "./GuppyChatSidebarFooter/GuppyChatSidebarFooter.vue";
import GuppyChatBlockUserList     from "./GuppyChatBlockUserList/GuppyChatBlockUserList.vue";
import GuppyCahtUserList          from "./GuppyChatUsersList/GuppyChatUsersList.vue";
import GuppyChatFriendList        from "./GuppyChatFriendList/GuppyChatFriendList.vue";
import { GUPPYCHAT_SETTINGS }     from "./../../resources/constants";
import RestApiManager             from "../../resources/RestApiController";
import GuppyChatProfileSetting    from "./GuppyChatProfileSetting/GuppyChatProfileSetting.vue";
import GuppyChatPrivateList       from "./GuppyChatConversationList/GuppyChatPrivateList.vue";
import GuppyChatGroupList         from "./GuppyChatConversationList/GuppyChatGroupList.vue";
import CustomerSupport               from "./GuppyChatCustomerSupport/CustomerSupport.vue";
import { mapState }               from 'vuex';
export default {
    name : "GuppyChatSidebar",
    components: {
      GuppyCahtUserList,
      GuppyChatSidebarFooter,
      GuppyChatFriendList,
      GuppyChatBlockUserList,
      GuppyChatPostsConversationList,
      GuppyChatProfileSetting,
      GuppyChatPrivateList,
      GuppyChatGroupList,
      CustomerSupport,
      WhatsappList : GuppyChatWhatsappConversationList
    },
    computed :mapState({
      activeTab      : state => state.activeTab,
      isWhatsappChat : state => state.isWhatsappChat,
    }),
    watch:{
      userType(type){
        RestApiManager.getUnReadMessages()
        if(Number(type) == 1){
          RestApiManager.getProfileInfo();
        }
      }
    },
    beforeCreate(){
      let defaultActiveTab = GUPPYCHAT_SETTINGS.defaultActiveTab;
      if(!GUPPYCHAT_SETTINGS.enabledTabs.includes(defaultActiveTab)){
        defaultActiveTab = GUPPYCHAT_SETTINGS.enabledTabs.length && GUPPYCHAT_SETTINGS.enabledTabs[0];
      }
      this.$store.commit('selectTab', defaultActiveTab);
    },
    created(){
      if(this.userType){
        RestApiManager.getUnReadMessages()
        Number(this.userType) == 0 && RestApiManager.getGuestUserInfo();
        Number(this.userType) == 1 && RestApiManager.getProfileInfo();
      }
    }
}
</script>
<style scoped>
.at-chat_sidebar {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 28%;
  flex: 0 0 28%;
  max-width: 28%;
  border-right: 1px solid #eee;

}
.at-chat_sidebar:before{
    width: 100%;
    position: absolute;
    content: '';
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
}
.at-ovelay:before{
    opacity: 1;
    visibility: visible;
    background-color: rgba(0,0,0,0.3);
    transition: all 0.6s ease-in-out;
}

</style>