<template>
    <div id="posts" class="at-userlist_tab">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <div v-if="Object.keys(postConversation).length" class="at-deleteselected">
            <div class="at-groupuserbar at-groupuserbarvtwo">
                <input type="checkbox" id="at_select_post_chats" name="check" @change.stop="selectAll" v-model="selectAllItems">
                <label for="at_select_post_chats" class="at-groupuserbar_content">
                     <h6>{{TRANS.select_all_chats_txt}}</h6>
                </label>
            </div>
            <div class="at-deletecheck" v-if="deleteConversations.length" @click.prevent="removeItems()">
                <a href="#">{{TRANS.cancel_txt}}</a>
            </div>
        </div>
        <ul v-if="Object.keys(postConversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-post-list-item 
                v-for="item in postConversation" 
                :itemProps="item" 
                :key="item.chatId" 
                @deleteItem="deleteItem($event)"
                :deleteConversations="deleteConversations"
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :svgIcon="true" listIcon="pin"/> 
        </template>
        <div class="at-redbtnwrapper" v-if="deleteConversations.length && !isLoading" @click.prevent="deleteAllItems()"> 
            <button class="at-btn at-btnred" :disabled="isSavingUpdt" :class="{'at-disable-btn' : isSavingUpdt }"> {{TRANS.delete_select_txt}} <i class="guppy-trash-2"></i></button>
        </div>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatPostListItem from "./GuppyChatPostListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../../resources/RestApiController";
import { mapState } from 'vuex';
export default {
    name: "GuppyChatPostList",
    components:{ 
        GuppyChatPostListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search :'',
            timer  : null,
            selectAllItems  : false,
            deleteConversations : [],
            isSavingUpdt : false
        }
    },
    computed :mapState({
        postConversation    : state => state.postConversation,
        postListFlag        : state => state.postListFlag,
        postListOffset      : state => state.postListOffset,
        noMorePostUsers     : state => state.noMorePostUsers,
        postUserSearch      : state => state.postUserSearch,
    }),
    methods:{
        removeItems(){
            this.deleteConversations = [];
            this.selectAllItems = false;
        },
        deleteAllItems(){
             this.$swal({
                icon: 'error',
                text: this.TRANS.are_you_sure,
                showCancelButton: true,
                confirmButtonText: this.TRANS.delet_grp_member_btn,
                confirmButtonColor: '#EF4444',
                cancelButtonText: this.TRANS.not_right_now,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading  = true;
                    let response    = await RestApiManager.deleteChats(this.deleteConversations);
                    this.isLoading  = false;
                    if (response.data.type == "success") { 
                        this.$store.commit('deleteConversation', { 
                            chatIds: this.deleteConversations, 
                            listType  : 'postConversation',
                        });
                        this.deleteConversations = [];
                        this.$store.commit('toggleMessagesScr',{ isEmptyView : true, isOpenMessagesScr : false });
                        if(!Object.keys(this.postConversation).length){
                            this.getPostConversation()
                        }
                    } else {
                        this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                    }
                }
            })
            
        },
        selectAll(){
            if(this.selectAllItems){
                let chatIds = Object.keys(this.postConversation);
                this.deleteConversations =[...new Set([...this.deleteConversations, ...chatIds])];
            } else {
                this.deleteConversations = [];
            }
        },
        deleteItem(chatId){
            let existItem = this.deleteConversations.find(item => item == chatId);
            let action = existItem ? 'remove' : 'add';
            if(action == 'add'){
                this.deleteConversations.push(chatId)
            } else {
                let index = this.deleteConversations.findIndex(item => item == chatId);
                if(index > -1 ){
                    this.deleteConversations.splice(index, 1);
                }
            }
        },
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMorePostUsers', RecordListValue: false});
            this.isScrolling        = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'postListOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'postUserSearch', searchValue: this.search});
                this.getPostConversation();
                this.updateOffset();
            }, 800)
        },
        async getPostConversation(){
            if ( !this.noMorePostUsers ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getConversation(this.postListOffset,this.search, 0)
                this.isLoading  = false;
                if (response.data.type == "success") {
                    let currentMoreRecord = Object.keys(response.data.guppyMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMorePostUsers', RecordListValue: currentMoreRecord});
                    if(!this.postListFlag){
                        this.$store.commit('updateListFlag', {flag: 'postListFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateOffset(){
            let currentOffset = this.postListOffset + this.RecordLimit;
            this.$store.commit('updateOffset', {offset:'postListOffset', offsetValue: currentOffset});
        },
        updateListRecord( recList ){
            let data = {
                'list'      : recList,
                'listType'  : 'postConversation',
                'isScrolling' : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getPostConversation();
                this.updateOffset();
            }
        },
    },
    created(){
        if( !Object.keys(this.postConversation).length || !this.postListFlag){
            this.getPostConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.postUserSearch;
    },
};
</script>