<template>
   <div class="at-view-messages" >
       <div v-if="messagesList.length" class="at-deleteselected">
          <div class="at-groupuserbar at-groupuserbarvtwo">
            <input type="checkbox" id="at_select_conversation" name="check" @change.stop="selectAll()" v-model="selectAllItems" />
            <label for="at_select_conversation" class="at-groupuserbar_content">
                <h6>{{TRANS.select_all_conv_txt}}</h6>
            </label>
          </div>
          <div class="at-deletecheck" v-if="deleteConversations.length" @click.prevent="removeItems()">
            <a href="#">{{TRANS.cancel_txt}}</a>
          </div>
        </div> 
        <div v-if="loadChat && !messagesList.length" class="at-empty-conversation" >
            <div class="at-chatloader">
                <span></span><span></span><span></span><span></span><span></span
                ><span></span><span></span><span></span>
            </div>
        </div>
        <template v-if="messagesList.length">
        <div :id="'message-wrap_' + chatInfo.chatId" ref="messagesEnd" class="at-messagewrap" @scroll="scrollHandler($event)">
        <!-- message list start -->
            <!-- loading message template -->
            <template v-if="messagesLoading">
                <div class="at-messages at-inner-loaader">
                    <span><i class="at-spinericon"></i></span>
                </div>
            </template>
            <!-- messageList component -->
            <guppy-chat-messages-list :deleteConversations="deleteConversations" @deleteItem="deleteItem($event)" :chatId="chatInfo.chatId"/>
        </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import GuppyChatMessagesList from './GuppyChatMessagesList.vue';
import GuppyChatManager from "../../../../resources/controller";
import RestApiManager from "../../../../resources/RestApiController";
export default {
    name : 'GuppyChatMessagesContainer',
    components:{
        GuppyChatMessagesList
    },
    props : {
      'chatId':{
        type: String,
        required : false,
        default : ()=>{
          return null
        }
      },
      'conversationData':{
        type: Object,
        required : false,
        default : ()=>{
          return null
        }
      },
    },
    data(){
        return {
            lastScrollTop : 0,
            convData: {
                offset      : 0,
                userId      : "",
                chatType    : "",
                receiverId  : "",
                groupId     : "",
                postId      : "",
                chatId      : "",
            },
            selectAllItems  : false,
        }
    },
    computed:{
        deleteConversations : function(){
            return this.$store.state.deleteConversations;
        },
        messagesList(){
            return this.$store.getters.messagesListData(this.chatId);
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.chatId)
        },
        loadChat(){
            return this.$store.getters.loadChat(this.chatId);
        },
        messagesLoading(){
            return this.$store.getters.messageLoading(this.chatId);
        },
        ...mapState({
            isMessangerChat : (state) => state.isMessangerChat
        }),
    },
    watch:{
        conversationData(converData){
            if(converData && this.isMessangerChat){
                this.convData = converData;
            }
        },
        chatInfo(){
            this.$store.state.deleteConversations = [];
            this.selectAllItems = false;
            this.$store.state.isDeletingMessage   = false;
            GuppyChatManager.messagesScrollList( this.lastScrollTop, this.chatId);
        }
    },
    methods:{
        removeItems(){
            this.$store.state.deleteConversations = [];
            this.selectAllItems = false;
        },
        deleteAllItems(){
            this.$swal({
                icon: 'error',
                text: this.TRANS.are_you_sure,
                showCancelButton: true,
                confirmButtonText: this.TRANS.delet_grp_member_btn,
                confirmButtonColor: '#EF4444',
                cancelButtonText: this.TRANS.not_right_now,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$store.state.isDeletingMessage   = true;
                    let response        = await RestApiManager.deleteConvMessages(this.$store.state.deleteConversations);
                    this.$store.state.isDeletingMessage   = false;
                    if (response.data.type == "success") { 
                        this.$store.commit('deleteConversationMessages', { 
                            chatId      : this.chatInfo.chatId,
                            messagesIds : this.deleteConversations
                        });
                        this.$store.state.deleteConversations = [];
                        if(!this.messagesList.length){
                            this.loadChatMessages()
                        }
                    } else {
                        this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                    }
                }
            })
        },
        selectAll(){
            if(this.selectAllItems){
                let messageIds = this.messagesList.map(value => value.messageId);
                this.$store.state.deleteConversations =[...new Set([...this.$store.state.deleteConversations, ...messageIds])];
            } else {
                this.$store.state.deleteConversations = [];
            }
        },
        deleteItem(messageId){
            let existItem = this.$store.state.deleteConversations.find(item => item == messageId);
            let action = existItem ? 'remove' : 'add';
            if(action == 'add'){
                this.$store.state.deleteConversations.push(messageId)
            } else {
                let index = this.$store.state.deleteConversations.findIndex(item => item == messageId);
                if(index > -1 ){
                    this.$store.state.deleteConversations.splice(index, 1);
                }
            }
        },
        loadChatMessages() {
            if (this.loadChat) {
                this.$store.dispatch("UpdateMessageList", {
                    chatData: this.convData,
                    lastScrollTop: this.lastScrollTop ? this.lastScrollTop : 0,
                });
            }
        },
        scrollHandler(e) {
            let scrollTop       = e.currentTarget.scrollTop;
            let scrollHeight    = this.$refs.messagesEnd ? this.$refs.messagesEnd.scrollHeight : 0;
            this.lastScrollTop  = scrollHeight - scrollTop;
            const top           = Math.round(scrollTop) === 0;
            if (top && this.messagesList.length) {
                if ( scrollHeight > e.currentTarget.offsetHeight ) {
                    this.loadChatMessages();
                }
            }
        },
        scrollListToBottom(scrollHeight = 0) {
            GuppyChatManager.messagesScrollList(scrollHeight, this.chatInfo.chatId);
        },
        scrollListToPosition() {
            this.scrollListToBottom(this.lastScrollTop);
        },
        updateConvData(){
            let offset  = 0;
            let userId  = "", chatType = "", receiverId = "", groupId = "", postId = "", chatId ="";
            chatType    = Number(this.chatInfo.chatType);
            chatId      = this.chatInfo.chatId;
            userId      = this.userId;
            if ([1,3].includes(this.chatInfo.chatType) ) {
                groupId     = postId = "";
                receiverId  = chatId.split('_')[0];
            } else if (this.chatInfo.chatType == 2) {
                receiverId  = postId = "";
                groupId     = chatId.split('_')[0];
            } else if (this.chatInfo.chatType == 0 ) {
                postId      = chatId.split('_')[0];
                receiverId  = this.chatInfo.postReceiverId;
                groupId     = "";
            }
            this.convData = { offset, userId, chatType,receiverId,groupId, postId, chatId }
        }
    },
    mounted(){
        this.$root.$on('deleteMessages',()=>{
            this.deleteAllItems();
        })
    },
    created(){
        if(this.isMessangerChat){
            this.convData = this.conversationData;
        } else {
            this.updateConvData();
            if(!this.messagesList.length){
                this.loadChatMessages();
            }
            GuppyChatManager.messagesScrollList( this.lastScrollTop, this.chatId);
        }
    },
}
</script>

<style scoped>
.at-messagewrap {
  display: flex;
  overflow: auto;
  min-height: 280px;
  padding: 10px 20px 0;
  flex-direction: column;
  margin-top: auto;
}
.at-messagewrap > div{
    margin-top: auto;
}
.at-messagewrap .at-alert {
  margin: auto;
}
.at-messagewrap .at-userbar_loader {
  background: transparent;
  position: relative;
  height: auto;
  padding: 20px 0 30px;
}
</style>