<template>
    <div class="at-message at-audio-area" :class="responsiveClass" :id="'message_'+messageProps.messageId">
        <h5 v-if="!messageProps.isSender && (messageProps.chatType == 2 || messageProps.chatType == 0)">{{messageProps.userName}}</h5>
        <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
            <div class="ready-player-1">
                <guppy-chat-audio :messageId="messageProps.messageId" :audioSource="messageProps.attachmentsData.attachments[0].file" />
            </div>
            <template v-slot:message_actions v-if="!disableReply">
                <guppy-chat-message-actions 
                    @deleteMessageEvent="deleteMessage($event)" 
                    :downloadData="messageProps.attachmentsData.attachments"
                    :isDownloadProps="true"
                    :messageProps="messageProps"
                />
            </template>
        </guppy-chat-quote-message-list>
        <div v-else class="ready-player-1">
            <guppy-chat-audio :messageId="messageProps.messageId" :audioSource="messageProps.attachmentsData.attachments[0].file" />
            <guppy-chat-message-actions 
                v-if="!disableReply"
                @deleteMessageEvent="deleteMessage($event)" 
                :downloadData="messageProps.attachmentsData.attachments"
                :isDownloadProps="true"
                :messageProps="messageProps"
            />
        </div>
    </div>
</template>

<script>
import GuppyChatMessageActions from "../GuppyChatMessageActions/GuppyChatMessageActions.vue";
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from 'vuex';
import GuppyChatAudio from "./GuppyChatAudio";
export default {
    name:'GuppyChatVoiceMessage',
    props:['messageProps' , 'disableReply'],
    components: {
        GuppyChatAudio,
        GuppyChatMessageActions,
        GuppyChatQuoteMessageList,
    },
    data(){
        return {
            responsiveClass: '',
        }
    },
    computed:mapState({
        isMessangerChat : state => state.isMessangerChat
    }),
    methods:{
        deleteMessage( data){
            this.$emit('deleteMsgEvt', data )
        },
    },
    mounted(){
        let contentId = document.getElementById('message_'+this.messageProps.messageId);
        if(contentId){
            let contentWidth = contentId.clientWidth
            if(this.isMessangerChat){
                if(contentWidth >= 220 ){
                this.responsiveClass = 'at-chat-msg-220'
                }
            } else {
                if(contentWidth >= 270 ){
                this.responsiveClass = 'at-chat-msg-270'
                } else if(contentWidth >= 190 ){
                this.responsiveClass = 'at-chat-msg-190'
                } else if(contentWidth >= 110 ){
                this.responsiveClass = 'at-chat-msg-110'
                }
            }
        }
    }
}
</script>

<style>
    .vueAudioBetter{
        margin: 0 !important;
        box-shadow: none !important;
        background-image: none !important;
        padding: 0 !important;
        border-radius: 0 !important;
        width: 330px !important;
        overflow: visible !important;
    }
    .at-message_sender .at-grp_receiver_msg>.at-message>.ready-player-1>.vueAudioBetter,
    .at-messages > .at-message > .ready-player-1 > .vueAudioBetter{
        margin: 0 24px 0 0 !important;
    }
    .operate > span:not(.iconfont){
        color: var(--terguppycolor) !important;
        font-size: 13px !important;
        line-height: 30px;
        padding-left: 20px !important;
    }
    .iconfont{
        color: var(--primguppycolor) !important;
        font-size: 20px !important;
        top: 0 !important;
        left: 0 !important;
    }
    .iconfont.icon-notificationforbidfill,
    .iconfont.icon-notificationfill{line-height: 20px;}
    .iconfont.icon-notificationforbidfill,
    .iconfont.icon-notificationfill{padding-left: 10px !important;}
    /* rtl */
    .wpguppy-rtl .at-message_sender .at-grp_receiver_msg>.at-message>.ready-player-1>.vueAudioBetter,
    .wpguppy-rtl .at-messages > .at-message > .ready-player-1 > .vueAudioBetter{margin: 0 0 0 24px !important;}
    .wpguppy-rtl .operate > span:not(.iconfont){
        padding-left: 0 !important;
        padding-right: 20px !important;
    }
    .wpguppy-rtl .iconfont{
        right: auto !important;
        left: 0 !important;
    }
    .wpguppy-rtl .operate{
        padding-right: 0;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
    .wpguppy-rtl .operate .iconfont + .iconfont{padding-right: 5px;}
    .wpguppy-rtl .iconfont.icon-notificationforbidfill,
    .wpguppy-rtl .iconfont.icon-notificationfill{
        padding-left: 0 !important;
        padding-right: 10px !important;
    }
    .wpguppy-rtl .at-messages:not(.at-message_sender) .at-chat-msg-220 .at-messageoption_list{
        right: auto;
        left: 100%;
        margin-right: 0;
        margin-left: -30px;
    }
</style>