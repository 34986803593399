import Vue from 'vue';
import axios from 'axios';
import store from "./vuex/store";
import VueAxios from 'vue-axios';
import Mixin from "./mixins/GlobalMixins";
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import App from './components/App.vue';
import widgetChatApp from './components/widgetChatApp.vue';
import GuppyChatButtonApp from './components/GuppyChatButtonApp.vue';
import AdminChats from "./AdminApp/AdminApp.vue";
import jQuery from 'jquery';
import { GUPPYCHAT_TRANSLATION as TRANS, GUPPYCHAT_SETTINGS } from "./resources/constants";
axios.defaults.baseURL = window.wpguppy_scripts_vars.restapiurl;
axios.defaults.headers.common['Authorization'] = `Bearer ` + window.wpguppy_scripts_vars.authToken;
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.use(VueVideoPlayer);
import { videoPlayer } from 'vue-video-player';
Vue.component('video-player', videoPlayer);
Vue.use(VueAxios, axios);
Vue.config.productionTip = true;
Vue.mixin(Mixin); 

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

// Filters
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('AM_PM_Replacement', function ( time ) {
  if ( !time ) return '';
  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday'];
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
  days.forEach( day =>{
    time = time.replace( day, TRANS[day.toLowerCase()]);
  });
  months.forEach( month =>{
    time = time.replace( month.slice(0, 3), TRANS[month.toLowerCase()].slice(0, 3) );
  });
  return time;
});

// messanger chat
if( jQuery('#wpguppy-messanger-chat').length && window.wpguppy_scripts_vars.userId ) {
  new Vue({
    store,
    render: h => h(App)
  }).$mount('#wpguppy-messanger-chat')
} else if( jQuery('#wpguppy-chats-history').length && window.wpguppy_scripts_vars.userId ) { //Admin chat
  new Vue({
    store,
    render: h => h(AdminChats)
  }).$mount('#wpguppy-chats-history')
} else if( jQuery('#wpguppy-widget-chat').length && window.wpguppy_scripts_vars.chatSetting.floatingWindowEnable ) {
  let pageId        = window.wpguppy_scripts_vars.pageId;
  let includeOnPage = GUPPYCHAT_SETTINGS.includeOnPage;
  let excludeOnPage = GUPPYCHAT_SETTINGS.excludeOnPage;
  let isAllowOnPage = false;

  if( 'includeOnPage' in GUPPYCHAT_SETTINGS && 'excludeOnPage' in GUPPYCHAT_SETTINGS ){
    if( includeOnPage.includes(pageId) ){
      isAllowOnPage = true;
    } else if ( !excludeOnPage.includes(pageId) && includeOnPage.length == 0) {
      isAllowOnPage = true;
    } else if ( includeOnPage.length == 0 && excludeOnPage.length == 0){
      isAllowOnPage = true;
    }
  }

  if(isAllowOnPage){
    new Vue({
      store,
      render: h => h(widgetChatApp)
    }).$mount('#wpguppy-widget-chat')
  }

}

//woocommerce product chat button
if( jQuery('#wpguppy-woo-products').length && window.wpguppy_scripts_vars.userId ) {
  Vue.component('wpguppy-woo-chat-button', GuppyChatButtonApp)
  new Vue({
    store,
  }).$mount('#wpguppy-woo-products');
}
