<template>
  <div class="at-message at-audiosendfile" :class="responsiveClass" :id="'message_'+messageProps.messageId">
    <h5>{{messageProps.userName}}</h5>
    <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
      <div class="at-sendfile">
        <i class="guppy-music"></i>
        <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
      </div>
     
    </guppy-chat-quote-message-list>
    <div v-else class="at-sendfile">
      <i class="guppy-music"></i>
      <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
    </div>
  </div>
</template>

<script>
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue";
import { mapState } from 'vuex';
export default {
  name: "GuppyAudioMessage",
  components: {
   
    GuppyChatQuoteMessageList
  },
  props : ['messageProps', 'disableReply'],
  data(){
    return {
      responsiveClass : ''
    }
  },
  computed:mapState({
    chatInfo : state => state.chatInfo,
    isMessangerChat : state => state.isMessangerChat
  }),
  methods:{
    deleteMessage( data){
      this.$emit('deleteMsgEvt', data )
    },
  },
  mounted(){
    let contentId = document.getElementById('message_'+this.messageProps.messageId);
    if(contentId){
        let contentWidth = contentId.clientWidth
           if(this.isMessangerChat){
            if(contentWidth >= 220 ){
              this.responsiveClass = 'at-chat-msg-220'
            }
          } else {
            if(contentWidth >= 270 ){
              this.responsiveClass = 'at-chat-msg-270'
            } else if(contentWidth >= 190 ){
              this.responsiveClass = 'at-chat-msg-190'
            } else if(contentWidth >= 110 ){
              this.responsiveClass = 'at-chat-msg-110'
            }
          }
      }
  }
};
</script>

<style scoped>
.at-audiosendfile{
  padding: 19px 20px;
}
</style>