<template>
  <ul class="at-chat_sidebar_footer" @mouseover="toggleOverLay(true)" @mouseleave="toggleOverLay(false)">
    <li class="at-hasimage">
      <a href="javascript:void(0);" class="">
        <img :src="GUPPYCHAT_SETTINGS.floatingIcon"  />
      </a>
    </li>
    <li @click.prevent="activeTab('private')" :class="{ 'active' : selectedActiveTab == 'private'}">
      <a href="javascript:void(0);" class="guppy-message-square">
        <div class="at-sidebar-item">
            <h6>{{TRANS.private_tab_txt}}</h6>
        </div>
      </a>
    </li>
    <li  @click.prevent="activeTab('group')" :class="{ 'active' : selectedActiveTab == 'group'}">
      <a href="javascript:void(0);">
        <guppy-svg-icons ImageColor="group" :isActive="selectedActiveTab == 'group'"/>
        <div class="at-sidebar-item">
            <h6>{{TRANS.group_tab_txt}}</h6>
        </div>
      </a>
    </li>
    <li @click.prevent="activeTab('posts')" :class="{ 'active' : selectedActiveTab == 'posts'}">
      <a href="javascript:void(0);">
        <guppy-svg-icons ImageColor="pin" :isActive="selectedActiveTab == 'posts'"/>
        <div class="at-sidebar-item">
            <h6>{{TRANS.post_tab_txt}}</h6>
        </div>
      </a>
    </li>
    <li @click.prevent="activeTab('customer_support')" :class="{ 'active' : selectedActiveTab == 'customer_support'}">
      <a href="javascript:void(0);">
        <guppy-svg-icons ImageColor="support" :isActive="selectedActiveTab == 'customer_support'"/>
        <div class="at-sidebar-item">
            <h6>{{TRANS.customer_tab_txt}}</h6>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import { GUPPYCHAT_SETTINGS } from '../../../../resources/constants';
import GuppySvgIcons from "../../EmptyViews/GuppySvgIcons.vue";
import jQuery from 'jquery';
export default {
  name: "GuppyChatSidebarFooter",
  components : {GuppySvgIcons},
  data(){
    return{
      isMobileDevice: GUPPYCHAT_SETTINGS.isMobileDevice,
      GUPPYCHAT_SETTINGS
    }
  },
  computed :mapState({
    selectedActiveTab : state => state.activeTab,
  }),
  methods:{
    activeTab(data){
      this.$store.commit('selectTab', data);
    },
    toggleOverLay(toggle){
      let targetClass = '.at-chat_sidebar';
      let applyClass = 'at-ovelay';
      let sibebarWidth = '70px';
      if(toggle){
        jQuery('ul.at-chat_sidebar_footer').css('width','270px')
        jQuery(targetClass).addClass(applyClass);
      } else {
        jQuery('ul.at-chat_sidebar_footer').css('width',sibebarWidth)
        jQuery(targetClass).removeClass(applyClass);
      }
    },
  },
};
</script>

<style scoped>
.at-admin-chat .at-chat_sidebar_footer {
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 9;
  width: 70px;
  height: 100%;
  overflow: auto;
  line-height: 30px;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: flex-start;
  border-right: 1px solid #eee;
  transition: width 0.5s ease;
}
.at-admin-chat .at-chat_sidebar_footer li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  width: auto !important;
  background: transparent !important;
  border: none !important;
  box-shadow: inset 0px -1px 0px #EEEEEE;
}
.at-admin-chat.wpguppy-rtl .at-chat_sidebar_footer > li{
      box-shadow: inset 0px -1px 0px #eeeeee;
}
.at-chat_sidebar_footer li.active{ 
   box-shadow: inset 3px 0px 0px var(--primguppycolor), inset 0px -1px 0px #eeeeee;
}

.at-admin-chat .at-chat_sidebar_footer a {
  font-size: 24px;
  color: #999999;
  position: relative;
  display: inline-block;
  padding: 18px 22px !important;
  width: 100%;
  text-align: center;
}
.at-admin-chat .at-chat_sidebar_footer li.active  a{
  color: var(--secguppycolor);
}
.at-admin-chat .at-chat_sidebar_footer a .at-notify {
  pointer-events: none;
  top: 0px;
  left: 100%;
  position: absolute;
  margin-left: -10px;
  -webkit-box-shadow: 0 0 0 3px var(--secguppycolor);
  box-shadow: 0 0 0 3px var(--secguppycolor);
  font-family: var(--secchatfont);
}

.at-admin-chat .at-chat_sidebar_footer:hover{
    width: 270px;

}
.at-admin-chat .at-chat_sidebar_footer:hover .at-sidebar-item{ 
  visibility: visible;
  opacity: 1;
}
/* .at-admin-chat Sidebar */
.at-sidebar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 0;
    border-left: 0;max-width: 200px;
    width: 200px;
    visibility: hidden;
    position: absolute;
    left:60px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}
.at-admin-chat .at-sidebar-item h6 {
    display: inline-flex;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
    margin: 0;
}
.at-admin-chat .at-sidebar-item h5  {
  margin: 0;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
}
.at-admin-chat .at-sidebar-item span {
  display:inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--primchatfont);

}
.at-admin-chat .at-chat_sidebar_footer::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.at-admin-chat .at-chat_sidebar_footer .at-nestedsetting {padding:0;}

 .at-admin-chat .at-nestedsetting > li{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  width: 260px;
  background: #fff;
 }
 .at-admin-chat .at-nestedsetting > li.active a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
 }

.at-admin-chat .at-chat_sidebar_footer li.at-hasimage a {
    padding: 8px 10px !important;
}
.at-admin-chat .at-chat_sidebar_footer li > a {
    font-size: 20px;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
.at-admin-chat .at-chat_sidebar_footer li a svg {
     margin: 0 ;
}
.at-admin-chat .at-admin-chat .at-chat_sidebar_footer li a svg{
     margin: 20px 25px;
}
.at-admin-chat .at-chat_sidebar_footer li > a > i{
      display: inline-block;
} 
.at-admin-chat .at-admin-chat .at-chat_sidebar_footer > li > a {
    width: auto;
}
.at-admin-chat .at-chat_sidebar_footer a img{
    max-width: 44px;
    border-radius: 50%;
    height: 44px;
}

.at-admin-chat .at-chat_sidebar_footer > li.active a{
  color: var(--secguppycolor);
}

.at-admin-chat .at-chat_sidebar_footer a .at-notify {
  pointer-events: none;
  top: -10px;
  left: 100%;
  position: absolute;
  margin-left: -10px;
  -webkit-box-shadow: 0 0 0 3px var(--secguppycolor);
  box-shadow: 0 0 0 3px var(--secguppycolor);
  font-family: var(--secchatfont);
}
.at-admin-chat .at-nestedsetting{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    transition: all 0.4s ease-in-out;
    background:#fff;
}
.at-admin-chat .at-nestedsetting li{
  box-shadow: inset 0px 1px 0px #EEEEEE;
}
.at-admin-chat .at-nestedsetting li.active a{
    color: var(--secguppycolor);
}
.at-admin-chat .at-chat_sidebar_footer li.at-hasimage {
  border:0 !important;
  justify-content:flex-start;
}
.at-admin-chat .at-chat_sidebar_footer li , 
.at-admin-chat .at-nestedsetting > li{
  width: 70px;
}
.at-admin-chat .at-chat_sidebar_footer li a , 
.at-admin-chat .at-nestedsetting > li a{
    padding: 0;
    width: 70px;
}
.at-admin-chat .at-chat_sidebar_footer:hover li , 
.at-admin-chat .at-nestedsetting:hover li {
   width: 270px;
}
.at-admin-chat .at-nestedsetting li{
  width: 70px;
}
.at-admin-chat .at-chat_sidebar_footer .at-hasimage .at-sidebar-item {
  padding: 0 10px;
}
.at-admin-chat .at-chat_sidebar_footer > li {
  width: 270px;
}
.at-admin-chat .at-sidebar-item {
    left: 70px;
}

.at-admin-chat .at-responmenu {
      display: flex;
      width:100%;
}
.at-admin-chat .at-responsivelist {
  display: flex;
  flex-direction: column;
  width: 60px;
}
.at-admin-chat .at-responsivelist {
    width: 70px;
}
.at-admin-chat .at-imgresponsivearea {
    display: flex;
    padding: 14px 20px;
    align-items: flex-start;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 100%;
}
.at-admin-chat .at-imgresponsivearea > a{
  padding: 0 !important;
}
.at-admin-chat .at-imgresponsivearea a {
  display: flex;
  padding: 0 !important;
}
.at-admin-chat .at-imgresponsivearea .at-sidebar-item {
    padding: 0;
    border: 0;
    flex-direction: column;
    margin: 10px 0 0;
    position: relative;
    align-items: flex-start;
    left: 0;
    max-width: 100%;
    width: 100%;
}
.at-admin-chat .at-responsivelist a {
    font-size: 20px;
    color: #999999;
    display: inline-flex;
    position: relative;
    width: auto;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px -1px 0px #EEEEEE;
    padding: 18px 20px;

}
.at-admin-chat .at-responsivelist a:focus{
    box-shadow: inset 0px -1px 0px #eeeeee !important;
}
.at-admin-chat .at-sidebarshow .at-menuicon{
  background: #F7F8FC !important;
}
.at-admin-chat .at-menuicon{
  box-shadow: inset 0px -1px 0px #EEEEEE;
}
.at-admin-chat .at-chat_sidebar_footer .at-listonrespon{
  box-shadow:none;
}
.at-admin-chat .at-sidebarshow {
  width: 260px !important;
}
.at-admin-chat .at-admin-chat .at-chat_sidebar_footer:hover {
  width: 270px;
}
@media (max-width: 767px){
  .at-admin-chat .at-chat_sidebar_footer:hover {
    width: 70px;
  }
  .at-admin-chat .at-imgresponsivearea a { 
    border: 0 !important;
  }
  .at-admin-chat .at-userchat_tab a {
    padding: 14px 10px;
}
.at-admin-chat .at-userlist_tab .at-sidebarhead_searchv2 {
    padding: 9.5px 0;
}
.at-chat_sidebar_footer .at-sidebar-item{
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
.at-admin-chat .at-imgresponsivearea .at-sidebar-item {
  margin:  10px 0 0 10px;
}
}
 @media (max-width: 480px){
   .at-admin-chat .at-imgresponsivearea {
    padding: 7px 20px;
   }
   .at-admin-chat .at-empty-conversation i {
    font-size: 27px;
 }
 }
</style>