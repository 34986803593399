import Vue from 'vue';
import jQuery from 'jquery'
import { GUPPYCHAT_SETTINGS } from '../../resources/constants';
export const TabState = {
    chatInfo : {},
    // private chat properties
    privateChatOffset       : 0,
    noMorePrivateChat       : false,
    privateConvFlag         : false,
    privateChatSearch       : '',
    privateConversation     : {},
    privateChatCount        : 0,
    onlineUsersList         : new Object(),

    // group chat properties
    groupChatOffset         : 0,
    noMoreGroupChat         : false,
    groupConvFlag           : false,
    groupChatSearch         : '',
    groupConversation       : {},
    groupChatCount          : 0,

    // post chat properties
    postListFlag            : false,
    postListOffset 	        : 0,
    noMorePostUsers         : false,
    postUserSearch          : '',
    postConversation        : {},
    postmessagesCount       : 0,

    // admin support user list properties
    adminSupportUserListFlag    : false,
    adminSupportUserListOffset  : 0,
    noMoreAdminSupportUsers     : false,
    adminSupportUserSearch      : '',
    adminSupportUserList        : {},
    
    // admin support chat list properties
    adminSupportChatsFlag     : false,
    adminSupportChatsOffset   : 0,
    noMoreAdminSupportChats   : false,
    adminSupportChatsSearch   : '',
    adminSupportChats         : {},
    adminSupportChatCount     : 0,

    // Whatsapp list properties
    whatsappListFlag        : false,
    whatsappListOffset 	    : 0,
    noMoreWhatsappUsers     : false,
    whatsappUserSearch      : '',
    whatsappList            : {},

    // user contacts list properties
    contactListFlag         : false,
    contactListOffset 	    : 0,
    noMoreContactUsers      : false,
    contactUserSearch       : '',
    contactList             : {},

    // firend requests list properties
    requestListFlag         : false,
    requestListOffset 	    : 0,
    noMoreRequesttUsers     : false,
    requestUserSearch       : '',
    requestList             : {},
    requestCount            : 0,

    // friend list properties
    friendListFlag          : false,
    friendListOffset 	      : 0,
    noMoreFriendUsers       : false,
    friendUserSearch        : '',
    friendList              : {},

    // Block user list properties
    blockListFlag           : false,
    blockListOffset 	      : 0,
    noMoreBlockUsers        : false,
    blockUserSearch         : '',
    blockList               : {},
    windowWidth             : 0,

    deleteConversations     : [],

}
export const ActionsActions = {
    startConversation(context, payload ) {
        context.commit('startConversation', payload)
    },
}
export const TabMutations = {
  refreshSupportChats(state){
    Vue.set(state, 'adminSupportChatsFlag', false );
    Vue.set(state, 'adminSupportChatsOffset', 0);
    Vue.set(state, 'noMoreAdminSupportChats', false );
    Vue.set(state, 'adminSupportChatsSearch', '' );
    Vue.set(state, 'adminSupportChats', {} );
    Vue.set(state, 'adminSupportChatCount', 0 );
  },
  removeChatInfo( state, payload ){
    Vue.delete(state.chatInfo, payload.chatId);
  },
  deleteConversation(state, payload ){
    payload.chatIds.forEach(chatId => {
      if(chatId in state[payload.listType]){
        Vue.delete(state[payload.listType], chatId);
        if(Object.keys(state.messagesList).length && (chatId in state.messagesList[chatId])){
          Vue.delete(state.messagesList, chatId);
        }
      }
    });
  },
  removeContactListItem( state, payload){
    Vue.delete(state.contactList, payload.chatId);
  },
  updateWidgetType( state, payload){
    if( 'isMessangerChat' in payload ) {
      state.isMessangerChat = payload.isMessangerChat;
      if(state.isMobileView){
        state.chatInfo = new Object();
      }
    }
    
    if('isWhatsappChat' in payload){
      state.isWhatsappChat = payload.isWhatsappChat;
    }

    if( 'isMobileView' in payload ) {
      state.isMobileView = payload.isMobileView;
    }
    if( 'isfloatingChat' in payload ) {
      state.isfloatingChat = payload.isfloatingChat;
    }

  },
  updateChatInfo( state, payload ){
    if(Object.keys(payload).length == 0){
      Vue.set(state, 'chatInfo', {});
      Vue.set(state, 'widgetChatMessageInput', {});
      Vue.set(state, 'messageInput', '');
    } else {
      if( state.isMessangerChat ){
        Vue.set(state, 'chatInfo', payload.data);
      }else if( payload.userClick && state.isWhatsappChat){
        let infoData = new Object();
        infoData['00_4'] = payload.data;
        Vue.set(state, 'chatInfo', infoData);
        Vue.delete(state.widgetChatMessageInput, '00_4')
      }else{
        if( state.chatInfo['00_4'] && payload.userClick){
          Vue.delete(state.chatInfo, '00_4');
        }
        Vue.delete(state.widgetChatMessageInput, '00_4')
        let windowWidth = state.windowWidth;
        let totalWindows = 3;
        if(windowWidth < 1141){
          totalWindows = 1;
        }else if(windowWidth < 1601){
          totalWindows = 2;
        } 
        let chatId = payload.data.chatId;
        let recExist = false;
        let userClick = false;
        if(Object.prototype.hasOwnProperty.call(state.chatInfo, chatId ) ){
          recExist = true;
        }
        if(payload.userClick){
          userClick = true;
        }
        let openChatWindows = Object.values(state.chatInfo).filter(chat => chat.isMinimize == false);
        if(openChatWindows.length == totalWindows){
          if(userClick){
            if(!recExist){
              Vue.set( state.chatInfo[openChatWindows[0].chatId],'isMinimize',  true);
              payload.data['isMinimize'] = false;
              Vue.set(state.chatInfo, chatId, payload.data);
            }else{
              let recIndex = openChatWindows.findIndex( (chat => chat.chatId == chatId) );
              if(recIndex == -1){
                let record = {};
                record = state.chatInfo[chatId];
                record.isMinimize = false;
                Vue.delete(state.chatInfo, chatId);
                Vue.set(state.chatInfo, chatId, record);
                Vue.set( state.chatInfo[openChatWindows[0].chatId],'isMinimize',  true);
              }
            }
          }else{
            payload.data['isMinimize'] = !recExist ? true : false;
            Vue.set(state.chatInfo, chatId, payload.data);
          }
        }else{
          if(userClick){
            if(recExist){
              let record = {};
              record = state.chatInfo[chatId];
              record.isMinimize = false;
              // Vue.delete(state.chatInfo, chatId);
              Vue.set(state.chatInfo, chatId, record);
            }else{
              payload.data['isMinimize'] = false;
              Vue.set(state.chatInfo, chatId, payload.data);
            }
          }else{
            if(recExist){
              let recIndex = openChatWindows.findIndex( (chat => chat.chatId == chatId) );
              let isMinimize = true;
              if(recIndex != -1){
                isMinimize = false;
              }
              payload.data['isMinimize'] = isMinimize;
              Vue.set(state.chatInfo, chatId, payload.data);
            }else{
              payload.data['isMinimize'] = true;
              Vue.set(state.chatInfo, chatId, payload.data);
            }
          } 
        }
      }
    }
  },
  unseenCountMessage( state, payload ){
    state.groupChatCount          = payload.groupChatCount;
    state.postmessagesCount       = payload.postmessagesCount;
    state.privateChatCount        = payload.privateChatCount;
    state.requestCount            = payload.requestCount;
    state.adminSupportChatCount   = payload.supportbaseChatCount;
  },
  updateTabListRecord(state, payload){
    if( Object.keys(state[payload.listType]).length && payload.isScrolling){
      let data = {...state[payload.listType], ...payload.list}
      Vue.set(state, payload.listType, data)
    } else {
      Vue.set(state, payload.listType, payload.list)
    }

  },
    selectTab( state, payload ){
      let enableTabs = [...GUPPYCHAT_SETTINGS.enabledTabs,'profile'];
      if(!enableTabs.includes('private_chats')){
        enableTabs.push('private_chats');
      }
      if( enableTabs.includes(payload) || state.isAdminChat) {
        state.activeTab = payload;
      }
    },
    startChat( state, payload){
      let friendData      = payload.friendData;
      let messagelistData = payload.messagelistData;
      let chatId          = friendData.chatId;
          Vue.delete(state.contactList, chatId);

          let conversationData = {
            ...friendData,
            'isSender' : true,
            'message'  : messagelistData.message,
            'isStartChat'  : messagelistData.isStartChat,
            'blockedId'  : messagelistData.blockedId,
            'messageId'  : messagelistData.messageId,
            'messageStatus'  : messagelistData.messageStatus,
            'messageType'  : messagelistData.messageType,
            'muteNotification'  : false,
            'timeStamp' : messagelistData.timeStamp,
            'isMinimize' : false,
          }

          state.activeTab   = "messages"; 

          if(!Object.prototype.hasOwnProperty.call(state.friendList, chatId ) && !friendData.isBlocked){
            const updateRecord = Object.assign({ chatId: friendData}, state.friendList);
            Vue.set(state, 'friendList', updateRecord );
          }

          if(!Object.prototype.hasOwnProperty.call(state.privateConversation, chatId ) ){
            const updatedConversation = Object.assign({ chatId: conversationData}, state.privateConversation);
            Vue.set(state, 'privateConversation', updatedConversation) 
          }

          if(state.isMessangerChat){
            Vue.set(state, 'chatInfo', conversationData);
          } else {
            Vue.set(state.chatInfo, chatId, conversationData);
          }
          jQuery('.at-chat').addClass('at-opnchatbox');
    },
    //push record to tab List
    updateRecList( state, payload ){ // just only use for update groupMembers list when create group
        let data = payload;
        if( state[data.listType].length && data.isScrolling){
            data.list.forEach( item => {
                let recordIndex = state[data.listType].findIndex( (user => Number(user.userId) == Number(item.userId) ) );
                if(recordIndex == -1){
                    Vue.set(state[data.listType], state[data.listType].length ,item)
                }
            });
        } else {
            state[data.listType] = [];
            state[data.listType] = data.list;
        }
    },
    updateConversationMessage(state, payload){
      let chatId = payload.chatId;
      let chatType = payload.chatType;
      let listType = '';
      if(chatType == 0){
        listType = 'postConversation';
      }else if(chatType == 1){
        listType = 'privateConversation';
      }else if(chatType == 2){
        listType = 'groupConversation';
      }else if(chatType == 3){
        listType = 'adminSupportChats';
      }
      if(payload.messageId){   // for delete message
        if(Object.prototype.hasOwnProperty.call(state[listType], chatId ) && state[listType][chatId].messageId == payload.messageId){
          state[listType][chatId].messageStatus = 2;
        }
      }else if(payload.messagelistData){
        let data           = JSON.parse(JSON.stringify(payload.messagelistData));
        data['clearChat'] = false;
        let unReadCount = 0;
        if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
          Vue.delete(state[listType], chatId);
        }
        if(state.isMessangerChat){      // for messanger base chat
          if(Object.keys(state.chatInfo).length === 0 || (state.chatInfo.chatId != chatId || state.chatInfo.chatType != chatType) ){
            unReadCount = data.UnreadCount;
          }
          if(data.chatType == 2){
            if(state.chatInfo && state.chatInfo.chatId == chatId && state.chatInfo.chatType == data.chatType){
              Vue.set(state, 'chatInfo', data);
            }
          }else if(data.isStartChat){
            Vue.set(state, 'chatInfo', data);
            jQuery('.at-chat').addClass('at-opnchatbox');
          }
        }else{
          let openChatWindow = Object.values(state.chatInfo).filter(chat => chat.isMinimize == false && chat.chatId == chatId).length;
          if(openChatWindow == 0){
            unReadCount = data.UnreadCount;
          }
          let userClick = false; 
          if(data.isStartChat){
            userClick = true;
          }
          if(!state.isMobileView){
            this.commit("updateChatInfo", { data, userClick });
          }
        }
        if(Number( unReadCount ) > 0 ){
          if(data.chatType == 0){
            let counter = state.postmessagesCount + 1;
            Vue.set(state, 'postmessagesCount', counter);
          }else if(data.chatType == 1){
            let counter = state.privateChatCount + 1;
            Vue.set(state, 'privateChatCount', counter);
          }else if(data.chatType == 2){
            let counter = state.groupChatCount + 1;
            Vue.set(state, 'groupChatCount', counter);
          }else if(data.chatType == 3){
            let counter = state.adminSupportChatCount + 1;
            Vue.set(state, 'adminSupportChatCount', counter);
          }
        }
        data.UnreadCount  = unReadCount;
        let record = new Object();
        record[chatId] = data;
        let newConvList   = {...record, ...state[listType]};
        Vue.set(state, listType, newConvList); 
      }
    },
    updateWindowWidth(state, payload){
      state.windowWidth = payload.windowWidth;
    }
} 