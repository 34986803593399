<template>
  <div class="at-chatbtn-wrap">
    <a class="at-chatbtn" href="javascript:void(0);" :class="{'at-disable-btn': isLoading }" @click.prevent="getPostInfo()"><i class="guppy-message-square"></i>{{TRANS.start_chat_txt}}</a>
  </div>
</template>
<script>
import jQuery from "jquery";
import GuppyChatManager from "../resources/controller";
import { GUPPYCHAT_SETTINGS } from "../resources/constants";
import { mapGetters } from 'vuex';
export default {
  name: 'GuppyChatButtonApp',
  props:['post_id'],
  data(){
    return {
      isLoading : false,
    }
  },
  computed:{
    minimizeChats(){
      let data  = { topChats : [] , bottomChats : [] }
      let chats = this.$store.getters.minimizeChats;
      data.topChats     = chats.slice(0,7);
      data.bottomChats  = chats.slice(7,chats.length);
      return data;
    },
    css: function(){
      return "<style> :root { --primguppycolor: " + GUPPYCHAT_SETTINGS.primaryColor+ "; --secguppycolor: " + GUPPYCHAT_SETTINGS.secondaryColor + "; --terguppycolor: " + GUPPYCHAT_SETTINGS.textColor + "; } </style>"
    },
    ...mapGetters({
      chats : 'widgetChats',
    }),
  },
  methods:{
   
    getPostInfo(){
      this.isLoading = true;
      this.axios.get('get-post-info?userId='+this.userId+'&postId='+this.post_id).then( response => {
        this.isLoading = false;
        if(response.data.type == 'success'){
          let postInfo = response.data.postInfo;
          if(postInfo !=''){
            this.$store.commit('updateChatInfo', {data : postInfo, userClick : true});
            if(postInfo.UnreadCount > 0){
              let  userId   = this.userId;
              let data = {
                chatId      : postInfo.chatId,
                chatType    : 0,
                userType    : this.userType,
                userId
              }
              GuppyChatManager.updateSeenMessagesCounter(data);
            }
          }
        } else if( response.data.type == "error" ) {
          this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
        }
      }).catch( errors =>{
        this.errors = errors;
        console.log(errors)
      });
    }
  },
  created(){
    jQuery('head').prepend(this.css);
  }
}
</script>