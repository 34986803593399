<template>
  <div class="at-chat_sidebarsetting">
    <div class="at-chat_sidebarsettingtitle">
      <h2>{{TRANS.detail_txt}}</h2>
      <a href="javascript:void(0);"><i class="guppy-x"></i></a>
    </div>
    <div class="at-chat_sidebarsettingarea">
      <div v-if="chatInfo.groupDetail && chatInfo.groupDetail.groupDescription" class="at-chat_sidebarsettingcontent">
        <p class="at-groupdescription">{{chatInfo.groupDetail.groupDescription}}</p>
      </div>
      <div v-if="chatInfo.chatType == 2 && !chatInfo.memberDisable" class="at-chat_sidebarsettingcontent at-chatgroup-sidebar">
          <div class="at-chatgroup-title">
            <h4>{{TRANS.group_users_txt}}</h4>
          </div>
          <template v-if="groupMembers.length">
            <ul id="memberList" class="at-grouplist at-groupmember-list">
              <li class="at-groupuserbar at-groupuserbarvtwo" v-for="(member, index) in groupMembers" v-show=" index < showMemberRecord" :key="index+'_'+Math.floor(Math.random() * 1000)">
                  <figure class="at-groupuserbar_profile">
                      <img :src="member.userAvatar" :alt="member.userName" >
                  </figure>
                  <div class="at-groupuserbar_title">
                      <h3>{{member.userName}}</h3>
                  </div>
                  <span class="at-group-user-status" v-if="member.groupRole == 1" >{{TRANS.owner_txt}}</span>
                  <span class="at-group-user-status" v-else-if="member.groupRole == 2">{{TRANS.admin_txt}}</span>
              </li>
              <li class="at-groupuserbar at-showmore" v-if="groupMembers.length >= showMemberRecord" @click.prevent="showMoreRec(showMemberRecord)"><a href="javascript:void()" id="loadMore">{{TRANS.load_more}}</a></li>
            </ul>
          </template>
      </div>
      <div class="at-gallerylistwrap">
        <h4>{{TRANS.media}}</h4>
        <ul v-if="attachments.length" class="at-gallerylist">
          <li v-for="(file, index) in attachments" :key="index+ '_' + Math.floor(Math.random() * 99999999)">
            <a href="javascript:void(0);" @click.prevent="downloadFile(file)">
              <img v-if="file.type == 'images'" :src="file.thumbnail" alt="attachment image" />
              <img v-else-if="file.type == 'file'"  :src="fileIcon"  alt="attachment file" />
              <img v-else-if="file.type == 'video'" :src="videoIcon" alt="attachment video" />
              <img v-else-if="file.type == 'audio'" :src="audioIcon" alt="attachment audio" />
            </a>
          </li>
        </ul>
        <div v-else class="at-dropbox at-dropboxempty">
            <i class="guppy-image"></i>
            <label>{{TRANS.no_attachments}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuppyChatManager from "../../../../resources/controller";
import fileicon   from "./resources/file.jpg";
import videoicon  from "./resources/video.jpg";
import audioicon  from "./resources/audio.jpg";
import { mapState } from "vuex";
export default {
    name : "GuppyChatSidebarSetting",
    props : ['mediaProps'],
    data(){
      return {
          fileIcon            : fileicon,
          videoIcon           : videoicon,
          audioIcon           : audioicon,
          showMemberRecord    : 10,
      }
    },
    computed: {
      attachments(){
        return this.$store.getters.attachmentList()
      },
      ...mapState({
      chatInfo: state => state.chatInfo,
      groupMembers: state => {
        if(state.chatInfo.groupDetail){
          return Object.values(state.chatInfo.groupDetail.memberAvatars).filter(member => Number(member.memberStatus) == 1 )
        }
        return [];
      }
    }) 
    },
    watch:{
      groupMembers(){
        this.showMemberRecord = 10
      }
    },
    methods:{
      showMoreRec(increament){
        this.showMemberRecord += increament;
      },
      downloadFile(file){
        if(file.type == "images" || file.type == "video"){
          this.previewImage(file.messageId)
        } else {
          GuppyChatManager.downloadFile(file.file, file.fileName);
        }
      },
      previewImage(messageId){
        this.$root.$emit('previewImage', {messageId})
      }
    },
};
</script>
<style scoped>
.at-chat_sidebarsetting {
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
  background: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  height: 100%;
}
.at-chat_sidebarsettingopen {
  transform: translateX(0);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
.at-chat_sidebarsettingcontent {
  padding: 26px 30px;
}
.at-chat_sidebarsettingcontent h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}
.at-chat_sidebarsettingcontent > a {
  color: #3C57E5 !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: block;
  transition: none;
}
.at-chat_sidebarsettingcontent > a.at-danger{
  color: #EF4444 !important;
}
.at-chat_sidebarsettingcontent .at-themeform {
  padding-top: 10px;
}
.at-chat_sidebarsettingcontent .at-btnlist {
  margin: 0;
}
.at-chat_sidebarsettingcontent .at-btnlist li {
  width: 100% !important;
  padding: 0 !important;
}
.at-chat_sidebarsettingcontent ~ .at-chat_sidebarsettingcontent {
  border-top: 1px solid #ddd;
}
.at-chat_sidebarsettingarea {
  height: calc(100vh - 91px);
  overflow-y: auto;
  background:#fff;
}
.at-gallerylistwrap{
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}
.at-gallerylistwrap h4 {
    letter-spacing: 0.5px;
    color: var(--terguppycolor) !important;
    line-height: 28px;
    font-size: 18px;
    margin: 0;
}
.at-gallerylist {
  margin: -5px;
  padding: 20px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.at-gallerylist li {
  margin: 0;
  list-style: none;
  padding: 5px !important;
  width: 33.333% !important;
}

.at-gallerylist li:not(.at-loadmorecontent) a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  min-height: 73.33px;
  max-height: 73.33px;
  border: 1px solid #DDDDDD;
  color: var(--primguppycolor);
}
.at-gallerylist li.at-loadmorecontent{
  width: 100% !important;
  margin-top: 10px;
}
.at-gallerylist li a i{
  display: inline-block;
  background: #fff;
  text-align: center;
  font-size: 24px;
}
.at-gallerylist li a img {
  max-width: 100%;
  object-fit: scale-down;
  border-radius: 5px;
  max-height: 70px;
  width: 100%;
}
.at-danger {
    color: #EF4444 !important;
}
.at-imgdownloader{
  outline: none !important;
  text-decoration: none !important;
  background-color: transparent !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-flex;
  vertical-align: middle;
  color: #3C57E5 !important;
  align-items: center;
}
.at-imgdownloader.at-disable-btn{
  background-color: transparent !important;
}
.at-imgdownloader .at-msgload{
  margin: 0;
  position: initial;
  display: inline-block;
  margin-right: 10px;
}
.at-imgdownloader .at-msgload i{
  border-right-color: #fff;
  display: block;
  width: 20px;
  height: 20px;
}
.at-chat_sidebarsettingcontent.at-chatgroup-sidebar {
  padding: 30px 0;
}
</style>
