<template>
  <div class="at-message at-sendpdffile" :class="responsiveClass" :id="'message_'+messageProps.messageId">
    <h5 v-if="!messageProps.isSender && (messageProps.chatType == 2 || messageProps.chatType == 0)">{{messageProps.userName}}</h5>
    <guppy-chat-quote-message-list :messageProps="messageProps.replyMessage" v-if="messageProps.replyMessage">
      <div class="at-sendfile">
        <i class="guppy-file"></i>
        <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
      </div>
      <template v-slot:message_actions v-if="!disableReply">
        <guppy-chat-message-action 
          @deleteMessageEvent="deleteMessage($event)" 
          :isDownloadProps="true"
          :downloadData="messageProps.attachmentsData.attachments"
          :messageProps="messageProps" />
      </template>
    </guppy-chat-quote-message-list>
    <div v-else class="at-sendfile">
        <i class="guppy-file"></i>
        <span>{{messageProps.attachmentsData.attachments[0].fileName}}<em>{{messageProps.attachmentsData.attachments[0].fileSize}}</em></span>
        <guppy-chat-message-action 
          v-if="!disableReply"
          @deleteMessageEvent="deleteMessage($event)" 
          :isDownloadProps="true"
          :downloadData="messageProps.attachmentsData.attachments"
          :messageProps="messageProps" />
    </div>
  </div>
</template>

<script>
import GuppyChatMessageAction from "../GuppyChatMessageActions/GuppyChatMessageActions.vue";
import GuppyChatQuoteMessageList from "../GuppyChatQuotedMessages/GuppyChatQuoteMessageList.vue"
import { mapState } from "vuex"
export default {
  name: "GuppyFileMessage",
  components: {
    GuppyChatMessageAction,
    GuppyChatQuoteMessageList
  },
  props:['messageProps', 'disableReply'],
  data(){
    return {
        responsiveClass: '',
    }
  },
  computed:mapState({
    isMessangerChat : state => state.isMessangerChat
  }),
  methods:{
    deleteMessage( data){
      this.$emit('deleteMsgEvt', data )
    },
    quoteMessage(id){
      this.$emit('quoteMsgIdEvt', id )
    }
  },
  mounted(){
    let contentId = document.getElementById('message_'+this.messageProps.messageId);
    if(contentId){
        let contentWidth = contentId.clientWidth
          if(this.isMessangerChat){
            if(contentWidth >= 220 ){
              this.responsiveClass = 'at-chat-msg-220'
            }
          } else {
            if(contentWidth >= 270 ){
              this.responsiveClass = 'at-chat-msg-270'
            } else if(contentWidth >= 190 ){
              this.responsiveClass = 'at-chat-msg-190'
            } else if(contentWidth >= 110 ){
              this.responsiveClass = 'at-chat-msg-110'
            }
          }
      }
  }
};
</script>

<style>
.at-sendfile {
  position: relative;
  background-color: #F7F7F7;
  padding: 11px 42px 11px 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.at-message-qoute + .at-sendfile{
  display: inline-flex;
  padding-right: 20px !important;
}
.at-sendpdffile .at-sendfile{padding-right: 42px;}
.at-sendfile > i {
  flex: none;
  align-self: flex-start;
  font-size: 24px;
  line-height: 38px;
  margin-right: 20px;
}
.at-sendfile > span {
  color: var(--secguppycolor);
  font-size: 15px;
  word-break: break-word;
  line-height: 20px;
  letter-spacing: 0.01em;
}
.at-sendfile > span em {
  display: block;
  color: var(--terguppycolor);
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
}
</style>