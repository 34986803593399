<template>
  <li class="at-userbar" :class="{ 'active' : chatInfo.chatId == itemProps.chatId} " @click.prevent="selectUserPostChat()">
       <div class="at-groupuserbar at-groupuserbarvtwo" @click="_stopPropagation">
            <input type="checkbox" @click.self="selectItem" :id="itemProps.chatId" name="check" :checked="itemProps.chatId == deleteItem" :value="itemProps.chatId">
            <label :for="itemProps.chatId" class="at-groupuserbar_content"></label>
        </div>
        <figure class="at-userpost_profile" >
            <img :src="itemProps.senderAvatar" class="at-postuserimg" alt="post image" />
            <img :src="itemProps.postImage" :alt="itemProps.postTitle" />
            <img :src="itemProps.receiverAvatar" class="at-postuserimg-two" alt="post image" />
        </figure>
        <!-- for user display name and slogan message -->
        <div class="at-userbar_title">
            <h3 v-if="itemProps.postTitle">
                {{itemProps.postTitle | capitalize}}
            </h3>
            <!-- when clear chat is false -->
          
            <span v-if="Number(itemProps.messageStatus) == 2 "><i class="guppy-slash"></i>{{TRANS.deleted_message}}</span>	
            <span v-else-if="Number(itemProps.messageType) == 0 "> {{itemProps.senderName}}:&nbsp;{{itemProps.message}}</span>
            <span v-else-if="Number(itemProps.messageType) == 1 ">{{itemProps.senderName}}:&nbsp; <i class="guppy-paperclip"></i>{{ TRANS.grp_sent_attachment.replace('((username))',itemProps.senderName) }}</span>
            <span v-else-if="Number(itemProps.messageType) == 2 ">{{itemProps.senderName}}:&nbsp; <i class="guppy-map-pin"></i>{{ TRANS.grp_sent_location.replace('((username))',itemProps.senderName) }}</span>
            <span v-else-if="Number(itemProps.messageType) == 3 ">{{itemProps.senderName}}:&nbsp; <i class="guppy-mic"></i>{{ TRANS.you_sent_voice_note.replace('((username))',itemProps.senderName) }}</span>	
            <!-- for notify messages  -->
            <span v-if="Number(itemProps.messageType) == 4"> 
                <span v-html="notifyMessage(itemProps)"></span>
            </span>
        </div>
        <div class="at-userbar_right">
            <span v-if="itemProps.timeStamp">{{getMessageTime(itemProps.timeStamp)}}</span>
            <em v-if="Number(itemProps.UnreadCount) > 0 && Number(itemProps.UnreadCount) < 100" class="at-notify">{{itemProps.UnreadCount}}</em>
            <em v-else-if="Number(itemProps.UnreadCount) > 99" class="at-notify">99+</em>
        </div>
    </li>
</template>

<script>
    import GuppyChatManager from "../../../../resources/controller";
    import { mapState } from "vuex";
    export default {
        name : "GuppyChatPostListItem",
        props:['itemProps', 'deleteConversations'],
        computed :{
            chatInfo(){
                return this.$store.getters.getChatInfo(this.itemProps.chatId)
            },
            deleteItem : function() { 
                let conversations = this.deleteConversations;
                let existItem     = conversations.find(item => item == this.itemProps.chatId);
                return existItem ? existItem : ''
            },
            ...mapState({
                userProfile: state => state.userProfile,
            })
        },
        methods:{
            _stopPropagation(e){
                e.stopPropagation();
            },
            selectItem(e){
                this.$emit('deleteItem', e.target.value)
            },
            notifyMessage(data){
                let notifymessageData = data.message;
                let notifyType        = Number(notifymessageData.type);
                let translation       = this.TRANS;
                if( notifyType == 1 ){
                    let transText   = data.isSender ? translation.auto_inv_sender_msg : translation.auto_inv_receiver_msg;
                    transText = transText.replace(/\\/g, '');
                    return transText.replace('((username))', data.userName)
                }
                return ''
            },
            selectUserPostChat(){
                let dataValues = this.itemProps;
                if(dataValues.UnreadCount > 0){
                    
                    let params = {
                        chatId      : dataValues.chatId,
                        userId      : this.userId,
                        userType    : this.userType,
                        senderId    : dataValues.postReceiverId,
                        chatType    : dataValues.chatType,
                    }
                    GuppyChatManager.updateSeenMessagesCounter(params);
                    this.$store.commit('updateUnseenCounter', this.itemProps);
                }
                let data = this.itemProps;
                
                if( Object.keys(this.chatInfo).length && this.chatInfo.chatId != data.chatId ){
                    let groupMembers = [];
                    let payload = {
                    userName   : this.userProfile.userName,
                    chatType   : this.chatInfo.chatType,
                    chatId     : this.chatInfo.chatId,
                    senderId   : this.userId,
                    userType   : this.userType,
                    text       : '',
                    groupMembers,
                    }
                    this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
                }
                this.$store.commit('updateChatInfo', {data, userClick : true});
            },
            getMessageTime(messageTimeStamp) {
                return GuppyChatManager.getLongDateTime(messageTimeStamp)
            },
        }
    }
</script>
<style scoped>
    .at-userpost_profile{
        margin: 0;
        flex: none;
        width: 50px;
        height: 50px;
        display: flex;
        position: relative;
        align-items: flex-start;
    }
    .at-userpost_profile + .at-userbar_title{
        margin-left: 15px;
    }
    .at-userpost_profile img:not(.at-postuserimg,.at-postuserimg-two){
        width: 100%;
        max-width: 40px;
        margin-top: auto;
        border-radius: 50%;
        margin-left: auto;
        object-fit: cover;
    }
    .at-postuserimg,.at-postuserimg-two{
        margin: 0;
        z-index: 1;
        max-width: 22.5px;
        height: 22.5px;
        border-radius: 50%;
        position: absolute;
        vertical-align: top;
        display: inline-block;
        outline: 4px solid #fff;
    }
    .at-postuserimg img{
        max-width: 22.5px;
        border: 4px solid #fff;
        object-fit: cover;
    }
    .at-postuserimg{
        top: 0px;
        left: 1px;
    }
    .at-postuserimg-two{
        bottom: -8px;
        right: -9px;
    }
    .at-statusonline:after{
        z-index: 1;
        right: -3px;
        content: '';
        bottom: -3px;
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        background: #22C55E;
        border: 2px solid #F7F7F7;
    }
    .at-statusoffline:after{
        z-index: 1;
        right: -3px;
        content: '';
        bottom: -3px;
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        background: #DDDDDD;
        border: 2px solid #F7F7F7;
    }
</style>