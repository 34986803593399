<template>
    <div id="messages" class="at-userlist_tab at-userlist_tabv2">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <div class="at-newcreategroup" v-if="createGroup">
            <a href="#" @click.prevent="getGroupMembers()" guppy-data-target="#creategroup"> 
                {{TRANS.click_here}}<span> {{TRANS.create_group_txt}}</span>
            </a>
        </div>
        <ul v-if="Object.keys(conversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-conversation-list-item 
                v-for="item in conversation" 
                :ConversationItemProps="item" 
                :key="item.chatId" 
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :svgIcon="true" listIcon="group"/> 
        </template>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatConversationListItem from "./GuppyConversationListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../resources/RestApiController";
import { GUPPYCHAT_SETTINGS } from "../../../resources/constants";
import GuppyChatManager from "../../../resources/controller";

import { mapState } from 'vuex';
export default {
    name: "GuppyChatGroupList",
    components:{ 
        GuppyChatConversationListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search      :'',
            timer       : null,
            createGroup	: GUPPYCHAT_SETTINGS.createGroup,
        }
    },
    computed :mapState({
      conversation      : state => state.groupConversation,
      groupConvFlag     : state => state.groupConvFlag,
      groupChatOffset   : state => state.groupChatOffset,
      noMoreGroupChat   : state => state.noMoreGroupChat,
      groupChatSearch   : state => state.groupChatSearch,
    }),
    methods:{
        getGroupMembers(){
            GuppyChatManager.updateGroupMembers();
        },
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMoreGroupChat', RecordListValue: false});
            this.isScrolling = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'groupChatOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'groupChatSearch', searchValue: this.search});
                this.getConversation();
                this.updateOffset();
            }, 800)
        },
        updateOffset(){
            let currentOffset = this.groupChatOffset + this.RecordLimit;
            this.$store.commit('updateOffset', {offset:'groupChatOffset', offsetValue: currentOffset});
        },
        async getConversation(){
            if ( !this.noMoreGroupChat ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getConversation(this.groupChatOffset, this.search, 2); // 2-for group chat
                this.isLoading  = false;

                if (response.data.type == "success") { 
                    let currentMoreRecord = Object.keys(response.data.guppyMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMoreGroupChat', RecordListValue: currentMoreRecord});
                    if(!this.groupConvFlag){
                        this.$store.commit('updateListFlag', {flag: 'groupConvFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateListRecord( recList ){
            let data = {
                'list'          : recList,
                'listType'      : 'groupConversation',
                'isScrolling'   : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getConversation();
                this.updateOffset();
            }
        }
    },
    created(){
        if( !Object.keys(this.conversation).length || !this.groupConvFlag){
            this.getConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.groupChatSearch;
    },
};
</script>