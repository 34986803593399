<template>
  <div class="at-chat_sidebarsetting">
    <div class="at-chat_sidebarsettingtitle">
      <h2>{{TRANS.settings}}</h2>
      <a href="javascript:void(0);"><i class="guppy-x"></i></a>
    </div>
    <div class="at-chat_sidebarsettingarea">
      <!-- for groupChat -->
      <div v-if="chatInfo.groupDetail && chatInfo.groupDetail.groupDescription" class="at-chat_sidebarsettingcontent">
        <p class="at-groupdescription">{{chatInfo.groupDetail.groupDescription}}</p>
      </div>
      <div v-if="!chatInfo.memberDisable && Number(chatInfo.chatType) != 3" class="at-chat_sidebarsettingcontent">
        <h4>{{TRANS.actions}}</h4>
        <!-- for groupChat -->
        <template v-if="chatInfo.chatType == 2 && ( getUserRole == 1 || getUserRole == 2 ) && !chatInfo.memberDisable && !isBPGroup" >
          <a @click.prevent="editGroup()" guppy-data-target="#creategroup" href="javascript:void(0);" >{{TRANS.edit_group}}</a>
        </template>
        <a href="javascript:void(0);" @click.prevent="muteNotification()" :class="{'at-disable-btn' : notificationLoader }" > 
          {{muteChatNotify ? TRANS.unmute_conversation : TRANS.mute_conversation}}
        </a>
      </div>
      <div v-if="chatInfo.chatType == 2 && !chatInfo.memberDisable" class="at-chat_sidebarsettingcontent at-chatgroup-sidebar">
          <div class="at-chatgroup-title">
            <h4>{{TRANS.group_users_txt}}</h4>
          </div>
          <template v-if="groupMembers.length">
            <ul id="memberList" class="at-grouplist at-groupmember-list">
              <li class="at-groupuserbar" v-for="(member, index) in groupMembers" v-show=" index < showMemberRecord" :key="member.userId">
                  <figure class="at-groupuserbar_profile">
                      <img :src="member.userAvatar" :alt="member.userName" >
                  </figure>
                  <div class="at-groupuserbar_title">
                      <h3>{{member.userName}}</h3>
                  </div>
                  <span class="at-group-user-status" v-if="member.groupRole == 1" >{{TRANS.owner_txt}}</span>
                  <span class="at-group-user-status" v-else-if="member.groupRole == 2">{{TRANS.admin_txt}}</span>
                    <a 
                      v-if="(( getUserRole == 1 && member.groupRole != 1 ) || 
                            ( getUserRole == 2 && member.userId != userId && member.groupRole != 1)) && !isBPGroup" 
                      href="javascript:void(0);" 
                      @click.prevent="deleteGroupMember(member)" 
                      guppy-data-target="#delete_group_member"
                      class="at-remove" 
                      ><i class="guppy-trash"></i></a>
              </li>
              <li class="at-groupuserbar at-showmore" v-if="groupMembers.length >= showMemberRecord" @click.prevent="showMoreRec(showMemberRecord)"><a href="javascript:void()" id="loadMore">{{TRANS.load_more}}</a></li>
            </ul>
          </template>
      </div>
      <div class="at-chat_sidebarsettingcontent" v-if="Number(chatInfo.chatType) != 3">
        <h4>{{TRANS.privacy_settings}}</h4>
        <template v-if="chatInfo.chatType == 0 || chatInfo.chatType == 1">
          <template v-if="chatInfo.isBlocked">
            <a v-if="chatInfo.blockedId != userId" href="javascript:void(0);" class="at-danger" guppy-data-target="#blockuser">
              {{TRANS.unblock_user}}
            </a>
          </template>
          <template v-else>  
            <a href="javascript:void(0);" class="at-danger" guppy-data-target="#blockuser">
              {{TRANS.block_user}}
            </a>
          </template>
          <a href="javascript:void(0);" v-if="isAllowReportOption" class="at-danger" guppy-data-target="#reportchat">
            {{TRANS.report_user}}
          </a>
        </template>
        <template v-else-if="chatInfo.chatType == 2">

          <a href="javascript:void(0);" v-if="chatInfo.memberDisable" class="at-danger" guppy-data-target="#deletegroup">{{TRANS.delete_group_txt}}</a>
          <a href="javascript:void(0);" v-else-if="!isBPGroup" class="at-danger" guppy-data-target="#leavegroup">{{TRANS.leave_group_txt}}</a>

          <a href="javascript:void(0);" class="at-danger" v-if="isAllowReportOption" guppy-data-target="#reportchat">{{TRANS.report_group}}</a>
        </template>
        <a href="javascript:void(0);" class="at-danger" v-if="isAllowClearChat" guppy-data-target="#clearchat">
            {{TRANS.clear_chat}}
          </a>
      </div>
      <div class="at-gallerylistwrap">
        <h4>{{TRANS.media}}</h4>
        <a v-if="attachments.length" href="javascript:void(0);"   class="at-imgdownloader" :class="{'at-disable-btn' : isdownloading }"  @click.prevent="downloadMedia()" >{{TRANS.download_all}}</a>
        <ul v-if="attachments.length" class="at-gallerylist">
          <li v-for="(file, index) in attachments" :id="index+ '_' + Math.floor(Math.random() * 99999999)" :key="index+ '_' + Math.floor(Math.random() * 99999999)">
            <a href="javascript:void(0);" @click.prevent="downloadFile(file)">
              <img  v-if="file.type == 'images'" :src="file.thumbnail" alt="attachment image" />
              <img  v-else-if="file.type == 'file'"  :src="fileIcon"   alt="attachment file" />
              <img  v-else-if="file.type == 'video'" :src="videoIcon" alt="attachment video" />
              <img  v-else-if="file.type == 'audio'" :src="audioIcon" alt="attachment audio" />
            </a>
          </li>
          <li v-if="chatMediaOffset" class="at-loadmorecontent">
            <a href="javascript:void(0);" @click.prevent="showMoreMedia()" :class="{'at-disable-btn' : isMediaLoading }"  class="at-btn">{{TRANS.load_more}}</a>
          </li>
        </ul>
        <div v-else class="at-dropbox at-dropboxempty">
            <i class="guppy-image"></i>
            <label>{{TRANS.no_attachments}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestApiManager   from "../../../resources/RestApiController";
import GuppyChatManager from "../../../resources/controller";
import fileicon   from "./resources/file.jpg";
import videoicon  from "./resources/video.jpg";
import audioicon  from "./resources/audio.jpg";
import { mapState } from "vuex";
import {GUPPYCHAT_SETTINGS} from "../../../resources/constants";
export default {
    name : "GuppyChatSidebarSetting",
    props : ['mediaProps'],
    data(){
      return {
          img                 : '',
          isloading           : false,
          isMediaLoading      : false,
          isdownloading       : false,
          fileIcon            : fileicon,
          videoIcon           : videoicon,
          audioIcon           : audioicon,
          notificationLoader  : false,
          showMemberRecord    : 10,
      }
    },
    computed: {
      getUserRole(){
        return this.$store.getters.getUserRole()
      },
      attachments(){
        return this.$store.getters.attachmentList()
      },
      muteChatNotify(){
        return this.$store.getters.muteChatNotify();
      },
      ...mapState({
      chatInfo: state => state.chatInfo,
      groupMembers: state => {
        if(state.chatInfo.groupDetail){
          return Object.values(state.chatInfo.groupDetail.memberAvatars).filter(member => Number(member.memberStatus) == 1 )
        }
        return [];
      },
      isBPGroup: state => {
        if(state.chatInfo.groupDetail){
          return state.chatInfo.groupDetail.isbpgroup;
        }
        return false;
      },
      chatMediaOffset : state => {
        if( state.messagesList[state.chatInfo.chatId] ){
          let _record = state.messagesList[state.chatInfo.chatId];
          if(_record.chatMediaOffset){
            return _record.chatMediaOffset;
          }
        }
        return 0;
      },
      isAllowClearChat(){
        return GUPPYCHAT_SETTINGS.clearChatOption
      },
      isAllowReportOption(){
        return GUPPYCHAT_SETTINGS.reportUserOption
      }
    }) 
    },
    watch:{
      groupMembers(){
        this.showMemberRecord = 10
      }
    },
    methods:{
      showMoreRec(increament){
        this.showMemberRecord += increament;
      },
      deleteGroupMember(data){
        this.$store.state.deleteGroupMember = {
          'userName'  : data.userName,
          'memberId'  : data.userId,
          'groupId'   : this.chatInfo.chatId,
        }
      },
      editGroup(){
        this.$store.commit('editGroupDetail', { resetGroup : false });
      },
      downloadFile(file){
        if(file.type == "images" || file.type == "video"){
          this.previewImage(file.messageId)
        } else {
          GuppyChatManager.downloadFile(file.file, file.fileName);
        }
      },
      previewImage(messageId){
        this.$root.$emit('previewImage', {messageId})
      },
      downloadMedia(){
        if(this.attachments.length && this.attachments.length == 1){
          GuppyChatManager.downloadFile(this.attachments[0].file, this.attachments[0].fileName);
        } else if(this.attachments.length && this.attachments.length > 1){
          this.isdownloading = true;
          let postId   = this.chatInfo.chatType == 0 ? this.chatInfo.chatId : '';
          let groupId  = this.chatInfo.chatType == 2 ? this.chatInfo.chatId : '';
          let actionTo =[1,3].includes( Number(this.chatInfo.chatType) ) ? this.chatInfo.chatId : this.chatInfo.chatType == 0 ? this.chatInfo.postReceiverId : '';
          this.axios.get(
            'download-guppy-attachments?userId=' + this.userId +
            '&actionTo='  + actionTo +
            '&chatType='  + this.chatInfo.chatType + 
            '&postId='    + postId + 
            '&groupId='   + groupId +
            '&userType='  + this.userType
            ).then(response =>{
            this.isdownloading = false;
            if(response.data.type == 'success' && response.data.downloadUrl!=''){
              GuppyChatManager.downloadFile(response.data.downloadUrl, response.data.fileName);
            } else if( response.data.type == "error" ) {
              this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
            }
          }).catch(errors => {
            this.isdownloading = false;
            console.log(errors)
          });
        }
      },
      showMoreMedia(){
        this.isMediaLoading = true;
        let actionTo = '', groupId = '', postId = '';
        let chatId   = this.chatInfo.chatId;
        if (this.chatInfo.chatType == 1 ) {
            groupId = postId = "";
            actionTo = chatId.split('_')[0];
        } else if (this.chatInfo.chatType == 2) {
            actionTo  = postId = "";
            groupId     = chatId.split('_')[0];
        } else if (this.chatInfo.chatType == 0 ) {
            postId      = chatId.split('_')[0];
            groupId     = "";
        }

        this.axios.get('get-guppy-attachments?'+
          'userId='+this.userId + 
          '&actionTo='+actionTo + 
          '&chatType='+this.chatInfo.chatType+
          '&groupId='+groupId+
          '&postId='+postId+
          '&offset='+this.chatMediaOffset).then(response =>{
          this.isMediaLoading = false;
          if(response.data.type == 'success'){
            let data = {
              userId    : this.chatInfo.userId, 
              chatType  :this.chatInfo.chatType,
              chatId    :this.chatInfo.chatId,
              mediaAttachments : response.data.mediaAttachments
            }
            this.$store.commit('UpdateChatMediaAttachments', data );
          } else if( response.data.type == "error" ) {
            this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
          }
        }).catch(errors => {
          this.isMediaLoading = false;
          console.log(errors)
        });
      },
      async muteNotification(){
        let actionTo = this.chatInfo.chatId.split('_')[0];
				let data = {
					muteType    : 0,
					postId      : this.chatInfo.chatType == 0 ? actionTo : '',
					userId      : this.userId,
					chatId      : this.chatInfo.chatId,
					chatType    : this.chatInfo.chatType,
          groupId     : this.chatInfo.chatType == 2 ? actionTo : '',
          actionTo    : this.chatInfo.chatType == 0 ? this.chatInfo.postReceiverId : actionTo,
				}
				this.notificationLoader = true;
        let response = await RestApiManager.muteNotification(data);
				this.notificationLoader = false;
        if( response.data.type == 'error'){
          this.alertBox('error', this.TRANS.error_title, response.data.desc)
        }
			}
    },
};
</script>
<style scoped>
.at-chat_sidebarsetting {
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
  background: #FFFFFF;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  height: 100%;
}
.at-chat_sidebarsettingopen {
  transform: translateX(0);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
.at-chat_sidebarsettingcontent {
  padding: 26px 30px;
}
.at-chat_sidebarsettingcontent h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}
.at-chat_sidebarsettingcontent > a {
  color: #3C57E5 !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: block;
  transition: none;
}
.at-chat_sidebarsettingcontent > a.at-danger{
  color: #EF4444 !important;
}
.at-chat_sidebarsettingcontent .at-themeform {
  padding-top: 10px;
}
.at-chat_sidebarsettingcontent .at-btnlist {
  margin: 0;
}
.at-chat_sidebarsettingcontent .at-btnlist li {
  width: 100% !important;
  padding: 0 !important;
}
.at-chat_sidebarsettingcontent ~ .at-chat_sidebarsettingcontent {
  border-top: 1px solid #ddd;
}
.at-chat_sidebarsettingarea {
  height: calc(100vh - 91px);
  overflow-y: auto;
  background:#fff;
}
.at-gallerylistwrap{
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}
.at-gallerylistwrap h4 {
    letter-spacing: 0.5px;
    color: var(--terguppycolor) !important;
    line-height: 28px;
    font-size: 18px;
    margin: 0;
}
.at-gallerylist {
  margin: -5px;
  padding: 20px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.at-gallerylist li {
  margin: 0;
  list-style: none;
  padding: 5px !important;
  width: 33.333% !important;
}

.at-gallerylist li:not(.at-loadmorecontent) a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  min-height: 73.33px;
  max-height: 73.33px;
  border: 1px solid #DDDDDD;
  color: var(--primguppycolor);
}
.at-gallerylist li.at-loadmorecontent{
  width: 100% !important;
  margin-top: 10px;
}
.at-gallerylist li a i{
  display: inline-block;
  background: #fff;
  text-align: center;
  font-size: 24px;
}
.at-gallerylist li a img {
  max-width: 100%;
  object-fit: scale-down;
  border-radius: 5px;
  max-height: 70px;
  width: 100%;
}
.at-danger {
    color: #EF4444 !important;
}
.at-imgdownloader{
  outline: none !important;
  text-decoration: none !important;
  background-color: transparent !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-flex;
  vertical-align: middle;
  color: #3C57E5 !important;
  align-items: center;
}
.at-imgdownloader.at-disable-btn{
  background-color: transparent !important;
}
.at-imgdownloader .at-msgload{
  margin: 0;
  position: initial;
  display: inline-block;
  margin-right: 10px;
}
.at-imgdownloader .at-msgload i{
  border-right-color: #fff;
  display: block;
  width: 20px;
  height: 20px;
}
.at-chat_sidebarsettingcontent.at-chatgroup-sidebar {
      padding: 30px 0;

}
</style>
