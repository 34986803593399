import { GroupState, GroupMutations, GroupActions } from "./GroupStore";
import { TabState, ActionsActions, TabMutations } from "./TabStore";
import { WidgetChatGetters, WidgetChatMutations, WidgetChatState } from './WidgetChatStore';

export const State = {
    ...GroupState, 
    ...TabState,
    ...WidgetChatState,
}

export const Actions = {
    ...ActionsActions, 
    ...GroupActions
}
export const Mutations = {
    ...GroupMutations,
    ...TabMutations,
    ...WidgetChatMutations,
}
export const Getters = {
    ...WidgetChatGetters,
}
