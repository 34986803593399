<template>
    <div id="messages" class="at-userlist_tab">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <div v-if="Object.keys(conversation).length" class="at-deleteselected">
            <div class="at-groupuserbar at-groupuserbarvtwo">
                <input type="checkbox" id="at_select_privat_chats" name="check" @change.stop="selectAll()" v-model="selectAllItems">
                <label for="at_select_privat_chats" class="at-groupuserbar_content">
                    <h6>{{TRANS.select_all_chats_txt}}</h6>
                </label>
            </div>
            <div class="at-deletecheck" v-if="deleteConversations.length" @click.prevent="removeItems()">
                <a href="#">{{TRANS.cancel_txt}}</a>
            </div>
        </div>
        <ul v-if="Object.keys(conversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-conversation-list-item 
                v-for="item in conversation" 
                :itemProps="item" 
                :key="item.chatId" 
                :deleteConversations="deleteConversations"
                @deleteItem="deleteItem($event)"
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :listIcon="'guppy-message-square'"/> 
        </template>
        <div class="at-redbtnwrapper" v-if="deleteConversations.length && !isLoading" @click.prevent="deleteAllItems()"> 
            <button class="at-btn at-btnred" :disabled="isSavingUpdt" :class="{'at-disable-btn' : isSavingUpdt }" > {{TRANS.delete_select_txt}} <i class="guppy-trash-2"></i></button>
        </div>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatConversationListItem from "./GuppyConversationListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../../resources/RestApiController";
import { mapState } from 'vuex';
export default {
    name: "GuppyChatSupportList",
    components:{ 
        GuppyChatConversationListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search      :'',
            timer       : null,
            selectAllItems  : false,
            deleteConversations : [],
            isSavingUpdt : false
        }
    },
    computed :mapState({
        conversation            :   state => state.adminSupportChats,
        adminSupportChatsFlag   :   state => state.adminSupportChatsFlag,
        adminSupportChatsOffset :   state => state.adminSupportChatsOffset,
        noMoreAdminSupportChats :   state => state.noMoreAdminSupportChats,
        adminSupportChatsSearch :   state => state.adminSupportChatsSearch,
        userProfile             :   state => state.userProfile,
    }),
    methods:{
        removeItems(){
            this.deleteConversations = [];
            this.selectAllItems = false;
        },
        deleteAllItems(){
             this.$swal({
               icon: 'error',
                text: this.TRANS.are_you_sure,
                showCancelButton: true,
                confirmButtonText: this.TRANS.delet_grp_member_btn,
                confirmButtonColor: '#EF4444',
                cancelButtonText: this.TRANS.not_right_now,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isSavingUpdt  = true;
                    let response    = await RestApiManager.deleteChats(this.deleteConversations);
                    this.isSavingUpdt  = false;
                    if (response.data.type == "success") { 
                        this.$store.commit('deleteConversation', { 
                            chatIds: this.deleteConversations, 
                            listType  : 'adminSupportChats',
                            });
                            this.deleteConversations = [];
                            this.$store.commit('toggleMessagesScr',{ isEmptyView : true, isOpenMessagesScr : false });
                            if(!Object.keys(this.conversation).length){
                                this.getConversation()
                            }
                    } else {
                        this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                    }
                }
            }) 
        },
        selectAll(){
            if(this.selectAllItems){
                let chatIds = Object.keys(this.conversation);
                this.deleteConversations =[...new Set([...this.deleteConversations, ...chatIds])];
            } else {
                this.deleteConversations = [];
            }
        },
        deleteItem(chatId){
            let existItem = this.deleteConversations.find(item => item == chatId);
            let action = existItem ? 'remove' : 'add';
            if(action == 'add'){
                this.deleteConversations.push(chatId)
            } else {
                let index = this.deleteConversations.findIndex(item => item == chatId);
                if(index > -1 ){
                    this.deleteConversations.splice(index, 1);
                }
            }
        },
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMoreAdminSupportChats', RecordListValue: false});
            this.isScrolling        = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'adminSupportChatsOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'adminSupportChatsSearch', searchValue: this.search});
                this.getConversation();
                this.updateOffset();
            }, 800);
        },
        updateOffset(){
            let currentOffset = this.adminSupportChatsOffset + this.RecordLimit;
                this.$store.commit('updateOffset', {offset:'adminSupportChatsOffset', offsetValue: currentOffset});
        },
        async getConversation(){
            if ( !this.noMoreAdminSupportChats ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getConversation(this.adminSupportChatsOffset, this.search, 3);
                this.isLoading  = false;

                if (response.data.type == "success") { 
                    let currentMoreRecord = Object.keys(response.data.guppyMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMoreAdminSupportChats', RecordListValue: currentMoreRecord});
                    if(!this.adminSupportChatsFlag){
                        this.$store.commit('updateListFlag', {flag: 'adminSupportChatsFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateListRecord( recList ){
            let data = {
                'list'      : recList,
                'listType'  : 'adminSupportChats',
                'isScrolling' : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getConversation();
                this.updateOffset();
            }
        },
        openChat( params ){
            params['chatType'] = 1;
            if(this.chatInfo){
                if(this.chatInfo.chatId != params.chatId){
                    let payload = {
                        userName   : this.userProfile.userName,
                        chatType   : this.chatInfo.chatType,
                        chatId     : this.chatInfo.chatId,
                        senderId   : params.chatId,
                        userType   : this.userType,
                        text       : '',
                        groupMembers : []
                    }
                    this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
                }
            }
            this.$store.commit('updateChatInfo', { data: params, userClick : true});
        },
        onlineUserListScroll(){
            const scrollUserList = document.querySelector('.at-onlineusers');
            scrollUserList.addEventListener('wheel', function(e) {
                const race = 15; // How many pixels to scroll

                if (e.deltaY > 0) // Scroll right
                    scrollUserList.scrollLeft += race;
                else // Scroll left
                    scrollUserList.scrollLeft -= race;
                    e.preventDefault();
            });
            let isDown = false;
            let startX;
            let scrollLeft;

            scrollUserList.addEventListener('mousedown', (e) => {
                isDown = true;
                scrollUserList.classList.add('active');
                startX = e.pageX - scrollUserList.offsetLeft;
                scrollLeft = scrollUserList.scrollLeft;
            });
            scrollUserList.addEventListener('mouseleave', () => {
                isDown = false;
                scrollUserList.classList.remove('active');
            });
            scrollUserList.addEventListener('mouseup', () => {
                isDown = false;
                scrollUserList.classList.remove('active');
            });
            scrollUserList.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - scrollUserList.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                scrollUserList.scrollLeft = scrollLeft - walk;
            });
        }
    },
    created(){
        if( !Object.keys(this.conversation).length || !this.adminSupportChatsFlag){
            this.getConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.adminSupportChatsSearch;
    },
};
</script>
<style>
.at-onlineusers{
    margin: 0;
    display: flex;
    overflow: auto;
    margin: 0 -20px;
    list-style: none;
    padding: 30px 0 10px;
    height: auto !important;
    width: calc(100% + 40px);
    flex-direction: row !important;
}
.at-onlineusers::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.at-onlineusers > li{
    border: 0;
    flex: none;
    display: block;
    cursor: pointer;
    max-width: 85px;
    list-style-type: none;
    padding: 0 !important;
    background: transparent;
    margin-bottom: 0 !important;
}
.at-onlineusers > li:first-child{
    margin-left: 20px;
}
.at-onlineusers > li:last-child{
    margin-right: 20px;
}
.at-onlineusers .at-userstatus{
    right: 0;
    bottom: 0;
    border: 2px solid #fff;
}
.at-onlineusers .at-userstatus:before{
    width: 8px;
    height: 8px;
}
.at-onlineusers figure{
    max-width: 50px;
    position: relative;
    margin: 0 auto !important;
}

.at-onlineusers .at-singleimg img{
    width: 50px;
    height: 50px;
}
.at-userlist_tab .at-sidebarhead_searchv2 {
    padding: 7.5px 0;
    border-bottom: 1px solid #eee;
}
.at-userlist_tab .at-sidebarhead_searchv2 .at-form-group input {
    border: 0;
    padding-left: 43px;
}
.at-admin-chat .at-sidebarhead_searchv2 .at-form-group input {
    height: 44px;
}
</style>