<template>
  <div>
    <!-- Block User PopUp -->
    <template>
      <div id="blockuser" class="at-modal">
        <div class="at-modaldialog">
          <div class="at-alert">
            <i class="guppy-alert-circle"></i>
            <h2>{{chatInfo.isBlocked ? TRANS.unblock_user_heading.replace('((username))', chatInfo.userName) : TRANS.block_user_title.replace('((username))', chatInfo.userName)}}</h2>
            <p>{{ chatInfo.isBlocked ? TRANS.unblock_user_description : TRANS.block_user_description}}</p>
            <template v-if="chatInfo.postId && !chatInfo.isBlocked">
              <div class="at-radio-holder">
                <div class="at-radio">
                  <input type="radio" name="for_post" id="for_this_post"  v-bind:value="1" v-model="postBlockType">
                  <label for="for_this_post">{{TRANS.for_this_post}}</label>
                </div>
                <div class="at-radio">
                  <input type="radio" name="for_post" id="for_all_post"  v-bind:value="0" v-model="postBlockType">
                  <label for="for_all_post">{{TRANS.for_all_post}}</label>
                </div>
              </div>
            </template>

            <a
              href="javascript:void(0);" 
              @click.prevent="toggleBlockUser()" 
              :class="{'at-disable-btn' : isloadingUpdt }" 
              class="at-btn">
              {{chatInfo.isBlocked ? TRANS.unblock_button : TRANS.block_user_button}}
            </a>
            <a 
              href="javascript:void(0);" 
              class="at-anchor at-guppy-removemodal">
              {{TRANS.not_right_now}}
            </a>
          </div>
        </div>
      </div>
    </template>
    <!-- Clear PopUp -->
    <template>
      <div id="clearchat" class="at-modal">
        <div class="at-modaldialog">
            <div class="at-alert">
                <i class="guppy-alert-circle"></i>
                <h2>{{ TRANS.clear_chat }}</h2>
                <p>{{ TRANS.clear_chat_description}}</p>
                <a 
                  href="javascript:void(0);" 
                  @click.prevent="clearChat()" 
                  :class="{'at-disable-btn' : isloadingUpdt }" 
                  class="at-btn">
                  {{TRANS.clear_chat_button}}
                </a>
                <a href="javascript:void(0);" class="at-anchor at-guppy-removemodal">{{TRANS.not_right_now}}</a>
            </div>
        </div>
      </div>
    </template>

    <!-- Report chat -->
    <template>
      <div id="reportchat" class="at-modal">
        <div class="at-modaldialog">
            <div class="at-alert">
                <h2>{{ TRANS.report_heading.replace('((username))', chatInfo.chatType == '1' || chatInfo.chatType == '0' ? chatInfo.userName : chatInfo.groupTitle)}}</h2>
                <p>{{TRANS.report_description}}</p>
                <form class="at-themeform">
                    <fieldset>
                        <div class="at-form-group-wrap">
                            <div class="at-form-group">
                                <label class="at-formtitle at-important">{{TRANS.report_title}}</label>
                                <div class="at-select at-dropdownholder">
                                    <div class="at-form-control"><span class="at-placeholder">{{this.reason ? this.reason : TRANS.report_reason}}</span></div>
                                    <ul class="at-dropdown">
                                        <li 
                                          v-for="(val, index) in reportingReasons" 
                                          @click="reason = val" :key="index">
                                          <a href="javascript:void(0);">{{val}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="at-form-group">
                                <label class="at-formtitle at-important">{{TRANS.report_issue_detail}}</label>
                                <textarea 
                                  class="at-form-control" 
                                  v-model.trim="reportdesc" 
                                  :placeholder="TRANS.report_add_description">
                                </textarea>
                            </div>
                            <div class="at-form-group">
                                <ul class="at-btnlist">
                                    <li><a  href="javascript:void(0);"  @click.prevent="reportChat()" :class="{'at-disable-btn' : isloadingUpdt }"  class="at-btn">{{TRANS.report_submit}}</a></li>
                                    <li><a href="javascript:void(0);" class="at-btn at-btnv2 at-guppy-removemodal">{{TRANS.report_cancel}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
      </div>
    </template>

    <!-- create Group PopUp -->
    <template>
      <div id="creategroup" class="at-modal at-creategroup">
        <div class="at-modaldialog">
            <div class="at-popupcontent">
                <div class="at-popuptitle">
                  <h2>{{ isEditGroup ? TRANS.edit_group_txt : TRANS.create_new_group_txt }}</h2>
                  <a href="javascript:void(0);" id="close-group" class="at-guppy-removemodal" @click.prevent="closeModal('group_update')"><i class="guppy-x"></i></a>
                </div>
                <form class="at-themeform">
                  <fieldset>
                    <div class="at-form-group-wrap">
                      <div class="at-form-group">
                          <label class="at-formtitle">{{TRANS.grp_photo_txt}}</label>
                          <div class="at-dropbox at-dropcontentholder">
                              <div class="at-dropboxuploader">
                                <div class="at-viewuploadimg">
                                  <template v-if="isCroppingImage">
                                    <vue-croppie ref="croppieRef" :enableResize="false" :boundary="croppieBoundry" :viewport="croppieViewport"></vue-croppie>
                                  </template>
                                  <figure class="at-uploadimg" v-else-if="uploadImage">
                                    <img :src="uploadImage" />
                                  </figure>
                                  <figure class="at-uploadimg" v-else-if="groupData.groupImage">
                                    <img :src="groupData.groupImage" />
                                  </figure>
                                  <i v-else class="guppy-image"></i>
                                  <a @click.prevent="croppedImage()" v-if="isCroppingImage" href="javascript:void(0);" class="at-cropimg">{{TRANS.crop_img_txt}}</a>
                                  <a v-else-if="groupData.groupImage || uploadImage" @click.prevent="removeImage()" href="javascript:void(0);" class="at-removeuploadimg">{{TRANS.remove}}</a>
                                </div>
                                
                                  <input type="file" :accept="uploadImgExt.join(',')" id="dropboxv2" name="dropbox" @change.prevent="onImageUpload">
                                  <label for="dropboxv2"><span>{{capitalizeText(TRANS.click_here)}}&nbsp;</span>{{TRANS.grp_photo_dsc_txt}}</label>
                              </div>
                              <div class="at-dropcontent at-none">
                                  <div class="at-droptitle">
                                    <span>Profile_photo3869.jpg</span>
                                    <div class="at-progressbarholder">
                                      <div class="at-progressbar">
                                          <div class="at-progressbar_fill" style="width: 50%;"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <a href="javascript:void(0);" class="at-removedrop"><i class="guppy-trash"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="at-form-group"> 
                          <label class="at-formtitle at-important">{{ isEditGroup ? TRANS.grp_edit_title_txt : TRANS.grp_title_txt }}</label>
                          <input type="text" v-model="groupData.groupTitle" class="at-form-control" :placeholder="TRANS.grp_title_placeholder_txt" required>
                      </div>
                      <div class="at-form-group"> 
                          <label class="at-formtitle at-important">{{ isEditGroup ? TRANS.edit_group_desc_txt : TRANS.add_group_desc_txt }}</label>
                          <textarea v-model="groupData.groupDescription" class="at-form-control" :placeholder="isEditGroup ? TRANS.edit_group_desc_txt : TRANS.add_group_desc_txt" ></textarea>
                      </div>
                      <div class="at-form-group">
                          <label class="at-formtitle at-important">{{ isEditGroup ? TRANS.grp_edit_users_txt : TRANS.grp_users_txt }}</label>
                          <div class="at-creategrouplist">
                              <div class="at-groupusersearch" v-if="( groupMembers.length > 10 || isSearching || search)">
                                <i class="guppy-search"></i>
                                <input type="text" v-model="search" @input="searchMember()" :placeholder="TRANS.search_txt">
                              </div>
                              <ul v-if="groupMembers.length && !isSearching" @scroll="scrollHandler($event)" class="at-grouplist" ref="groupMembersList">
                                  <li v-for="user in groupMembers" :key="user.userId" class="at-groupuserbar">
                                      <input type="checkbox" @change="selectGroupMember(user.userId)" v-model="groupData.memberIds" :value="user.userId" :id="'checkv_'+user.userId" name="check">
                                      <label class="at-groupuserbar_content" :for="'checkv_'+user.userId">
                                          <span class="at-groupuserbar_profile">
                                              <img :src="user.userAvatar" alt="profile image" class="mCS_img_loaded">
                                          </span>
                                          <span class="at-groupuserbar_title">{{user.userName}}</span>
                                          <!-- MAKE ADMIN TEXT -->
                                          <a href="javascript:void(0);" @click="removeAdmin(user.userId)" v-if="groupData.adminIds.includes(user.userId)" class="at-makeadmin at-makeadmin-check">{{TRANS.grp_admin_txt}}</a>
                                          <a href="javascript:void(0);" v-else class="at-makeadmin" @click.prevent="selectAdmin(user.userId)">{{TRANS.grp_mk_admin_txt}}</a>
                                      </label>
                                  </li>
                                  <guppy-chat-inner-loader v-if="isScrollList"/>
                              </ul>
                              <template v-else>
                                  <guppy-chat-loader v-if="isSearching"/>
                                  <guppy-chat-empty-view v-else :isSidebarProps="true" :listIcon="'guppy-users'"/> 
                              </template>
                          </div>
                      </div>
                      <div class="at-form-group">
                          <ul class="at-btnlist">
                              <li class="at-groupuserbar">
                                <input type="checkbox" v-model="groupData.disableReply" id="disable_reply" name="check">
                                <label class="at-groupuserbar_content" for="disable_reply">
                                    <span class="at-groupuserbar_title">{{TRANS.disable_grp_txt}}</span>
                                </label>
                              </li>
                              <li>
                                <button 
                                  type="submit" 
                                  class="at-btn"
                                  @click.prevent="updateGroup()"
                                  :disabled="disableSubmit || isSavingUpdt"
                                  :class="{'at-disable-btn' : isSavingUpdt }"
                                >
                                  {{ isEditGroup ? TRANS.grp_updt_txt : TRANS.grp_create_txt }}
                                </button>
                              </li>
                          </ul>
                      </div>
                    </div>
                  </fieldset>
                </form>
            </div>
        </div>
      </div>
    </template>

    <!-- Delete group -->
    <template>
      <div id="deletegroup" class="at-modal">
        <div class="at-modaldialog">
            <div class="at-alert">
                 <i class="guppy-alert-circle"></i>
                <h2>{{TRANS.delete_grp_txt.replace('((groupname))', chatInfo.groupTitle)}}</h2>
                <p>{{TRANS.delete_group_desc}}</p>
                <a 
                  href="javascript:void(0);" 
                  @click.prevent="deleteGroup()" 
                  :disabled="isDeletingGroup"
                  :class="{'at-disable-btn' : isDeletingGroup }" 
                  class="at-btn">
                  {{TRANS.delete_group_opt_txt}}
                </a>
                <a href="javascript:void(0);" id="delete_group_btn" class="at-anchor at-guppy-removemodal">{{TRANS.not_right_now}}</a>
            </div>
        </div>
      </div>
    </template>

    <!-- Delete group member -->
    <template>
      <div id="delete_group_member" class="at-modal">
        <div class="at-modaldialog">
            <div class="at-alert">
                <i class="guppy-alert-circle"></i>
                <h2>{{TRANS.delet_grp_member_txt.replace('((username))', deleteGroupMember.userName)}}</h2>
                <p>{{TRANS.delet_grp_member_dsc}}</p>
                <a href="javascript:void(0);" 
                  @click.prevent="deleteMember()" 
                  class="at-btn"
                  :class="{'at-disable-btn' : isDeletingMember }"  
                  >{{TRANS.delet_grp_member_btn}}</a>
                <a href="javascript:void(0);" 
                  id="delete_group_btn" 
                  class="at-anchor at-guppy-removemodal"
                  >{{TRANS.not_right_now}}</a>
            </div>
        </div>
      </div>
    </template>

    <!-- Leave Group PopUp -->
    <template>
      <div id="leavegroup" class="at-modal">
        <div class="at-modaldialog">
          <div class="at-alert">
              <i class="guppy-alert-circle"></i>
              <h2>{{TRANS.leave_group_heading}}</h2>
              <p>{{TRANS.leave_group_dsc.replace('((groupname))', chatInfo.groupTitle)}}</p>
              <a href="javascript:void(0);" 
                  @click="leaveGroup(false)" 
                  :class="{'at-disable-btn' : isLeavingGroup }"
                  :disabled="isLeavingGroup"
                  class="at-btn">{{TRANS.leave_group_opt_txt}}</a>
              <a href="javascript:void(0);" class="at-anchor at-guppy-removemodal" id="close_leave_group_modal">{{TRANS.not_right_now}}</a>
          </div>
        </div>
      </div>
    </template>

    <!-- Leave admin Group PopUp -->
    <template>
      <div id="admin_leave_group" class="at-modal at-admin-leavegroup">
        <div class="at-modaldialog">
            <div class="at-popupcontent">
                <div class="at-popuptitle">
                  <h2>{{ TRANS.before_leave_heading }}</h2>
                  <a href="javascript:void(0);" id="admin_leave_group" class="at-guppy-removemodal"><i class="guppy-x"></i></a>
                </div>
                <div class="at-assignlist-wrap">
                    <label class="at-formtitle at-important">{{TRANS.assign_group_admin_txt}}</label>
                    <div class="at-form-search">
                      <i class="guppy-search"></i>
                      <input type="search" v-model="searchMemberName" @input="searchUser()" name="search" placeholder="Search here" class="at-form-control">
                    </div>
                    <ul class="at-assignlist">
                      <li v-for="member in groupMembersList" :key="member.userId">
                        <figure>
                          <img :src="member.userAvatar" :alt="member.userName">
                          <figcaption>{{member.userName}}</figcaption>
                        </figure>
                        <a 
                          href="javascript:void(0)" 
                          @click.prevent="setAdminUser(member.userId)" 
                          class="at-makeadmin"
                          :class="{'at-makeadmin-check': selectAdminIds.includes(member.userId)}"
                          >{{ selectAdminIds.includes(member.userId) ? TRANS.grp_admin_txt : TRANS.grp_mk_admin_txt}}</a>
                      </li>
                    </ul>
                </div>
                <div class="at-popupfooter">
                  <a 
                    href="javascript:void(0)" 
                    @click.prevent="leaveGroup(true)" 
                    class="at-btn at-leavebtn"
                    :disabled="isLeavingGroup"
                    :class="{'at-disable-btn' : isLeavingGroup }"
                    >
                    {{TRANS.assign_grp_admin_btn_txt}}
                  </a>
                </div>
            </div>
        </div>
      </div>
    </template>

  <!-- Crop Profile image popup -->
    <template>
			<div id="image_crop" class="at-modal at-cropping-popup" :class="{'at-modalopen at-fadin' : isCroppedImage}">
        <div class="at-modaldialog"> 
            <div class="at-alert">
              <vue-croppie ref="profCroppieRef" :enableResize="false" :boundary="proCroppieBoundry" :viewport="proCroppieViewport"></vue-croppie>
					      <div class="at-form-group">
                    <ul class="at-btnlist">
                        <li><a  href="javascript:void(0);"  @click.prevent="proCroppedImage()" :class="{'at-disable-btn' : isloadingUpdt }" class="at-btn">{{TRANS.crop_img_txt}}</a></li>
                        <li><a href="javascript:void(0);" id="cancel_crop_image" @click.prevent="closePopUp('cropImage')" class="at-btn at-btnv2">{{TRANS.cancel_txt}}</a></li>
                    </ul>
                </div>
              </div>

          </div>
      </div>
    </template>

    <!-- Accept invite popup -->
    <template>
			<div id="accept_invite" class="at-modal at-invitepopup" :class="{'at-modalopen at-fadin' : isOpenInvitePopUp}">
        <div class="at-modaldialog"> 
          <guppy-chat-invite-response :isShowDefaultMessage="false"/>
          </div>
      </div>
    </template>
    <!-- Media gallery -->
    <template>
       <LightBox ref="galleryLightbox" :closeText="TRANS.gallery_close_btn" :previousText="TRANS.gallery_previous_btn" :nextText="TRANS.gallery_next_btn" :nThumbs="15" :showLightBox="false" :media="media"></LightBox>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from "vuex";
import jQuery from "jquery";
import {GUPPYCHAT_REPORTINGREASONS, GUPPYCHAT_SETTINGS } from "../../resources/constants";
import GuppyChatManager from "../../resources/controller";
import RestApiManager from "../../resources/RestApiController";
import GuppyChatLoader from "../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatInnerLoader from "../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatEmptyView from "../EmptyViews/GuppyChatEmptyView.vue";
import GuppyChatInviteResponse from "./GuppyChatInviteResponse/GuppyChatInviteResponse.vue";
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'
Vue.use(VueCroppie);
import LightBox from 'vue-it-bigger'
require('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
Vue.component('LightBox', LightBox)

export default {
  name : "GuppyChatSidebarSettingPopup",
  components:{
    GuppyChatInnerLoader,
    GuppyChatLoader,
    GuppyChatEmptyView,
    GuppyChatInviteResponse,
  },
  data(){
    
    return {
      isloadingUpdt : false,
      isSavingUpdt : false,
      reportdesc : '',
      reason    : '',
      search    : '',      
      reportingReasons : GUPPYCHAT_REPORTINGREASONS,
      uploadImgExt    : GUPPYCHAT_SETTINGS.imgExt,
      lastScrollTop : 0,
      postBlockType : 0,
      groupData : {
        'disableReply'  : '',
        'groupImage'    : '',
        'groupTitle'    : '',
        'groupDescription'    : '',
        'memberIds'     : [],
        'adminIds'      : [],
        'userId'        : '',
        'removeImage'   : 0,
      },
      file : '',
      uploadImage : '',
      timer : null,
      isEditGroup : false,
      isDeletingMember : false,
      isDeletingGroup : false,
      isLeavingGroup : false,
      groupMembersList  : [],
      searchMemberList  : [],
      selectAdminIds    : [],
      searchMemberName  : '',
      isSearching : false,
      isCroppingImage : false,
      croppieBoundry : {
        width: 450, 
        height: 300,
      },
      croppieViewport : {
        width:400, 
        height:250, 
        'type':'square'
      },
      proCroppieBoundry : {
        width: 600, 
        height: 460,
      },
      proCroppieViewport : {
        width:500, 
        height:450, 
        'type':'square'
      },
      isCroppedImage : false,
      isOpenInvitePopUp : false,
      startAt : 0,
      media : []
    }
  },
  computed: {
    galleryItems(){
      return this.$store.getters.galleryItems();
    },
    chatInfo(){
      return this.$store.getters.getChatInfo()
    },
    getUserRole(){
      return this.$store.getters.getUserRole()
    },
    ...mapState({
      deleteGroupMember : state => state.deleteGroupMember,
      isScrollList      : state => state.isScrollList,
      groupId           : state => state.groupId,
      groupMembers      : state => {
        if(state.chatInfo.groupDetail){
          if(state.groupDetail.isEditGroup){
            let selectedMember = Object.values(state.chatInfo.groupDetail.memberAvatars).filter( member => Number(member.memberStatus) == 1 );
            let removedMembers = selectedMember.filter( member => Number( member.groupRole ) == 1 || member.userId == state.userId)
            let ids            = new Set( removedMembers.map( user => Number(user.userId)) );
            let userList       = JSON.parse( JSON.stringify( state.groupMembers ) );
            let filterdMembers = userList.filter((member) => {
                return !ids.has(Number(member.userId))
            });
            return filterdMembers;
          } else {
            return state.groupMembers;
          }
        }
        return state.groupMembers 
      },
      disableSubmit(){
        let {groupTitle, memberIds} = this.groupData;
        if( groupTitle.trim() && memberIds.length ){
          return false
        }
        return true
      },
      getGroupDetail : state => state.groupDetail,
    }) 
  },
  watch:{
    groupMembers(){
      this.isSearching = false;
    },
    getGroupDetail( newVal ){
      if( !newVal.isEditGroup ){  // manage data for create group
        this.groupData = {
          'groupId'       : '',
          'disableReply'  : '',
          'groupTitle'    : '',
          'groupDescription'    : '',
          'memberIds'     : [],
          'adminIds'      : [],
          'userId'        : '',
          'groupImage'    : '',
          'isEditGroup'   : false,
          'removeImage'   : 0,
        }
        this.uploadImage  = '';
        this.search       = '';
        this.isEditGroup  = false;
        this.$store.state.isEditGroup = false;
      } else {  // manage data for update group
        let memberIds= [];
        let adminIds = [];

        if( this.chatInfo.groupDetail ) {
          let members     = Object.values( this.chatInfo.groupDetail.memberAvatars ).filter(member => Number(member.memberStatus) == 1);
              members     = members.filter( member => member.userId != this.userId );
          let userAdmins  = members.filter( member => member.groupRole == 2 && member.userId != this.userId);
              memberIds   = members.map(  member => member.userId );
              memberIds   = memberIds.filter( id => id != this.userId );

              if(userAdmins.length){
                adminIds  = userAdmins.map( member => member.userId )
              }

               this.groupData = {
                  'groupId'           : this.chatInfo.chatId.split('_')[0],
                  'disableReply'      : this.chatInfo.groupDetail ? this.chatInfo.groupDetail.disableReply : '',
                  'groupDescription' : this.chatInfo.groupDetail ? this.chatInfo.groupDetail.groupDescription : '',
                  'groupTitle'        : this.chatInfo.groupTitle,
                  'memberIds'         : memberIds,
                  'adminIds'          : adminIds,
                  'userId'            : this.userId,
                  'groupImage'        : this.chatInfo.groupImage,
                  'isEditGroup'       : true,
                  'removeImage'       : 0,
                }
                this.uploadImage  = ''; // for update new image
                let groupMembers  = JSON.parse(JSON.stringify(Object.values( this.chatInfo.groupDetail.memberAvatars ).filter(member => Number(member.memberStatus) == 1)));
                let userList      = JSON.parse( JSON.stringify( this.$store.state.groupMembers ) );
                let selectedUsers = Object.values(groupMembers).filter( member => Number( member.groupRole ) != 1 && member.userId != this.userId)
                let ids           = new Set(selectedUsers.map( user => Number(user.userId)));
                let merged        = [...selectedUsers, ...userList.filter(user => !ids.has(Number(user.userId)))];
                this.isEditGroup  = true;
                this.$store.state.groupMembers = merged;
                this.$store.state.isEditGroup = true;
        }
      }
    }
  },
  methods:{
    previewImage(messageId){
      this.media  = this.galleryItems
      let index   = this.galleryItems.findIndex(item => item.messageId == messageId);
      if(index > -1) {
        this.startAt = index;
        this.$refs.galleryLightbox.showImage(index)
      }
    },
    closePopUp( popUp ){
      switch(popUp){
        case 'cropImage':
          this.isCroppedImage = false;
          break;
        case 'acceptInvite':
          this.isOpenInvitePopUp = false;
          break;
      }
    },
    openPopUp( popUp ){
      switch(popUp){
        case 'cropImage':
          this.isCroppedImage = true;
          break;
        case 'acceptInvite':
          this.isOpenInvitePopUp = true;
          break;
      }
    },
    setProfileImage(file){
      var reader 			= new FileReader();
      this.isCroppedImage = true;
      reader.onload = e => {
        this.$refs.profCroppieRef.bind({
          url: e.target.result
        });
      }
      reader.readAsDataURL(file);
    },
    closeModal(type){
      if(type == 'group_update'){
        if(this.$refs && this.$refs.croppieRef){
          this.$refs.croppieRef.destroy();
        }
        this.isCroppingImage = false;
        this.search     = '';
        this.groupData  = {
          'disableReply'  : '',
          'groupImage'    : '',
          'groupTitle'    : '',
          'groupDescription'    : '',
          'memberIds'     : [],
          'adminIds'      : [],
          'userId'        : '',
          'removeImage'   : 0,
        }
      }

    },
    croppedImage() {
			let options = {
				type: 'blob',
				size: { width: 600, height: 450 },
				format: 'jpeg'
			};
			this.$refs.croppieRef.result(options, output => {
			const CroppedImage = new File( [output], 
				`image_${Math.floor(Math.random() * 99999999)}.jpeg`, 
				{ type: output.type, } );
				this.file 		 = CroppedImage;
				this.uploadImage = URL.createObjectURL(CroppedImage);
			});
      setTimeout(()=>{
        this.$refs.croppieRef.destroy();
        this.isCroppingImage = false;
      },100);
		},
    proCroppedImage() {
      let options = {
        type: 'blob',
        size: { width: 600, height: 450 },
        format: 'jpeg'
      };
      let imageUrl = '',file = '';
      this.$refs.profCroppieRef.result(options, output => {
        const CroppedImage = new File( [output], 
          `image_${Math.floor(Math.random() * 99999999)}.jpeg`, 
          { type: output.type, } );
        file 		 = CroppedImage;
        imageUrl = URL.createObjectURL(CroppedImage);
        this.isCroppedImage = false;
        this.$root.$emit('CroppedfileParams', { imageUrl, file })
      });
    },
    capitalizeText(s){
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    removeImage(){
      this.uploadImage = ''; 
      this.groupData.groupImage = '';
      this.groupData.removeImage = 1;
      this.file = '';
    },
    searchUser(){
      var query = this.searchMemberName.toLowerCase();
      this.groupMembersList = this.searchMemberList.filter(item => {
          return item.userName.toLowerCase().indexOf(query) >= 0;
      });
      if(!this.searchMemberName){
        this.groupMembersList = this.searchMemberList;
      }
    },
    setAdmin(){
      let data = {
        'userId'     : this.userId,
        'groupId'    : this.deleteGroupMember.groupId,
        'memberIds'  : this.userId,
      }
      this.isDeletingMember = true;
      this.axios.post('set-guppy-admin-member', data).then(() => {
        this.isDeletingMember = false;
        document.getElementById('close_leave_group').click();
      }).catch(error=>{
        this.isDeletingMember = false;
        console.log(error);
      });
    },
    deleteMember(){
      let data = {
        'userId': this.userId,
        'memberId'  : this.deleteGroupMember.memberId,
        'groupId' : this.deleteGroupMember.groupId,
      }
      this.isDeletingMember = true;
      this.axios.post('delete-guppy-group-member', data).then(response => {
        this.isDeletingMember = false;
        document.getElementById('delete_group_btn').click();
         let userId            = this.userId;
         let chatType         = 2;
          let messagelistData = JSON.parse(JSON.stringify(response.data.messagelistData));
          let chatData        = JSON.parse(JSON.stringify(response.data.chatData));
          let groupMembers    =  response.data.groupMembers;
          let userMessages    =  response.data.userMessages;
          let payload         = { chatData, messagelistData, groupMembers, userMessages, userId, chatType}
          this.$store.dispatch('triggerSocketEvents', {'event':'updateGuppyGroup', payload});
      }).catch(error=>{
        this.isDeletingMember = false;
        console.log(error);
      });
    },
    deleteGroup(){
      let data = {
        'groupId'   : this.chatInfo.chatId.split('_')[0],
        'userId'    : this.userId,
      }
      this.isDeletingGroup = true;
      this.axios.post('delete-guppy-group', data).then(response => {
        this.isDeletingGroup = false;
        if(response.data.type == 'success' ){
          this.$store.commit('deleteGroup', response.data);
          let data = { userId : this.userId, ...response.data}
          this.$store.dispatch('triggerSocketEvents', {'event':'deleteGroup', payload : data } );
          document.getElementById('delete_group_btn').click();
        } else if( response.data.type == "error" ) {
          this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
        }
      }).catch(error=>{
        this.isDeletingGroup = false;
        console.log(error);
      });
    },
    leaveGroup( checkAdmin = false ){
      let data = {
        'groupId'   : this.chatInfo.chatId,
        'userId'    : this.userId,
        'groupRole' : this.getUserRole,
        'memberId'  : this.userId,
        'adminIds'  : this.selectAdminIds,
      }
      if(checkAdmin && !this.selectAdminIds.length){
        this.alertBox('warning', this.TRANS.error_title, this.TRANS.select_admin_text);
        return false;
      }
      this.isLeavingGroup = true;
      this.axios.post('leave-guppy-group', data).then(response => {
        this.isLeavingGroup = false;
        if(response.data.type == 'success'){
          let userId          = this.userId;
          let chatType        = 2;      
          let messagelistData = JSON.parse(JSON.stringify(response.data.messagelistData));
          let chatData        = JSON.parse(JSON.stringify(response.data.chatData));
          let groupMembers    =  response.data.groupMembers;
          let userMessages    =  response.data.userMessages;
          let payload         = { chatData, messagelistData, groupMembers, userMessages, userId, chatType}
          this.$store.dispatch('triggerSocketEvents', {'event':'leaveGuppyGroup', payload});
        } else if(response.data.type == 'error'){
          this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
        }

        if( response.data.type == 'suggested' ) {
          this.groupMembersList = this.searchMemberList = response.data.groupMembers;
          jQuery('.at-modal').removeClass('at-modalopen')
		      jQuery('.at-overlaymodal').remove()
          jQuery('#admin_leave_group').addClass('at-modalopen')
          jQuery('#admin_leave_group').addClass('at-fadin')
          jQuery('.at-overlaymodal').addClass('at-fadin')
          jQuery('body').css('overflow', 'hidden')
          jQuery('body').append("<div class='at-overlaymodal'></div>")
        } else {
          document.getElementById('admin_leave_group').click();
          document.getElementById('close_leave_group_modal').click();
          this.selectAdminIds = this.groupMembersList = this.searchMemberList = [];
        }
      }).catch(error=>{
        this.isLeavingGroup = false;
        console.log(error);
      });
    },
    setAdminUser( userId ){
      if( this.selectAdminIds.includes(userId) ){
        const index = this.selectAdminIds.indexOf(userId);
        if (index > -1) {
          this.selectAdminIds.splice(index, 1);
        }
      } else {
        this.selectAdminIds.push(userId)
      }
    },
    selectGroupMember(userId){
      if(!this.groupData.memberIds.includes(userId)){
        this.removeAdmin(userId)
      }
    },
    updateGroup(){
      if( this.groupData.groupTitle.trim() && this.groupData.memberIds.length ){
        this.groupData.groupTitle = this.groupData.groupTitle.trim();
        let data              = new FormData();
        this.groupData.userId = this.userId;
        this.isSavingUpdt     = true;
        Object.keys(this.groupData).map(item =>{
          data.append(item, this.groupData[item]);
        });

        if( this.file ){data.append(0, this.file);}
        this.axios.post('update-guppy-group', data).then(response =>{
          this.isSavingUpdt     = false;
          if(response.data.type == 'success'){
            document.getElementById('close-group').click();
            let userId          = this.userId;
            let chatType        = 2;
            let messagelistData = JSON.parse(JSON.stringify(response.data.messagelistData));
            let chatData        = JSON.parse(JSON.stringify(response.data.chatData));
            let groupMembers    =  response.data.groupMembers;
            let userMessages    =  response.data.userMessages;
            let payload         = { chatData, messagelistData, groupMembers, userMessages, userId, chatType }
            this.$store.dispatch('triggerSocketEvents', {'event':'updateGuppyGroup', payload});
            this.search     = '';
            this.groupData  = {
              'disableReply'  : '',
              'groupImage'    : '',
              'groupTitle'    : '',
              'groupDescription'    : '',
              'memberIds'     : [],
              'adminIds'      : [],
              'userId'        : '',
              'removeImage'   : 0,
            }
            this.file = '';
            this.isCroppingImage = false;
          } else if( response.data.type == "error" ) {
            this.alertBox('error', this.TRANS.error_title, response.data.message_desc)
          }  
        }).catch(error =>{
          this.isSavingUpdt = false;
          document.getElementById('close-group').click();
          this.search     = '';
          this.groupData  = {
            'disableReply'  : '',
            'groupImage'    : '',
            'groupDescription'    : '',
            'groupTitle'    : '',
            'memberIds'     : [],
            'adminIds'      : [],
            'userId'        : '',
            'removeImage'   : 0,
          }
          this.file = '';
          this.isCroppingImage = '';
          console.log(error)
        });
      }else {
        this.alertBox('warning', this.TRANS.error_title, this.TRANS.empty_field_required)
      }
    },
    searchMember(){
      this.$store.commit('updateRecordList', {RecordList: 'noMoreGroupMemeber', RecordListValue: false});
      clearTimeout(this.timer); 
      this.isSearching = true;
      this.timer = setTimeout(()=>{
          this.$store.commit('updateOffset', { offset:'groupMemberOffset', offsetValue: 0 });
          RestApiManager.getGroupMemberList(false, this.search); //isScrolling
      }, 800)
    },
    onImageUpload(e){
      let file	= e.target.files[0];
      let fileExt = file.name.split('.').pop();
        if( this.uploadImgExt.includes('.'+fileExt) ){
          var reader = new FileReader();
          this.isCroppingImage = true;
					reader.onload = e => {
						this.$refs.croppieRef.bind({
							url: e.target.result
						});
	  			}
					reader.readAsDataURL(file);
				
					
        } else {
          this.alertBox('error', this.TRANS.error_title, this.TRANS.invalid_input_file);
          return;
      }
      e.target.value = ''
    },
    selectAdmin(userId){
      this.groupData.adminIds.push(userId)
    },
    removeAdmin(userId){
      let index = this.groupData.adminIds.indexOf(userId);
      if(index > -1 ){
        this.groupData.adminIds.splice(index, 1);
      }
    },
    async toggleBlockUser(){
      let data = {
            'statusType': this.chatInfo.isBlocked ? 4 : 3,
            'actionTo'  : this.chatInfo.chatId.split('_')[0],
            'userId'    : this.userId,
            'groupId'   : '',
          }
      this.isloadingUpdt = true;
      let response = new Object();
      data['muteNotification']  = this.chatInfo.muteNotification;
      if( this.chatInfo.postId ){
        data['actionTo']  = this.chatInfo.postId;
        data['postId']    = this.chatInfo.postId;
        data['blockType'] = this.postBlockType;
        data['actionTo']  = this.chatInfo.postReceiverId;
        response = await GuppyChatManager.updatePostUserStatus(data);
        this.postBlockType = 0;
      } else {
        response = await GuppyChatManager.updateUserStatus(data);
      }

      jQuery('#blockuser').removeClass('at-modalopen at-fadin');
      jQuery(".at-chat_sidebarsetting").removeClass("at-chat_sidebarsettingopen");
      jQuery(".at-chat_messages").removeClass("at-chat_messagesslide");
      jQuery('body').css('overflow','');
      if( response.data.type == "success" ) {
          this.$store.commit('UpdateSelectUserStatus', response.data.userData );
      } else {
        this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
      }

      this.isloadingUpdt = false;
    },
    clearChat(){
      let actionTo = this.chatInfo.chatId.split('_')[0];
      let chatType = this.chatInfo.chatType;
      let chatId   = this.chatInfo.chatId;
      let groupId  = chatType == 2 ? chatId.split('_')[0] : '';
      let postId   = chatType == 0 ? chatId.split('_')[0] : '';
     
      let data = {
            'actionTo'  : chatType == 0 ? this.chatInfo.postReceiverId : [1,3].includes(chatType) ? actionTo : '', // empty for group case
            'chatId'    : chatId,
            'userId'    : this.userId,
            'groupId'   : groupId,
            'postId'    : postId,
            'chatType'  : chatType,
          }
      this.isloadingUpdt = true;
      this.axios.post("clear-guppy-chat", data).then((response) => {
       
        if( response.data.type == "success" ) {
           this.isloadingUpdt = false;
          jQuery('#clearchat').removeClass('at-modalopen at-fadin');
          jQuery(".at-chat_sidebarsetting").removeClass("at-chat_sidebarsettingopen");
          jQuery(".at-chat_messages").removeClass("at-chat_messagesslide");
          jQuery('body').css('overflow','');
          this.$store.dispatch('clearGuppyChat', data );
          this.$store.dispatch('triggerSocketEvents', {'event':'clearChat', payload : data})
        } else if( response.data.type == "error" ) {
          this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
        }
      }).catch((error) => {
        this.isloadingUpdt = false;
        console.log(error);
      });
    },
    reportChat(){
      if(this.reason !='' && this.reportdesc !=''){
        let data = {
          'chatId'          : this.chatInfo.chatId,
          'userId'          : this.userId,
          'chatType'        : this.chatInfo.chatType,
          'reportReason'    : this.reason,
          'reportDesc'      : this.reportdesc,
          'reportAgainst'   : this.chatInfo.chatType == 1 ? this.chatInfo.userName : this.chatInfo.groupTitle 
        }
        this.isloadingUpdt = true;
        this.axios.post("report-guppy-chat", data).then((response) => {
        
          if( response.data.type == "success" ) {
            this.isloadingUpdt = false;
            jQuery('#reportchat').removeClass('at-modalopen at-fadin');
            jQuery(".at-chat_sidebarsetting").removeClass("at-chat_sidebarsettingopen");
            jQuery(".at-chat_messages").removeClass("at-chat_messagesslide");
            jQuery('body').css('overflow','');
            this.reason = this.reportdesc = '';
          } else if( response.data.type == "error" ) {
            this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
          }
        }).catch((error) => {
          this.isloadingUpdt = false;
          this.reason = this.reportdesc = '';
          console.log(error);
        });
      }else{
        this.alertBox('error', this.TRANS.error_title, this.TRANS.empty_field_required);
      }
    },
    scrollHandler(e) {
      if(e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight ){
        RestApiManager.getGroupMemberList(true, this.search); //isScrolling
      }
    },
  },
  mounted(){
    this.$root.$on('croppedProfileImage', (data)=>{
      this.setProfileImage(data);
    });
    this.$root.$on('openPopUp', (data)=>{
      this.openPopUp(data.type)
    });
    this.$root.$on('closePopUp', (data)=>{
      this.closePopUp(data.type)
    });
    this.$root.$on('previewImage', (data)=>{
      this.previewImage(data.messageId)
    });
  },
}
</script>

<style>
.at-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10501;
  display: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.at-modal:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.at-modalopen {
  display: flex;
}

.at-modaldialog {
  width: 100%;
  margin: auto;
  display: flex;
  padding: 30px 0;
  max-width: 528px;
  align-items: center;
}
.at-chat575 .at-modaldialog{
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.at-guppyside-responsive .at-modaldialog{
  padding-left: 15px;
  padding-right: 15px;
}
.at-modaldialog .at-alert {
  max-width: initial;
  width: 100%;
}

.at-fadin {
  opacity: 1;
}
.at-alert .at-themeform{
  text-align: left;
}
.at-chat textarea.at-form-control {
    resize: none;
    padding-top: 12px;
}
.at-chat .at-dropdownholder .at-form-control {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.at-dropdownholder .at-form-control > span,
.at-placeholder {
  color: var(--terguppycolor);
  font: 400 14px/24px var(--secchatfont);
}
.at-dropdownholder .at-form-control > span:not(.at-placeholder){color: var(--secguppycolor);}
.at-dropdown {
  margin:0;
  padding: 0;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 99;
  left: 0;
  font: 500 14px/2em "Urbanist", sans-serif;
  flex-direction: column;
  -webkit-box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  max-height: 200px;
  overflow-y: auto;
}
.at-dropdown li {
  line-height: inherit;
  list-style-type: none;
  outline: none;
}
.at-dropdown a {
  cursor: pointer;
  margin: 0;
  align-items: center;
  font-weight: 500;
  padding: 6px 20px;
  display: flex;
  color: #0A0F26;
  transition: all 0.3s ease-in-out;
}
.at-dropdown a:hover {
  background-color: #F7F7F7 !important;
}
.at-dropdown a:focus{
  outline: none !important;
}
.at-dropdown a i {
  color: var(--terguppycolor);
  font-size: 18px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}
.at-dropdown.at-uploadoption_open{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.at-dropcontentholder {
  text-align: left;
  padding: 20px;
  background: #fff !important;
}

.at-dropboxuploader {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}

.at-dropcontent {
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: 100%;
}
.at-dropcontent img {
  margin-right: 10px;
  border-radius: 10px;
  flex: none;
}
.at-dropcontent .at-removedrop {
  font-size: 20px;
  color: #EF4444;
  margin-left: auto;
}

.at-droptitle {
  flex: auto;
}
.at-droptitle > span {
  font: 500 0.875rem/1.5714285714em "Urbanist", sans-serif;
  letter-spacing: 0.5px;
  display: block;
}

.at-progressbarholder {
  position: relative;
  height: 3px;
  margin-top: 10px;
}

.at-progressbar {
  width: 100%;
  background-color: #F7F7F7;
}

.at-progressbar,
.at-progressbar_fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  border-radius: 60px;
}

.at-progressbar_fill {
  background-color: #FF7300;
}
.at-dropboxuploader i {
  line-height: 60px;
  width: 60px;
  text-align: center;
	background: #F7F7F7;
	border-radius: 10px;
	font-size: 28px;
	color: var(--terguppycolor);
	margin-bottom: 10px;
	display: inline-block;
}
.at-creategroup .at-dropbox{
  border-radius: 4px;
  padding: 30px 20px;
}
.at-makeadmin{
  flex: none;
  margin-left: auto;
  padding: 3px 8px;
  border: 1px solid #DDDDDD;
  border-radius: 3px;
  letter-spacing: 0.01em;
  font-family: var(--secchatfont);
  color: #999999 !important;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: none;
}
input[type="checkbox"]:checked + .at-groupuserbar_content .at-makeadmin{
  display: inline-flex;
  visibility: visible;
  opacity: 1;
}
.at-makeadmin i{
  margin-right: 2px;
  font-weight: 700;
}
.at-makeadmin:not(.at-makeadmin-check){
  opacity: 0;
  visibility: hidden;
}
.at-groupuserbar_content:hover .at-makeadmin:not(.at-makeadmin-check){
  opacity: 1;
  visibility: visible;
}
.at-makeadmin-check{
  background: #22C55E;
  border-color: #22C55E;
  color: #fff !important;
}
.at-makeadmin-check::before{
  content: "\e92b";
  font-family: 'guppy-icomoon';
  color: #fff;
  margin-right: 4px;
}
.at-dropboxuploader img{
  margin: 0 0 10px;
}
.at-creategroup .at-form-group .at-btnlist{
  width: auto;
  box-shadow: inset 0px 1px 0px #dddddd;
  margin: 0 -30px -10px -30px;
  padding: 10px 20px 0;
}
.at-btnlist .at-groupuserbar_content{
  padding: 0 0 0 34px !important;
}
.at-btnlist .at-groupuserbar_title{
  white-space: pre-wrap;
  letter-spacing: 0.01em;
  font: 400 16px/26px var(--secchatfont);
}
.at-btnlist .at-groupuserbar_content:hover,
.at-btnlist .at-groupuserbar:hover{
  background: transparent;
}
.at-btnlist .at-groupuserbar_content::after{
  left: 0;
}
 .at-creategroup .at-popupcontent{ 
   background: transparent;
 }
 .at-creategroup .at-themeform{
   background-color: #fff;
 }
 /* Leave Group */
 .at-assignlist-wrap .at-form-search{ 
   margin: 0 0 10px;
 }
.at-form-search{
  position: relative;
  display: flex;
  align-items: center;
}
.at-form-search i{
  position: absolute;
    left: 18px;
    font-size: 18px;
    color: var(--terguppycolor);
}
.at-chat .at-form-search .at-form-control{
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 40px;
  outline: none;
}
.at-admin-leavegroup .at-themeform fieldset{
  margin: -5px;
}
.at-admin-leavegroup .at-themeform fieldset .at-form-group{padding: 5px;}
.at-assignlist{
  margin: 0;
  padding: 0;
  max-height: 350px;
  overflow: auto;
}
.at-assignlist li{
  display: flex;
  padding: 10px 14px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.at-assignlist li:hover{
  background: #F7F7F7;
}
.at-assignlist .at-makeadmin{
  margin-left: auto;
  opacity: 1;
  visibility: visible;
  display: inline-block;
  padding: 3px 6px;
}
.at-assignlist figure{
  margin: 0;
  display: flex;
  align-items: center;
}
.at-assignlist figure img{
  max-width: 30px;
  border-radius: 50%;
  max-height: 30px;
  margin-right: 10px;
}
.at-assignlist figure figcaption{
  font: 700 14px/26px var(--primchatfont);
  color: var(--secguppycolor);
}
.at-leavebtn{
  background-color: #EF4444 !important;
}
.at-uploadimg{
  display: flex;
  min-width: 200px;
  min-height: 200px;
  max-height: 400px;
  align-items: center;
  padding-bottom: 40px;
  justify-content: center;
}
.at-uploadimg img{
  border-radius: 5px 5px 0 0;
}
 @media (max-width: 480px){
  .at-creategroup .at-form-group .at-btnlist{
    padding: 10px 10px 0;
    margin: 0 -15px -10px;
  }
}
</style>