import Vue from 'vue';
import Vuex from 'vuex';
import Pusher from 'pusher-js';
import jQuery from "jquery";
import GuppyChatManager from "../resources/controller";
import SocketioService from '../services/socketio.service';
import { State, Actions, Mutations } from './Stores'
import RestApiManager from "../resources/RestApiController";
import { GUPPYCHAT_CONSTANTS, GUPPYCHAT_TRANSLATION as TRANS } from "../resources/constants";
import dateFormat from "dateformat";
import moment from "moment";

import { GUPPYCHAT_SETTINGS } from "../resources/constants";
Vue.use(Vuex)


var canType = true;
var throttleTime = 1500;


export default new Vuex.Store({
  state: {
    ...State,
    userId             : window.wpguppy_scripts_vars.userId, // current loggedin user id
    userType           : window.wpguppy_scripts_vars.userType,
    isSupportMember    : GUPPYCHAT_SETTINGS.isSupportMember,
    FriendsOffset 		 : 0,
    BlocksOffset 		   : 0,
    isAdminChat        : false,
    isScrollList 		   : false,
    pusher 		         : false,
    noMoreFriends      : false,
    noMoreBlockUsers   : false,
    invitationResp     : false,
    ReceiveInvitation  : false,
    userFriendFlag     : false,
    userBlockFlag      : false,
    postFlag           : false,
    autoInvite         : true,
    isEmptyView        : true,
    isOpenMessagesScr  : false,
    isMessangerChat    : false, 
    isWhatsappChat     : false, 
    isMobileView       : false, 
    isDeletingMessage  : false,
    isfloatingChat     : false,
    userResponseData   : '',
    unseenPostMsgCount : '',
    unseenMsgCount     : '',
    userProfile        : '',
    quoteMessageId 		 : {},
    widgetChatMessageInput : {},
    messageInput       : '',
    friendSearch       : '',
    blockUserSearch    : '',
    activeTab          : 'users',
    messagesList       : {},
    deleteConversations : [],
  },
  getters: {
    // put sychronous functions for changing state e.g. add, edit, delete

    attachmentList : (state) => ( chatId = '') => {
      let selectedId = "";
      if(state.isMessangerChat){
        selectedId = state.chatInfo.chatId;
      } else if( chatId ){
        selectedId = chatId;
      } else if( state.selectedChatId ){ // for popUps
        selectedId = state.selectedChatId;
      }
      let _record    = state.messagesList[selectedId];
        if( _record && _record.mediaAttachments){
          return _record.mediaAttachments;
        }
        return []
    },
    galleryItems : (state) => ( chatId = '') => {
      let selectedId = "";
      if(state.isMessangerChat){
        selectedId = state.chatInfo.chatId;
      } else if( chatId ){
        selectedId = chatId;
      } else if( state.selectedChatId ){ // for popUps
        selectedId = state.selectedChatId;
      }

      let mediaAttachments = [];

      let record    = state.messagesList[selectedId];
        if( record && record.mediaAttachments){
          if(record.mediaAttachments.length){
            record.mediaAttachments.forEach(item=>{
              let data = null;
              if(item.type == 'images'){
                data = { 
                  type: 'image',
                  thumb: item.thumbnail,
                  src: item.file,
                  messageId : item.messageId
                }
              } else if(item.type == 'video'){
                let videoType = item.file.split('.').pop();
                data = { 
                  type: 'video',
                  thumb: GUPPYCHAT_CONSTANTS.VIDEO_THUMBNAIL,
                  sources: [
                    {
                      src: item.file,
                      type: 'video/'+videoType
                    }
                  ],
                  messageId : item.messageId,
                  width: 800,
                  height: 600,
                  autoplay: false 
                }
              }
              if(data){
                mediaAttachments.push(data)
              }
            });
          }
          return mediaAttachments;
        }
        return [];
    },
    getTotalUnreadCount: (state) => {
      let privateCount      = state.privateChatCount
      let postmessagesCount = state.postmessagesCount;
      let requestCount      = state.requestCount;
      return Number(privateCount) + Number(postmessagesCount) + Number(requestCount);
    },
    getMessageInput : ( state ) => ( chatId ) => {
      if(state.isMessangerChat){
        return state.messageInput
      } else if( chatId ){
        return state.widgetChatMessageInput[chatId]
      }
    },
    typingUsersIndicatorText : ( state ) => ( chatId ) => {
      let chatInfo = '';
      if(state.isMessangerChat){
        chatInfo = state.chatInfo
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      }

      let existKey  = 'typingUsers' in chatInfo
      let typeIndicator = '', typingUsers = '', totalUsers = 0;
      if(existKey){
        let users   = chatInfo.typingUsers;
        totalUsers  = users.length;
        if( totalUsers ){
            let selectUsers = users.slice(0,3);
            if( totalUsers == 1 ){
              typeIndicator = 'isTypingText';
              typingUsers   = selectUsers[0];
            } else if( totalUsers == 2 || totalUsers == 3 ){
              typeIndicator = 'areTypingText';
              typingUsers   = selectUsers.join(', ');
            } else {
              typeIndicator = 'moreTypingText';
              typingUsers   = selectUsers.join(', ');
            }
        }
      }
      return { typeIndicator, typingUsers , totalUsers};
    },
    isDisabledFooter : ( state ) => ( chatId ) => {
      let chatInfoId = '';
      if(state.isMessangerChat){
        chatInfoId = state.chatInfo.chatId
      } else if( chatId ){
        chatInfoId = chatId;
      }
      if ( state.messagesList[chatInfoId] ) {
        let message = state.messagesList[chatInfoId].messages.at(-1);
        if (message) {
          return message.messageType == 5;
        }
      }
      return false;
    },
    loadChat : ( state ) => ( chatId = '' ) => {
      if(state.isMessangerChat){
        if(state.messagesList[state.chatInfo.chatId]){
          return state.messagesList[state.chatInfo.chatId].loadChat;
        }
      } else if ( chatId && state.messagesList[chatId] ) {
        return state.messagesList[chatId].loadChat;
      }
      return true;
    },
    messageLoading : ( state ) => ( chatId = '' ) => {
      if(state.isMessangerChat){
        if(state.messagesList[state.chatInfo.chatId]){
          return state.messagesList[state.chatInfo.chatId].messagesLoading;
        }
      } else if ( chatId && state.messagesList[chatId] ) {
        return state.messagesList[chatId].messagesLoading;
      }
      return false;
    },
    widgetChats : state => {
      if(!state.isMessangerChat){
        return Object.values(state.chatInfo).filter(chat => chat.isMinimize === false);
      }
      return [];
    },
    minimizeChats : state => {
      if(!state.isMessangerChat){
        return Object.values(state.chatInfo).reverse().filter(chat => chat.isMinimize === true);
      }
      return [];
    },
    getQuoteMessage : (state) => ( chatKey = '') => {
      let chatId = ''
      if(state.isMessangerChat){
        chatId = state.chatInfo.chatId
      } else {
        chatId = chatKey;
      }
      let quoteMsgId = state.quoteMessageId[chatId];
      if(quoteMsgId){
        let messagesList = state.messagesList[chatId];
        if(messagesList){
          return messagesList.messages.find((message) => message.messageId == quoteMsgId);
        }
      }
      return ''
    },
    getQuoteMessageId : (state) => ( chatKey = '') => {
      let chatId = ''
      if(state.isMessangerChat){
        chatId = state.chatInfo.chatId
      } else {
        chatId = chatKey;
      }
      return state.quoteMessageId[chatId];
      
    },
    muteChatNotify: (state) => ( infoKey = '') => { // infoKey requeired when wiget chat is on
      if(state.isMessangerChat){
        return state.chatInfo.muteNotification
      }else{
        let chatInformation = {...state.chatInfo[infoKey]};
        if(chatInformation){
          return chatInformation.muteNotification;
        }
      }
      return false;
    },
    messagesListData : ( state) => (chatKey = '') => {
      let cDate = null;
      let chatId = ''
      if(state.isMessangerChat){
        chatId = state.chatInfo.chatId
      } else {
        chatId = chatKey;
      }
      if ( state.messagesList[chatId] ) {
        return state.messagesList[chatId].messages.map(
          (message) => {
            const messageSentDate = new Date(message.timeStamp * 1000).toLocaleDateString();
            if (cDate !== messageSentDate) {
              var currentMessageDate = moment(new Date(message.timeStamp * 1000));
              var today = moment().endOf("day");
              let dateDifference = today.diff(currentMessageDate, "days");

              if (dateDifference == 0) {
                message.messageSentDate = TRANS.today_time_txt;
              } else if (dateDifference == 1) {
                message.messageSentDate = TRANS.yesterday_time_txt;
              } else if (dateDifference > 1 && dateDifference < 7) {
                message.messageSentDate = dateFormat(new Date(message.timeStamp * 1000), "dddd");
              } else {
                message.messageSentDate = dateFormat(new Date(message.timeStamp * 1000),"dddd, mmm d, yyyy");
              }
            } else {
              message.messageSentDate = "";
            }
            cDate = messageSentDate;
            message['chatId'] = chatId;
            return message;
          }
        );
      } else {
        return [];
      }
    },
    getChatInfo : (state) => ( chatId = '') => {
      let chatInfo = {}
      if( state.isMessangerChat){
        chatInfo = state.chatInfo;
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      } else if( state.selectedChatId ){ // for popUps
        chatInfo = state.chatInfo[state.selectedChatId];
      }
      if(chatInfo){
        return chatInfo
      } else {
        return {}
      }
    },
    chatInfoData : (state) => ( chatId = null ) => {
      if( state.isMessangerChat){
        return state.chatInfo;
      } else if(chatId) {
        return state.chatInfo[chatId];
      }
      return {}
    },
    memberDisable : state => ( chatId = null ) => {
      let chatInfo = {}
      if(state.isMessangerChat){
        chatInfo = state.chatInfo;
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      }

      let data        = { isDisable : false, disableText : '' }
      
      if( chatInfo.chatType == 2 && chatInfo.memberDisable ) {
        let messageData     = chatInfo.message;
            data.isDisable  = true;
        if( typeof messageData == "object" ){
          let notifyMemberIds = [];
          let type            = messageData.type;
          let membersUpdate   = chatInfo.membersUpdate;
          let translationText = type == 4 ? TRANS.leave_grp_member_txt : TRANS.remove_grp_member_txt;
              notifyMemberIds = messageData.memberIds.length ? messageData.memberIds : [];
          
          if( chatInfo.isSender ) {
              data.disableText = translationText.replace('“((username))”', TRANS.you);
          } else {
              data.disableText = translationText.replace('“((username))”', membersUpdate[notifyMemberIds[0]]);
          }
        }
      }
      return data
    },
    deleteBPGroup : state => ( chatId = null ) => {
      let chatInfo = {}
      if(state.isMessangerChat){
        chatInfo = state.chatInfo;
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      }

      let data = { isDeleted : false, disableText : '' }

      if( chatInfo.chatType == 2 && chatInfo.groupDetail ) {
        let groupDetail     = chatInfo.groupDetail;
        if( typeof groupDetail == "object" ){
          let bpGroupStatus = groupDetail.bpGroupStatus;
          let isbpgroup        = groupDetail.isbpgroup;
          data.disableText = TRANS.group_deleted;
          if(bpGroupStatus == 0 && isbpgroup){
            data.isDeleted = true;
          }
        }
      }
      return data
    },
    disableReply : state => ( chatId = null ) => {
      let chatInfo = {}
      if(state.isMessangerChat){
        chatInfo = state.chatInfo;
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      }
      if(chatInfo.chatType == 2 ) {
        let isDisable = chatInfo.userDisableReply;
        if(isDisable){ return true }
      }
      return false
    },
    getUserRole : state => ( chatId = null ) => {
      let chatInfo = {}
      if(state.isMessangerChat){
        chatInfo = state.chatInfo;
      } else if( chatId ){
        chatInfo = state.chatInfo[chatId];
      }
      let members = [];
      if(chatInfo.groupDetail){
        members = Object.values(chatInfo.groupDetail.memberAvatars)
        if(members.length){
          let result = members.find( user => Number(user.userId) == Number(state.userId) );
          if(result){
            return result.groupRole
          }
        }
      }
      return ''
    }
  },
  mutations: {
    ...Mutations,
    updateUserInfo( state, payload ){
      if('userId' in payload){
        Vue.set(state, 'userId', payload.userId);
      }
      if('userType' in payload){
        Vue.set(state, 'userType', payload.userType);
      }
      if('isSupportMember' in payload){
        Vue.set(state, 'isSupportMember', payload.isSupportMember);
      }
    },
    deleteConversationMessages(state, payload){
      payload.messagesIds.forEach( msgId => {
        let index = state.messagesList[payload.chatId]['messages'].findIndex( message => msgId == message['messageId']);
        if(index > -1 ){
          Vue.delete(state.messagesList[payload.chatId]['messages'], index);
        }
      });
    },
    updateQuoteMessageId(state, payload){
      Vue.set(state.quoteMessageId,payload.chatId, payload.messageId)
    },
    deleteRecord(state, payload){
      if((payload.recordList in state) && (payload.key in state[payload.recordList]) ){
        Vue.delete(state[payload.recordList],payload.key)
      }
    },
    removeQuote(state, payload){
      Vue.delete(state.quoteMessageId,payload.chatId)
    }, 
    updateMessageInput(state, payload){
      if(state.isMessangerChat){
        Vue.set(state, 'messageInput', payload.text);
      } else if(payload.chatId) {
        Vue.set(state.widgetChatMessageInput, payload.chatId, payload.text);
      }
    },
    updateSearch( state, payload){
      state[payload.search] = payload.searchValue;
    },
    getGroupMembers( state, payload){
      state.groupMembers = payload;
    },
    updateRecordList( state, payload){
      state[payload.RecordList] = payload.RecordListValue;
    },
    // update the offset of list tabs
    updateOffset( state, payload){
      state[payload.offset] = payload.offsetValue;
    },
    //update flag the first time load tab data
    updateListFlag( state, payload){
      state[payload.flag] = payload.flagValue;
    },
    // Reject the invitation
    declineInvite(state, payload ){
      Vue.delete(state.requestList, payload.chatId);
      Vue.set(state.contactList, payload.chatId, payload)
    },
    blockUser( state, payload ){
      Vue.delete(state.requestList, payload.chatId)
      Vue.set(state.blockList, payload.chatId, payload);
    },
    acceptInvite( state, payload ){
      let chatId = payload.chatId;
      Vue.delete(state.requestList, chatId);
      Vue.set(state.friendList, chatId, payload );
      if(state.isMessangerChat){
        Vue.set(state, 'chatInfo', payload);
      } else {
        payload['isMinimize'] = false;
        Vue.set(state.chatInfo, chatId, payload)
      }
      state.isEmptyView       = false;
      state.isOpenMessagesScr = true;
      jQuery('.at-chat').addClass('at-opnchatbox');
    },
    toggleMessagesScr( state, payload ){
      state.isEmptyView        = payload.isEmptyView;
      state.isOpenMessagesScr  = payload.isOpenMessagesScr;
    },
    UpdateMessageList( state, payload ){
      let chatId = payload.chatId;
      let data = {
        messages  : payload.messages,
        loadChat  : payload.loadChat,
        messagesLoading  : false,
      }
      if(state.isAdminChat){
        data['senderId'] = payload.senderId;
      }

      let scrollTop = false;
      if( Object.prototype.hasOwnProperty.call(state.messagesList, chatId ) ) {
        state.messagesList[chatId].messages  = data.messages;
        state.messagesList[chatId].loadChat  = data.loadChat;
        state.messagesList[chatId].messagesLoading  = false;
        scrollTop = true;
      } else {
        Vue.set(state.messagesList, chatId, data )
      } 
      //for media attachments
    
        let attachments  = payload.mediaAttachments ? payload.mediaAttachments : [];
      
        if( Object.prototype.hasOwnProperty.call(state.messagesList, chatId ) ){

          if(attachments.length > 20){
            state.messagesList[chatId]['chatMediaOffset'] = 20;
          }

          if(state.messagesList[chatId].mediaAttachments){
          attachments = attachments.concat(state.messagesList[chatId].mediaAttachments);
          }

          if(attachments.length){
            state.messagesList[chatId]['mediaAttachments'] = attachments;
          }
        }
        if(scrollTop){ //top
          GuppyChatManager.messagesScrollList( payload.lastScrollTop, payload.chatId);
        }else{ //bottom
          GuppyChatManager.messagesScrollList( 0, payload.chatId);
        }
    },
    UpdateChatMediaAttachments(state, payload){
        let chatId = payload.chatId;
        let mediaAttachments  = payload.mediaAttachments ? payload.mediaAttachments : [];
        let data = new Object();
       
          if( Object.prototype.hasOwnProperty.call(state.messagesList, chatId ) ){
          if(mediaAttachments.length){
            data['chatMediaOffset'] = state.messagesList[chatId].chatMediaOffset + 20;
          }else{
            data['chatMediaOffset'] =  false;
          }

          if(state.messagesList[chatId].mediaAttachments){
            mediaAttachments =  state.messagesList[chatId].mediaAttachments.concat(mediaAttachments);
            data['mediaAttachments'] = mediaAttachments;
            data['messages']         = state.messagesList[chatId].messages;
            data['loadChat']         = state.messagesList[chatId].loadChat;
            Vue.set(state.messagesList, chatId, data); 
          }  
        }
    },
    userResponse( state, payload ){
      state.userResponseData = payload;
      state.isEmptyView = false;
      state.isOpenMessagesScr = false;
    },
    UpdateSelectUserStatus( state, payload ){
      if(payload.chatType == 1 ){
        if( payload.chatId in state.privateConversation ){
          Vue.set(state.privateConversation[payload.chatId], 'isBlocked' , payload.isBlocked);
        }
        if( payload.isBlocked ){
          if(payload.chatId in state.friendList ){
            Vue.delete(state.friendList, payload.chatId)
          }

          if( !Object.prototype.hasOwnProperty.call(state.blockList, payload.chatId ) ){
            Vue.set(state.blockList, payload.chatId, payload);
          }
        }else{
          if( Object.prototype.hasOwnProperty.call(state.blockList, payload.chatId ) ){
            Vue.delete(state.blockList, payload.chatId);
          }
          if( payload.friendStatus == 1){
            Vue.set(state.friendList, payload.chatId, payload)
          }else{
            if( !Object.prototype.hasOwnProperty.call(state.contactList, payload.chatId ) ){
              Vue.set(state.contactList, payload.chatId, payload)
            }
          }
        }
      }else if( payload.chatType == 0 ){ // for post case
        let listType  = 'postConversation';
        let chatId    = payload.chatId;
        if( payload.blockType == 0 ){     // for all posts
          let postConversationList  = {...state[listType]}
          let records               = Object.values(postConversationList);
          let postReceiverId        = payload.chatId.split('_')[1];
          let status                = payload.status;
          if(records.length){
            records.forEach( item => {
              if( item.postReceiverId == postReceiverId ){
                if( !item.isBlocked && item.blockedId != state.userId && payload.isBlocked){ // for block
                  Vue.set(state[listType][item.chatId],'isBlocked', payload.isBlocked)
                  Vue.set(state[listType][item.chatId],'blockedId', blockedId)
                  Vue.set(state[listType][item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state[listType][item.chatId],'friendStatus', status)
                }

                if( item.isBlocked && item.blockedId != state.userId && !payload.isBlocked ){ // for unblock
                  Vue.set(state[listType][item.chatId],'isBlocked', false)
                  Vue.set(state[listType][item.chatId],'blockedId', false)
                  Vue.set(state[listType][item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state[listType][item.chatId],'friendStatus', status)
                }

              }
            })
          }
        } else {
          if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
            Vue.set(state[listType][chatId],'isBlocked', payload.isBlocked)
            Vue.set(state[listType][chatId],'blockedId', payload.isBlocked ? blockedId : false)
            Vue.set(state[listType][chatId],'isOnline', payload.isOnline)
            Vue.set(state[listType][chatId],'friendStatus', status)
            Vue.set(state[listType][chatId],'muteNotification', payload.muteNotification)
          }
        }

      }

      let blockedId = payload.chatId.split('_');
      blockedId = payload.chatType == 1 ? blockedId[0] : blockedId[1];
      if(state.isMessangerChat){
        if( state.chatInfo.chatType == 1 ){ // this only for private case
          state.chatInfo = payload
        } else { // for post case
          state.chatInfo['blockedId'] = payload.isBlocked ? blockedId : '';
          state.chatInfo['blockerId'] = payload.isBlocked ? payload.blockerId : ''; 
          state.chatInfo['clearChat'] = payload.clearChat
          state.chatInfo['isBlocked'] = payload.isBlocked
          state.chatInfo['isOnline']  = payload.isOnline
        }
      } else {
        if(payload.chatId in state.chatInfo ){
          payload['isMinimize'] = false;
          let chatInfoData = state.chatInfo[payload.chatId]
          if(chatInfoData){
            chatInfoData['blockedId'] = payload.isBlocked ? blockedId : '';
            chatInfoData['blockerId'] = payload.isBlocked ? payload.blockerId : '';
            chatInfoData['clearChat'] = payload.clearChat
            chatInfoData['isBlocked'] = payload.isBlocked
            chatInfoData['isOnline']  = payload.isOnline
            Vue.set(state.chatInfo, payload.chatId, chatInfoData);
          }
        }
      }
    },
    setUserProfile( state, payload ){
      state.userProfile = payload.userInfo;
      if(payload.onlineFriendslist){
        Vue.set(state, 'onlineUsersList', payload.onlineFriendslist);
      }
    },
    receiveMessage(state, payload){
      let chatId    = payload.chatId;
      let chatType  = payload.chatData.chatType;
      let isSender  = payload.chatData.isSender;
      let listType = '';
      if(chatType == 0){
        listType = 'postConversation';
      }else if(chatType == 1){
        listType = 'privateConversation';
      }else if(chatType == 2){
        listType = 'groupConversation';
      }else if(chatType == 3){
        listType = 'adminSupportChats';
      }
      if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
        let  isMute =  state[listType][chatId].muteNotification;
        if(!state.userProfile.muteNotification && !isMute && !isSender){
          GuppyChatManager.playNotificationBell();
        }
      }
      if(state.messagesList[chatId]){
        let messageIndex = state.messagesList[chatId].messages.findIndex( (message => message.messageId == payload.chatData.messageId) );
        let randNumber = payload.chatData.metaData.randNum;
        let randNumberIndex = state.messagesList[chatId].messages.findIndex( 
          (message => {
            if(message.metaData){
              return message.metaData.randNum == randNumber
            } else {
              return false;
            }
          }) 
        );
        if(messageIndex == -1 && randNumberIndex == -1){
          state.messagesList[chatId].messages.push(payload.chatData);
          
          if( payload.chatData.messageType == 1 ){
            let attList =  payload.chatData.attachmentsData.attachments.map( item => {
              item['type'] = payload.chatData.attachmentsData.attachmentType;
              return item;
            });
            let data = {};
            let mediaAttachments = [];
            if(state.messagesList[chatId].mediaAttachments){
              mediaAttachments =  state.messagesList[chatId].mediaAttachments.concat(attList);
            } else {
              mediaAttachments = attList;
            }
            
            data['mediaAttachments'] = mediaAttachments;
            data['messages']         = state.messagesList[chatId].messages;
            data['loadChat']         = state.messagesList[chatId].loadChat;
            Vue.set(state.messagesList, chatId, data);
          }
        }
        if(jQuery('#message-wrap_'+chatId).length){
          jQuery('#message-wrap_'+chatId).animate({ scrollTop: jQuery('#message-wrap_'+chatId)[0].scrollHeight}, 800);
        }
      }
      if(state.isMessangerChat){ 
        if(state.chatInfo.chatId == payload.chatId && state.chatInfo.chatType == payload.chatData.chatType && !isSender){
          let  userId   = state.userId;
          let  userType = state.userType;
          let data = {
            chatId      : state.chatInfo.chatId,
            chatType    : payload.chatData.chatType,
            userId,
            userType
          }
          GuppyChatManager.updateSeenMessagesCounter(data);
        }
      }else{
        let openChatWindow = Object.values(state.chatInfo).filter(chat => chat.isMinimize == false && chat.chatId == chatId).length;
        if(openChatWindow != 0  && !isSender){
          let  userId   = state.userId;
          let  userType = state.userType;
          let data = {
            chatId      : state.chatInfo[chatId].chatId,
            chatType    : payload.chatData.chatType,
            userId,
            userType
          }
          GuppyChatManager.updateSeenMessagesCounter(data);
        }
      }
    },
    updateUnseenCounter(state, payload){
      let selectList = '';
      let msgCountKey = '';
      if(payload.chatType == 0){
        selectList  = 'postConversation';
        msgCountKey = 'postmessagesCount';
      } else if(payload.chatType == 1){
        msgCountKey = 'privateChatCount';
        selectList  = 'privateConversation';
      } else if (payload.chatType == 2 ){
        msgCountKey = 'groupChatCount';
        selectList  = 'groupConversation';
      } else if(payload.chatType == 3){ 
        msgCountKey = 'adminSupportChatCount';
        selectList  = 'adminSupportChats';
      } else if(payload.chatType == 5 ){  // for request count
        msgCountKey = 'requestCount';
        selectList  = 'requestList';
        
      }

      let updateCount = 0;
      if(state[msgCountKey] > 0 ){
        updateCount = state[msgCountKey] - payload.UnreadCount;
      }
      Vue.set(state, msgCountKey, updateCount);
      if( payload.chatId && state[selectList][payload.chatId] ){
        Vue.set(state[selectList][payload.chatId], 'UnreadCount', 0);
      }
    },

    appendMessage(state, payload){
      let chatId        = payload.chatId;
      let randNumber    = payload.metaData.randNum;
      if( state.messagesList[chatId] ) {
        let messageIndex = state.messagesList[chatId].messages.findIndex( 
          (message => {
            if(message.metaData){
              return message.metaData.randNum == randNumber
            } else {
              return false;
            }
          }) 
        );
        if( messageIndex > -1 ){
          Vue.set(state.messagesList[chatId]['messages'], messageIndex, payload);
        } else {
          state.messagesList[chatId]['messages'].push(payload);
        }

        GuppyChatManager.messagesScrollList( 0, payload.chatId);
        if( payload.messageType == 1 ){
          let attList =  payload.attachmentsData.attachments.map( item => {
            item['type'] = payload.attachmentsData.attachmentType;
            return item;
          });
          let data = {};
          let mediaAttachments = [];
          if(state.messagesList[chatId].mediaAttachments){
            mediaAttachments =  state.messagesList[chatId].mediaAttachments.concat(attList);
          } else {
            mediaAttachments = attList;
          }

          data['mediaAttachments'] = mediaAttachments;
          data['messages']         = state.messagesList[chatId].messages;
          data['loadChat']         = state.messagesList[chatId].loadChat;
          Vue.set(state.messagesList, chatId, data);
        }
      }
    },
    updateMessageStatus(state, payload){
      let chatId          = payload.chatId;
      let messageIds      = payload.messageIds;
      let messageCounter  = Number(payload.messageCounter);
      let chatType        = payload.chatType;
      let isSender        = payload.isSender;
      let listType = '';
      if(chatType == 0){
        listType = 'postConversation';
      }else if(chatType == 1){
        listType = 'privateConversation';
      }else if(chatType == 2){
        listType = 'groupConversation';
      }else if(chatType == 3){
        listType = 'adminSupportChats';
      }
      if(state.messagesList[chatId] && isSender){
        if(chatType == '0' || chatType == '1' || chatType == '3'){
          if(messageIds){
            for (let [id] of Object.entries(messageIds)) {
              let messageIndex = state.messagesList[chatId].messages.findIndex( (message => message.messageId == id));
              if(messageIndex > -1){
                state.messagesList[chatId].messages[messageIndex].messageStatus = 1;
              }
            }
          }
        }else if(chatType == '2'){
          if(payload.detail){
            for (let [id, item] of Object.entries(payload.detail)) {
              let messageIndex = state.messagesList[chatId].messages.findIndex( (message => message.messageId == id));
              if(messageIndex > -1){
                if(item.seen){
                  state.messagesList[chatId].messages[messageIndex].messageStatus = 1;
                }else{
                  let messageData = state.messagesList[chatId].messages[messageIndex];
                  messageData.messageSeenIds = item.seenids;
                  Vue.set(state.messagesList[chatId].messages, messageIndex, messageData);
                }
              }
            }
          }
        }
      }else if(!isSender){
        chatId = chatId.split('_');
        let counterlist = '';
        if(chatType == 0){
          let senderId = payload.senderId;
          counterlist = 'postmessagesCount';
          chatId = chatId[0] + '_' + senderId + '_'  + chatType; 
        }else if(chatType == 1){
          let senderId = payload.senderId;
          counterlist = 'privateChatCount';
          chatId = senderId + '_' + chatType;
        }else if(chatType == 2){
          counterlist = 'groupChatCount';
          chatId = chatId[0] + '_' + chatType;
        }else if(chatType == 3){
          counterlist = 'adminSupportChatCount';
          chatId = chatId[0] + '_' + chatType;
        }
        if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
          let counter = state[listType][chatId].UnreadCount - messageCounter;
          if(Number( counter ) >= 0 ){
            Vue.set( state[listType][chatId],'UnreadCount',  counter);
            Vue.set(state, counterlist, counter);
          
            if(!state.isMessangerChat){
              if(Object.prototype.hasOwnProperty.call(state.chatInfo, chatId ) ){
                Vue.set( state.chatInfo[chatId],'UnreadCount',  counter);
              }
            }
          }
        }
      }
    },
    updateUser(state, payload){
      let chatId    = payload.chatId;
      let status    = payload.status;
      let blockedId = 0;
      let isBlocked = payload.isBlocked;
      let chatType  = payload.chatType;
      let blockerId = payload.blockerId;
      let listType  = '';
      
      if(chatType == 0){
        listType = 'postConversation';
        blockedId = payload.blockedId;
      }else if(chatType == 1){
        listType = 'privateConversation';
        blockedId = payload.blockedId;
      }
      if(chatType == 1){
        if(status == 3){
          if(chatId in state.friendList ){
            Vue.delete(state.friendList, chatId)
          }
          if(state.userId == blockerId ){
            if( !Object.prototype.hasOwnProperty.call(state.blockList, chatId ) ){
              Vue.set(state.blockList, chatId, payload);
            }
          }
        }else if(status == 1) {
          if( !Object.prototype.hasOwnProperty.call(state.friendList, chatId ) ){
            Vue.set(state.friendList, chatId, payload);
          }
          if(state.userId == blockerId ){
            if(chatId in state.blockList ){
              Vue.delete(state.blockList, chatId)
            }
          }
        }
        if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
          state[listType][chatId].blockedId         = blockedId;
          state[listType][chatId].friendStatus      = status;
          state[listType][chatId].isBlocked         = isBlocked;
          state[listType][chatId].isOnline          = payload.isOnline;
          state[listType][chatId].muteNotification  = payload.muteNotification;
        }
      }else if( payload.chatType == 0 ){                            // for post case
        if( payload.blockType == 0 ){                               // for all posts
          let postConversationList  = {...state[listType]}
          let records               = Object.values(postConversationList);
          let postReceiverId        = chatId.split('_')[1];
          if(records.length){
            records.forEach( item => {
              if( item.postReceiverId == postReceiverId ){
                if( !item.isBlocked && item.blockedId != state.userId && payload.isBlocked){ // for block
                  Vue.set(state[listType][item.chatId],'isBlocked', payload.isBlocked)
                  Vue.set(state[listType][item.chatId],'blockedId', blockedId)
                  Vue.set(state[listType][item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state[listType][item.chatId],'friendStatus', status)
                }

                if( 
                    ( item.isBlocked && 
                      item.blockedId == state.userId && 
                      !payload.isBlocked && payload.isReceiver 
                    ) // unblock for receiver 
                    || 
                    (
                      item.isBlocked && 
                      item.blockedId != state.userId && 
                      !payload.isBlocked && !payload.isReceiver
                    ) // unblock for sender
                  ){
                  Vue.set(state[listType][item.chatId],'isBlocked', false)
                  Vue.set(state[listType][item.chatId],'blockedId', false)
                  Vue.set(state[listType][item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state[listType][item.chatId],'friendStatus', status)
                }
              }
            })
          }
        }else{
          if( Object.prototype.hasOwnProperty.call(state[listType], chatId ) ){
            Vue.set(state[listType][chatId],'isBlocked', payload.isBlocked)
            Vue.set(state[listType][chatId],'blockedId', payload.isBlocked ? blockedId : false)
            Vue.set(state[listType][chatId],'isOnline', payload.isOnline)
            Vue.set(state[listType][chatId],'friendStatus', status)
            Vue.set(state[listType][chatId],'muteNotification', payload.muteNotification)
          }
        }
      }

      if(state.isMessangerChat){
        if(state.chatInfo.chatId == chatId && state.chatInfo.chatType == chatType){
          state.chatInfo.isBlocked                = isBlocked;
          state.chatInfo['blockedId']             =  blockedId;
          state.chatInfo['friendStatus']          = status;
          state.chatInfo['muteNotification']      = payload.muteNotification;
        }
      }else{ 
        if(payload.chatType == 0 && payload.blockType == 0 ){
          let records         = Object.values(state.chatInfo);
          let postReceiverId  = chatId.split('_')[1];
          if ( records.length ){
            records.forEach ( item => {
              if ( item.postReceiverId == postReceiverId ){
                if( !item.isBlocked && item.blockedId != state.userId && payload.isBlocked){ // for block
                  Vue.set(state.chatInfo[item.chatId],'isBlocked', payload.isBlocked)
                  Vue.set(state.chatInfo[item.chatId],'blockedId', payload.isBlocked ? blockedId : false)
                  Vue.set(state.chatInfo[item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state.chatInfo[item.chatId],'muteNotification', payload.muteNotification)
                  Vue.set(state.chatInfo[item.chatId],'friendStatus', status)
                }

                if( 
                    ( 
                      item.isBlocked && 
                      item.blockedId == state.userId && 
                      !payload.isBlocked && payload.isReceiver
                    ) // unblock for receiver
                    || 
                    ( 
                      item.isBlocked && 
                      item.blockedId != state.userId && 
                      !payload.isBlocked && !payload.isReceiver 
                    ) // unblock for sender
                  ) {
                  Vue.set(state.chatInfo[item.chatId],'isBlocked', false)
                  Vue.set(state.chatInfo[item.chatId],'blockedId', false)
                  Vue.set(state.chatInfo[item.chatId],'isOnline', payload.isOnline)
                  Vue.set(state.chatInfo[item.chatId],'muteNotification', payload.muteNotification)
                  Vue.set(state.chatInfo[item.chatId],'friendStatus', status)
                }
              }
            })
          }
        } else if( Object.prototype.hasOwnProperty.call(state.chatInfo, chatId ) ){
          Vue.set(state.chatInfo[chatId], 'isBlocked', isBlocked);
          Vue.set(state.chatInfo[chatId], 'blockedId', isBlocked ? blockedId : false);
          Vue.set(state.chatInfo[chatId], 'friendStatus', status);
          Vue.set(state.chatInfo[chatId], 'muteNotification', payload.muteNotification);
          Vue.set(state.chatInfo[chatId], 'muteNotification', payload.muteNotification);
        }
      }
    },
    deleteMessageStatus(state, payload){
      let messageId     = payload.messageId;
      let chatId        = payload.chatId;
      let chatType      = payload.chatType;
      if( state.messagesList[chatId] ){
        let messageIndex = state.messagesList[chatId].messages.findIndex( (message => message.messageId == messageId));
        if(messageIndex > -1){
          state.messagesList[chatId].messages[messageIndex].messageStatus = 2;
        }
      }
      if(!state.isMessangerChat && Object.prototype.hasOwnProperty.call(state.chatInfo, chatId )){
        let UnreadCount = state.chatInfo[chatId].UnreadCount;
        UnreadCount =  UnreadCount - 1;
        if(UnreadCount >= 0 ){
          Vue.set( state.chatInfo[chatId],'UnreadCount',  UnreadCount);
        }
      }
      let listType = '';
      let counterType = '';
      let counter = 0;
      if(chatType == 0){
        counter = state.postmessagesCount - 1;
        counterType = 'postmessagesCount';
        listType = 'postConversation';
      }else if(chatType == 1){
        counter = state.privateChatCount - 1;
        counterType = 'privateChatCount';
        listType = 'privateConversation';
      }else if(chatType == 2){
        counter = state.groupChatCount - 1;
        counterType = 'groupChatCount';
        listType = 'groupConversation';
      }
      if(counter >= 0 ){
        Vue.set(state, counterType, counter);
        Vue.set(state[listType][chatId],'UnreadCount', counter);
      }
    },
    clearGuppyChat(state, payload){
      let chatId    = payload.chatId;

      if( state.messagesList[chatId] ){
        state.messagesList[chatId]['messages'] = [];
        state.messagesList[chatId].loadChat = false;
      }
      let stateList = '';
      if( payload.chatType == 0 ){
        stateList = ['postConversation'];
      } else if( payload.chatType == 1 ) {
        stateList = ['privateConversation', 'friendList']; // also update in friend list
      } else if( payload.chatType == 2 ){
        stateList = ['groupConversation'];
      }

      stateList.forEach(item=>{
        if( state[item][chatId] ){
          Vue.set(state[item][chatId],'clearChat', true)
        }
      });

    },
    updateMessageLoadingFlag(state, payload){
      if( state.messagesList[payload.chatId] ){
        Vue.set(state.messagesList[payload.chatId], 'messagesLoading', payload.flagValue);
      } else {
        let data ={
          messages          : null,
          mediaAttachments  : null,
          loadChat          : true,
          messagesLoading   : payload.flagValue,
        }
        Vue.set(state.messagesList,payload.chatId, data)
      }
    },
    updateMuteChatNotify(state, payload){
      let muteType = payload.muteType;
      let stateList = []; 
      if( muteType == 0 ) {
        if( payload.chatType == 0 ){
          stateList = ['postConversation'];
        } else if( payload.chatType == 1 ) {
          stateList = ['privateConversation', 'friendList']; // also update in friend list
        } else if( payload.chatType == 2 ){
          stateList = ['groupConversation'];
        }
        if( state.isMessangerChat ){
          if(state.chatInfo.chatId == payload.chatId){
            Vue.set(state.chatInfo, 'muteNotification', payload.isMute);
          }
        } else {
          if(state.chatInfo[payload.chatId]){
            state.chatInfo[payload.chatId]['muteNotification'] = payload.isMute;
          }
        }
        stateList.forEach( item => {
          if( state[item][payload.chatId] ){
            Vue.set(state[item][payload.chatId],'muteNotification', payload.isMute)
          }
        });
      } else {
        state.userProfile.muteNotification = payload.isMute; 
      }

    },
    updateTypeIndicator(state, payload){
      let data = {};
      let chatKey = payload.chatId;
      if(state.isMessangerChat){
        data = JSON.parse(JSON.stringify(state.chatInfo));
      }else{
        if(payload.chatType == 0){
          chatKey = chatKey.split('_');
          chatKey = chatKey[0] + '_' + payload.senderId + '_' + payload.chatType;
        }else if(payload.chatType == 1){
          chatKey = payload.senderId + '_' + payload.chatType;
        }else if(payload.chatType == 3){
          chatKey = chatKey.split('_');
          chatKey = payload.senderId + '_'+ payload.chatType;
        }
        if(Object.prototype.hasOwnProperty.call(state.chatInfo, chatKey ) ){
          data = JSON.parse(JSON.stringify(state.chatInfo[chatKey]));
        }
      }
      if(Object.keys(data).length){
        let isExist = 'typingUsers' in  data;
        if( !isExist ){ data['typingUsers'] = []; }
        
        if( payload.chatType == 0 
          || payload.chatType == 1 
          || payload.chatType == 3 
        ){
          let senderId =  data.chatId;
          senderId = senderId.split('_');
          if(payload.chatType == 0){
            senderId = senderId[1];
          }else{
            senderId = senderId[0];
          }
         
          if( senderId == payload.senderId && data.chatType == payload.chatType){
            if(payload.text){
              data['typingUsers'] = [];
              data.typingUsers.push(payload.userName);
            }else{
              let index = data.typingUsers.indexOf(payload.userName);
              if ( index > -1){ data.typingUsers.splice(index, 1); }
            }
          }else{
            let index = data.typingUsers.indexOf(payload.userName);
            if ( index > -1){ data.typingUsers.splice(index, 1); }
          } 
        }else if(payload.chatType == 2){
          if(data.chatId == payload.chatId && data.chatType == payload.chatType){
            if(payload.text ) {
              if(data.typingUsers.indexOf(payload.userName) == -1 ){
                data.typingUsers.push(payload.userName);
              }
            }else {
              let index = data.typingUsers.indexOf(payload.userName);
              if ( index > -1){ data.typingUsers.splice(index, 1); }
            }
          }else{
            let index = data.typingUsers.indexOf(payload.userName);
            if ( index > -1){ data.typingUsers.splice(index, 1); }
          }
        }
        if(state.isMessangerChat){
          Vue.set(state.chatInfo, 'typingUsers', data.typingUsers);
        }else{
          Vue.set(state.chatInfo[chatKey], 'typingUsers', data.typingUsers);
        }
      }
    },
    deleteGroup( state, payload ) {
      Vue.delete(state.groupConversation, payload.chatId);
      if(state.isMessangerChat){
        Vue.set(state, 'isEmptyView', true);
      } else {
        Vue.set(state, 'selectedChatId','');
        Vue.delete(state.chatInfo,payload.chatId);
      }
    }
  },
  actions: {
    ...Actions,
    unBlockUser (context, payload) {
      context.commit('unBlockUser', payload)
    },
    toggleMessagesScr(context, payload ) {
      context.commit('toggleMessagesScr', payload)
    },
    userResponse( context, payload ) {
      context.commit('userResponse', payload)
    },
    setUserProfile( context, payload ) {
      context.commit('setUserProfile', payload)
    },
    clearGuppyChat(context, payload){
      context.commit('clearGuppyChat', payload)
    },
    UpdateMessageList( context, payload ) {
      let state         = context.state;
      let chatData      = payload.chatData;
      let chatId        = chatData.chatId;
      let receiverId    = chatData.receiverId;
      let chatType      = Number(chatData.chatType);
      let groupId       = chatData.groupId;
      let userId        = state.userId;
      let postId        = chatData.postId;
      let messageData   = [];
      let offset        = 0;
      let loadChat      = true;
      let userType      = state.userType;
      let currentMessageList  = [];
      let stringChatType      = GuppyChatManager.getChatType(chatType);
      
      if(state.messagesList[chatId]){
        currentMessageList = state.messagesList[chatId].messages;
        if(currentMessageList.length){
          context.commit('updateMessageLoadingFlag', { flagValue : true, chatId })
          offset = currentMessageList[0].messageId;
        }
      }

      Vue.axios.get(
        'load-guppy-chat?offset=' +offset +
        '&receiverId='+ receiverId +
        '&userId='+ userId +
        '&chatType='+ chatType +
        '&groupId='+ groupId +
        '&postId='+ postId +
        '&userType=' + userType
        ).then( response => {
        if( response.data.type == 'success' ){
          if( !response.data.chatMessages.length ) {
            loadChat = false;
          }
          if( currentMessageList.length ){
            messageData = [].concat(response.data.chatMessages, currentMessageList);
          } else {
            messageData = response.data.chatMessages;
          }
          let senderId = '';
          if(response.data.senderId){
            senderId = response.data.senderId
          }
          let data = {
            type                : stringChatType,
            messages            : messageData,
            mediaAttachments    : response.data.mediaAttachments,
            lastScrollTop       : payload.lastScrollTop,
            messagesLoading     : true,
            loadChat,
            chatId,
            senderId
          }

          if(typeof response.data.muteNotfication != 'undefined'){
            data['muteNotfication'] = response.data.muteNotfication;
          }
          context.commit('UpdateMessageList', data)

        }
      }).catch( error => {
        console.log( error )
      });
    },
    updateConversationMessage( context, payload ) {
      context.commit('updateConversationMessage', payload)
    },
    updateMessageStatus( context, payload ) {
      context.commit('updateMessageStatus', payload)
    },
    deleteMessageStatus( context, payload ) {
      context.commit('deleteMessageStatus', payload)
    },
    getGroupMembers( context, payload ) {
      context.commit('getGroupMembers', payload)
    },
    initializePusher(context, payload){
      let pusher = new Pusher(payload.pusherKey, {
        cluster: payload.pusherCluster,
        auth: {
          params: {
            userId  : payload.userId,
          }
        },
        authEndpoint: payload.restApiUrl+'channel-authorize',
      });
      
      if(pusher){
        let channel = pusher.subscribe('presence-user-'+payload.userId);
        context.state.pusher = pusher;
        channel.bind('recChatData', function (data) {
          context.commit('receiveMessage', data);
          context.commit('updateConversationMessage', data);
          if(data.messagelistData.isOnline && data.messagelistData.chatType == 1){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.messagelistData.chatId,
              chatType:         data.messagelistData.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.messagelistData.userName,
              userAvatar:       data.messagelistData.userAvatar,
              muteNotification: data.messagelistData.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.messagelistData.chatId, userfriendList)
          }
        });

        channel.bind('updateUser', function (data) {
          context.commit('updateUser', data);
          if(data.status == 3 && context.state.onlineUsersList[data.chatId]){
            Vue.delete(context.state.onlineUsersList, data.chatId);
          }else if(data.status == 1 && data.isOnline){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.chatId,
              chatType:         data.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.userName,
              userAvatar:       data.userAvatar,
              muteNotification: data.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.chatId, userfriendList)
          }
        });

        channel.bind('clearChat', function (data) {
          context.commit('clearGuppyChat', data);
        });

        channel.bind('deleteGroup', function (data) {
          context.commit('deleteGroup', data);
        });

        // update ChatMuteNoification status
        channel.bind('updateMuteChatNotify' , function (data) {
          context.commit('updateMuteChatNotify', data);
        });
        
        channel.bind('senderChatData', function (data) {
          context.commit('receiveMessage', data);
          context.commit('updateConversationMessage', data);
          if(data.messagelistData.isOnline && data.messagelistData.chatType == 1){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.messagelistData.chatId,
              chatType:         data.messagelistData.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.messagelistData.userName,
              userAvatar:       data.messagelistData.userAvatar,
              muteNotification: data.messagelistData.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.messagelistData.chatId, userfriendList)
          }
        });

        channel.bind('updateMessage', function (data) {
          context.commit('updateMessageStatus', data);
        });
        channel.bind('deleteMessage', function (data) {
          context.commit('deleteMessageStatus', data);
          context.commit('updateConversationMessage', data);
        });
        channel.bind('groupChatData', function (data) {
          if(typeof data.chatData != 'undefined'){
            context.commit('updateGroupChatData', data);
            context.commit('receiveMessage', data);
          }
          context.commit('updateConversationMessage', data);
        });
        channel.bind('isTyping', function (data) {
          context.commit('updateTypeIndicator', data);
        });

        channel.bind('updateOnlineStatus', function (data) {
          Vue.set(context.state.onlineUsersList, data.chatId, data);
        });

        channel.bind('updateUserOnlineFriends', function (data) {
          Vue.set(context.state.onlineUsersList, data.chatId, data);
        });

        channel.bind('userOffline' , function (userId) {
          if(userId){
            let chatKey = userId+'_1';
            if(context.state.onlineUsersList[chatKey]){
              Vue.delete(context.state.onlineUsersList, chatKey);
            }
          }
        });
      }
    },
    initializeSocket(context, settings){
      let socket = SocketioService.socketConnection(settings.authToken);
      if(socket){

        // add user to socket 
        if(settings.userId){
          let id = settings.userId;
          SocketioService.connectUser(id);
        }

        // listening for sending data and update receiver 
        socket.on('receiverChatData' , (data) => {
          context.commit('receiveMessage', data);
          context.commit('updateConversationMessage', data);
          if(data.messagelistData.isOnline && data.messagelistData.chatType == 1){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.messagelistData.chatId,
              chatType:         data.messagelistData.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.messagelistData.userName,
              userAvatar:       data.messagelistData.userAvatar,
              muteNotification: data.messagelistData.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.messagelistData.chatId, userfriendList)
          }
        });

        // listening for sending data and update sender 
        socket.on('senderChatData' , (data) => {
          context.commit('receiveMessage', data);
          context.commit('updateConversationMessage', data);
          if(data.messagelistData.isOnline && data.messagelistData.chatType == 1){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.messagelistData.chatId,
              chatType:         data.messagelistData.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.messagelistData.userName,
              userAvatar:       data.messagelistData.userAvatar,
              muteNotification: data.messagelistData.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.messagelistData.chatId, userfriendList)
          }
        });

        // update message status 
        socket.on('updateMsgStatus' , (data) => {
          context.commit('updateMessageStatus', data);
        });

        // delete message from sender 
        socket.on('deleteSenderMessage' , (data) => {
          context.commit('deleteMessageStatus', data);
          context.commit('updateConversationMessage', data);
        });

        // delete message from receiver  
        socket.on('deleteReceiverMessage' , (data) => {
          context.commit('deleteMessageStatus', data);
          context.commit('updateConversationMessage', data);
        });

        // is typing 
        socket.on('isTyping' , (data) => {
          context.commit('updateTypeIndicator', data);
        });

        // update user status
        socket.on('updateUser' , (data) => {
          context.commit('updateUser', data);
          if(data.status == 3 && context.state.onlineUsersList[data.chatId]){
            Vue.delete(context.state.onlineUsersList, data.chatId);
          }else if(data.status == 1 && data.isOnline){
            let userfriendList = new Object();
            userfriendList = {
              chatId:           data.chatId,
              chatType:         data.chatType,
              blockedId:        false,
              isBlocked:        false,
              isOnline:         true,
              userName:         data.userName,
              userAvatar:       data.userAvatar,
              muteNotification: data.muteNotification
            };
            Vue.set(context.state.onlineUsersList, data.chatId, userfriendList)
          }
        });

        // update ChatMuteNoification status
        socket.on('updateMuteChatNotify' , (data) => {
          context.commit('updateMuteChatNotify', data);
        });

        // update ChatMuteNoification status
        socket.on('clearChat' , (data) => {
          context.commit('clearGuppyChat', data)
        });

        // update ChatMuteNoification status
        socket.on('deleteGroup' , (data) => {
          context.commit('deleteGroup', data)
        });

        // update group status
        socket.on('groupChatData' , (data) => {
          if(typeof data.chatData != 'undefined'){
            context.commit('updateGroupChatData', data);
          }
          context.commit('updateConversationMessage', data);
        });

        // update group status
        socket.on('leaveGuppyGroup' , (data) => {
          if(typeof data.chatData != 'undefined'){
            context.commit('updateGroupChatData', data);
          }
          context.commit('updateConversationMessage', data);
        });

        socket.on('userConnected' , (payload) => {
          if(payload.userId == context.state.userId
            && GUPPYCHAT_SETTINGS.realTimeOption == 'socket' 
            && GUPPYCHAT_SETTINGS.socketEnable
            && context.state.userType > 0){
            Vue.axios.post('guppy-user-online',payload).then( response => {
              if( response.data.type == 'success' ){
                response.data.userId = payload.userId;
                SocketioService.updateOnlineStatus(response.data);
              }
            }).catch( error => {
              console.log( error )
            });
          }
        });

        socket.on('updateOnlineStatus' , (data) => {
          if(data){
            Vue.set(context.state.onlineUsersList, data.chatId, data)
          }
        });

        socket.on('updateUserOnlineFriends' , (data) => {
          if(data){
            Vue.set(context.state, 'onlineUsersList', data)
          }
        });

        socket.on('userOffline' , (userId) => {
          if(userId){
            let chatKey = userId+'_1';
            if(context.state.onlineUsersList[chatKey]){
              Vue.delete(context.state.onlineUsersList, chatKey);
            }
          }
        });
      }
    },
    triggerSocketEvents(context, data){
      if(GUPPYCHAT_SETTINGS.realTimeOption == 'socket' && GUPPYCHAT_SETTINGS.socketEnable){
        if(data.event == 'sendMessage'){
          SocketioService.sendMessage(data.payload);
        } else if(data.event == 'updateMuteChatNotify'){
          SocketioService.updateMuteChatNotify(data.payload);
        } else if(data.event == 'deleteGroup'){
          SocketioService.deleteGroup(data.payload);
        }else if(data.event == 'updateMsgStatus'){
          SocketioService.updateMsgStatus(data.payload);
        }else if(data.event == 'clearChat'){
          SocketioService.clearChat(data.payload);
        }else if(data.event == 'deleteMessage'){
          SocketioService.deleteMessage(data.payload);
        }else if(data.event == 'updateUser'){
          SocketioService.updateUser(data.payload);
        }else if(data.event == 'updateGuppyGroup'){
          SocketioService.updateGuppyGroup(data.payload);
        }else if(data.event == 'leaveGuppyGroup'){
          SocketioService.leaveGuppyGroup(data.payload);
        }
      }
    },
    triggerclientEvents(context, data){
      if(GUPPYCHAT_SETTINGS.realTimeOption == 'socket' && GUPPYCHAT_SETTINGS.socketEnable){
        if(data.event == 'isTyping'){
          SocketioService.isTyping(data.payload);
        }
      }else if(GUPPYCHAT_SETTINGS.realTimeOption == 'pusher' && GUPPYCHAT_SETTINGS.pusherEnable){
        if( data.event == 'isTyping'){
          if(canType) {
            RestApiManager.pusherTypeIndicator({userId : context.state.userId, ...data.payload});
            canType = false;
            setTimeout(function() {
              canType = true;
            }, throttleTime);
          }
        }
      }
    },
    initRealTimeChatSetting( context, payload ){
      if(GUPPYCHAT_SETTINGS.realTimeOption == 'pusher' && GUPPYCHAT_SETTINGS.pusherEnable){
        let settings = { 
           pusherKey     : GUPPYCHAT_SETTINGS.pusherKey,
           pusherCluster : GUPPYCHAT_SETTINGS.pusherCluster,
           restApiUrl    : window.wpguppy_scripts_vars.restapiurl,
           userId        : context.state.userId,
         };
         context.dispatch('initializePusher', settings);
       } else if(GUPPYCHAT_SETTINGS.realTimeOption == 'socket' && GUPPYCHAT_SETTINGS.socketEnable){
         let settings = { 
           authToken     : payload.authToken,
           userId        : context.state.userId,
         };
         context.dispatch('initializeSocket', settings);
       }
    }
  }

})