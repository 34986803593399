<template>
  <div class="at-chat_sidebar">
      <guppy-chat-private-list v-if="activeTab == 'private'"/>
      <guppy-chat-group-list v-else-if="activeTab == 'group'"/>
      <guppy-chat-post-list v-else-if="activeTab == 'posts'" />
      <guppy-chat-support-list v-else-if="activeTab == 'customer_support'" />
      <guppy-chat-sidebar-footer />
  </div> 
</template>

<script>
  import GuppyChatPostList          from "./GuppyChatPostConversation/GuppyChatPostList.vue";
  import GuppyChatGroupList         from "./GuppyChatConversationList/GuppyChatGroupList.vue";
  import GuppyChatPrivateList       from "./GuppyChatConversationList/GuppyChatPrivateList.vue";
  import GuppyChatSidebarFooter     from "./GuppyChatSidebarFooter/GuppyChatSidebarFooter.vue";
  import GuppyChatSupportList       from "./GuppyChatConversationList/GuppyChatSupportList.vue";
  import { GUPPYCHAT_SETTINGS }     from "../../../resources/constants";
  import { mapState }   from 'vuex';
  export default {
      name : "GuppyChatSidebar",
      components: {
        GuppyChatSidebarFooter,
        GuppyChatGroupList,
        GuppyChatPrivateList,
        GuppyChatPostList,
        GuppyChatSupportList
      },
      computed :mapState({
        activeTab : state => state.activeTab,
      }),
      beforeCreate(){
        let enableTabs = GUPPYCHAT_SETTINGS.enabledTabs;
        let defaultTab = enableTabs[0];
        if(enableTabs.includes(GUPPYCHAT_SETTINGS.defaultActiveTab)){
          defaultTab = GUPPYCHAT_SETTINGS.defaultActiveTab;
        }
        this.$store.commit('selectTab', defaultTab);
      },
  }
</script>
<style  scoped>
.at-userlist_tab{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 0px);
    overflow:hidden;
    border-right: 1px solid #eee;

}
.at-chat_sidebar {
  position: relative;

  -webkit-box-flex: 0;
  -ms-flex: 0 0 26.2%;
  flex: 0 0 26.2%;
  max-width: 26.2%;
  overflow: hidden
}
.at-chat_sidebar:before{
    width: 100%;
    position: absolute;
    content: '';
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
}
.at-ovelay:before{
    opacity: 1;
    visibility: visible;
    background-color: rgba(0,0,0,0.3);
    transition: all 0.6s ease-in-out;
}
</style>