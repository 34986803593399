<template>
    <div id="messages" class="at-userlist_tab at-userlist_tabv2">
        <div class="at-sidebarhead_search at-sidebarhead_searchv2">
            <div class="at-form-group">
                <i class="guppy-search"></i>
                <input
                    v-model.trim="search"
                    @input="searchConversation()"
                    type="search"
                    class="at-form-control"
                    name="search"
                    :placeholder="TRANS.search"
                />
            </div>
        </div>
        <div v-if="onlineUsersList.length && showOnlineUsersList" class="at-sidebarhead_search  at-recentonline">
            <h5 v-if="isMessangerChat && windowWidth >= 768">{{TRANS.recent_online_user_txt}}</h5>
            <ul  class="at-onlineusers" id="at-onlineusers">
                <li v-for="(user, index) in onlineUsersList" :key="index+'_'+user.chatId" @click.prevent="openChat(user)" class="at-userbar">
                    <figure class="at-singleimg">
                        <img :src="user.userAvatar" :alt="user.userName">
                        <span class="at-userstatus online"></span>
                    </figure>
                    <h3 v-if="user.userName">{{user.userName | capitalize }}</h3>
                </li>
            </ul>
        </div>
        <ul v-if="Object.keys(conversation).length" class="conversation-list" @scroll="conversationScrollHandler($event)">
            <guppy-chat-conversation-list-item 
                v-for="item in conversation" 
                :ConversationItemProps="item" 
                :key="item.chatId" 
            />
            <guppy-chat-inner-loader v-if="isLoading"/>
        </ul>
        <template v-else>
            <guppy-chat-loader v-if="isLoading"/>
            <guppy-chat-empty-view v-else :isSidebarProps="true" :listIcon="'guppy-message-square'"/> 
        </template>
    </div>
</template>

<script>
import GuppyChatInnerLoader from "../../GuppyChatLoader/GuppyChatInnerLoader.vue";
import GuppyChatLoader from "../../GuppyChatLoader/GuppyChatLoader.vue";
import GuppyChatConversationListItem from "./GuppyConversationListItem.vue";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import RestApiManager from "../../../resources/RestApiController";
import { GUPPYCHAT_SETTINGS } from "../../../resources/constants";

import { mapState } from 'vuex';
export default {
    name: "GuppyChatPrivateList",
    components:{ 
        GuppyChatConversationListItem,
        GuppyChatLoader,
        GuppyChatInnerLoader,
        GuppyChatEmptyView
    },
    data(){
        return {
            search :'',
            timer  : null,
            initScroll : false
        }
    },
    computed :{
        ...mapState({
            isMessangerChat   :   state => state.isMessangerChat,
            conversation      :   state => state.privateConversation,
            privateConvFlag   :   state => state.privateConvFlag,
            privateChatOffset :   state => state.privateChatOffset,
            noMorePrivateChat :   state => state.noMorePrivateChat,
            privateChatSearch :   state => state.privateChatSearch,
            windowWidth       :   state => state.windowWidth,
            onlineUsersList   :   state => {
                    if(Object.keys(state.onlineUsersList).length){
                        let users = Object.values(state.onlineUsersList);
                        return users.reverse();
                    }
                    return []
                },
        }),
        showOnlineUsersList(){
            return !GUPPYCHAT_SETTINGS.hideOnlineUserList
        },
    },
    watch : {
        onlineUsersList(){
            setTimeout(()=>{
                   const scrollUserList = document.querySelector('.at-onlineusers');
                    if(scrollUserList){
                        this.onlineUserListScroll();
                    }
            }, 100)
        }
    },
    methods:{
        searchConversation(){
            this.$store.commit('updateRecordList', {RecordList: 'noMorePrivateChat', RecordListValue: false});
            this.isScrolling        = false;
            clearTimeout(this.timer); 
            this.timer = setTimeout(()=>{
                this.updateListRecord( {} );
                this.$store.commit('updateOffset', { offset:'privateChatOffset', offsetValue: 0 });
                this.$store.commit('updateSearch', {search:'privateChatSearch', searchValue: this.search});
                this.getConversation();
                this.updateOffset();
            }, 800);
        },
        updateOffset(){
            let currentOffset = this.privateChatOffset + this.RecordLimit;
                this.$store.commit('updateOffset', {offset:'privateChatOffset', offsetValue: currentOffset});
        },
        async getConversation(){
            if ( !this.noMorePrivateChat ) {
                this.isLoading  = true;
                let response    = await RestApiManager.getConversation(this.privateChatOffset, this.search, 1);
                this.isLoading  = false;

                if (response.data.type == "success") { 
                    let currentMoreRecord = Object.keys(response.data.guppyMessageList).length == 0 ? true : false;
                    this.$store.commit('updateRecordList', {RecordList: 'noMorePrivateChat', RecordListValue: currentMoreRecord});
                    if(!this.privateConvFlag){
                        this.$store.commit('updateListFlag', {flag: 'privateConvFlag', flagValue:true});
                    }
                    this.updateListRecord( response.data.guppyMessageList );
                } else if(response.data.type == "error"){
                    this.alertBox('error', this.TRANS.error_title, response.data.message_desc);
                }
            }
        },
        updateListRecord( recList ){
            let data = {
                'list'      : recList,
                'listType'  : 'privateConversation',
                'isScrolling' : this.isScrolling
                }
            this.$store.commit('updateTabListRecord', data)
        },
        conversationScrollHandler(elem) {
            if (
                ((elem.target.offsetHeight + elem.target.scrollTop) + 1 ) >=
                elem.target.scrollHeight
            ) {
                this.isScrolling = true;
                this.getConversation();
                this.updateOffset();
            }
        },
        openChat( params ){
            params['chatType'] = 1;
            if(this.chatInfo){
                if(this.chatInfo.chatId != params.chatId){
                    let payload = {
                        userName   : this.userProfile.userName,
                        chatType   : this.chatInfo.chatType,
                        chatId     : this.chatInfo.chatId,
                        senderId   : params.chatId,
                        userType   : this.userType,
                        text       : '',
                        groupMembers : []
                    }
                    this.$store.dispatch('triggerclientEvents', {'event':'isTyping', payload});
                }
            }
            this.$store.commit('updateChatInfo', { data: params, userClick : true});
        },
        onlineUserListScroll(){
            const scrollUserList = document.querySelector('.at-onlineusers');
            scrollUserList.addEventListener('wheel', function(e) {
                const race = 15; // How many pixels to scroll

                if (e.deltaY > 0) // Scroll right
                    scrollUserList.scrollLeft += race;
                else // Scroll left
                    scrollUserList.scrollLeft -= race;
                    e.preventDefault();
            });
            let isDown = false;
            let startX;
            let scrollLeft;

            scrollUserList.addEventListener('mousedown', (e) => {
                isDown = true;
                scrollUserList.classList.add('active');
                startX = e.pageX - scrollUserList.offsetLeft;
                scrollLeft = scrollUserList.scrollLeft;
            });
            scrollUserList.addEventListener('mouseleave', () => {
                isDown = false;
                scrollUserList.classList.remove('active');
            });
            scrollUserList.addEventListener('mouseup', () => {
                isDown = false;
                scrollUserList.classList.remove('active');
            });
            scrollUserList.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - scrollUserList.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                scrollUserList.scrollLeft = scrollLeft - walk;
            });
        }
    },
    created(){
        if( !Object.keys(this.conversation).length || !this.privateConvFlag){
            this.getConversation();
            this.updateOffset();
        }
    },
    mounted(){
        this.search = this.privateChatSearch;
    },
};
</script>
<style scoped>
.at-onlineusers{
    margin: 0;
    display: flex;
    overflow: auto;
    margin: 0 -20px;
    list-style: none;
    padding: 0;
    height: auto !important;
    width: calc(100% + 40px);
    flex-direction: row !important;
}
.at-chat_previewfloat .at-onlineusers > li:first-child {
    margin-left: 0;
} 
.at-onlineusers::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.at-onlineusers > li{
    border: 0;
    flex: none;
    display: block;
    cursor: pointer;
    max-width: 82px;
    list-style-type: none;
    padding: 0 !important;
    background: transparent;
    margin-bottom: 0 !important;
}
.at-onlineusers > li:first-child{
    margin-left: 20px;
}
.at-onlineusers > li:last-child{
    margin-right: 20px;
}
.at-onlineusers .at-userstatus{
    right: 0;
    bottom: 0;
    border: 2px solid #fff;
}
.at-onlineusers .at-userstatus:before{
    width: 8px;
    height: 8px;
}
.at-onlineusers figure{
    max-width: 44px;
    position: relative;
    margin: 0 auto !important;
}
.at-onlineusers h3{
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0 0;
    overflow: hidden;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 7px;
}
.at-onlineusers .at-singleimg img{
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.at-messanger-chat .at-onlineusers .at-singleimg img{
    width: 44px;
    height: 44px;
    object-fit: cover;
}
.at-recentonline{
    width: 100%;
    position: relative;
    padding: 20px;
    background: #ffffff;
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    box-shadow: inset 0px -1px 0px 0px #eeeeee, inset 0px 0px 0px #eeeeee;

}
.at-recentonline h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    text-transform: inherit;
    margin-bottom: 10px;
    margin-top: 0;
}
.at-chat_sidebar .at-recentonline{
    box-shadow: inset 0px -1px 0px 0px #eeeeee, inset 0px 0px 0px #eeeeee;
}
</style>