<template>
    <div>
        <div class="at-messages" :class="{ 'at-message_sender': message.senderId == senderId }" :key="message.messageId" v-for="message in messagesList" >
            
            <div class="at-groupuserbar at-groupuserbarvtwo" :style="message.messageSentDate ? 'top :60px' : ''"
                v-if="[ '0', '1' ,'2' ,'3' ].includes(message.messageType)" @click="_stopPropagation">
                <input type="checkbox" @click.self="selectItem" :id="message.messageId" name="check" :checked="checkDeleteItem(message.messageId)" :value="message.messageId">
                <label :for="message.messageId" class="at-groupuserbar_content"></label>
            </div>
            <!-- for show date message -->
            <div v-if="message.messageSentDate" class="at-chatseparation" :key="message.messageId" >
                <span> {{ message.messageSentDate | AM_PM_Replacement}} </span>
            </div>
            <!-- for delete message template -->
            <template v-if="Number(message.messageStatus) == 2">
                <!-- for group message -->
                <div class="at-grp_receiver_msg">
                    <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                    <guppy-delete-message :messageProps="message" />
                </div>
            </template>

            <!-- for text message template-->
            <template v-else-if="Number(message.messageType) == 0">
                <!-- for group message -->
                <div class="at-grp_receiver_msg">  
                    <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                    <guppy-chat-text-message :messageProps="message" :disableReply="disableReply" @deleteMsgEvt="deleteChatMessage($event)" />
                </div>
            </template>

            <!-- for location message template -->
            <template v-else-if="Number(message.messageType) == 2">
                <div class="at-grp_receiver_msg">
                    <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                    <guppy-location-message @deleteMsgEvt="deleteChatMessage($event)" :messageProps="message" :disableReply="disableReply" />
                </div>
            </template>

            <!-- for notify message template -->
            <template v-else-if="Number(message.messageType) == 4">
                <guppy-chat-notify-message :chatInfo="chatInfo" :messageProps="message" />
            </template>

            <!-- for loading message template -->
            <template v-else-if="Number(message.messageType) == 5">
                <div class="at-message at-loadermsg">
                    <i class="guppy-loader at-spinericon"></i>
                    {{ TRANS.attachments_uploading }}
                </div>
            </template>

            <!-- media message templates -->
            <template v-else>
                <!-- for audio message template -->
                <template v-if="message.attachmentsData.attachmentType == 'audio'" >
                    <div class="at-grp_receiver_msg">
                        <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                        <guppy-audio-message :messageProps="message" :disableReply="disableReply" @deleteMsgEvt="deleteChatMessage($event)" />
                    </div>
                </template>

                <!-- for documents message attachments template -->
                <template v-if="message.attachmentsData.attachmentType == 'file'" >
                    <div class="at-grp_receiver_msg">
                        <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                        <guppy-file-message :messageProps="message" :disableReply="disableReply" @deleteMsgEvt="deleteChatMessage($event)" />
                    </div>
                </template>

                <!-- for image message attachments template -->
                <template v-if="message.attachmentsData.attachmentType == 'images'" >
                    <div class="at-grp_receiver_msg">
                        <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                        <guppy-image-message :totalRecord="message.attachmentsData.attachments.length" :messageProps="message" :disableReply="disableReply" />
                    </div>
                </template>

                <!-- for video message attachments template -->
                <template v-if="message.attachmentsData.attachmentType == 'video'" >
                    <div class="at-grp_receiver_msg">
                        <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                        <guppy-video-message :messageProps="message" :disableReply="disableReply" />
                    </div>
                </template>

                <!-- for voice note recording message attachments template -->
                <template v-if="message.attachmentsData.attachmentType == 'voice_note'" >
                    <div class="at-grp_receiver_msg">
                        <img class="at-group-avatar" :src="message.userAvatar" :alt="message.userName" />
                        <guppy-chat-voice-message :messageProps="message" :disableReply="disableReply" />
                    </div>
                </template>
            </template>

            <!-- for group chat display time template -->
            <span v-if="Number(message.messageType) != 4 && (message.chatType == 0 || message.chatType == 2)" class="at-message_time" >
                {{ getMessageTime(message.timeStamp) }}
            </span>

            <!-- for private chat display time template -->
            <span v-else class="at-message_time">
                {{ getMessageTime(message.timeStamp)  }}
            </span>
        </div>
    </div>
       
</template>

<script>
    import GuppyChatVoiceMessage from "../GuppyChatVoiceMessage/GuppyChatVoiceMessage.vue";
    import GuppyChatTextMessage from "../GuppyChatTextMessage/GuppyChatTextMessage.vue";
    import GuppyLocationMessage from "../GuppyLocationMessage/GuppyLocationMessage.vue";
    import GuppyChatNotifyMessage from "../GuppyChatNotifyMessage/GuppyChatNotifyMessage.vue";
    import GuppyDeleteMessage from "../GuppyDeleteMessage/GuppyDeleteMessage.vue";
    import GuppyAudioMessage from "../GuppyAudioMessage/GuppyAudioMessage.vue";
    import GuppyImageMessage from "../GuppyImageMessage/GuppyImageMessage.vue";
    import GuppyVideoMessage from "../GuppyVideoMessage/GuppyVideoMessage.vue";
    import GuppyFileMessage from "../GuppyFileMessage/GuppyFileMessage.vue";
    import GuppyChatManager from "../../../../resources/controller";
    export default {
    name: "GuppyChatMessagesList",
    components: {
        GuppyChatVoiceMessage,
        GuppyChatTextMessage,
        GuppyLocationMessage,
        GuppyDeleteMessage,
        GuppyAudioMessage,
        GuppyImageMessage,
        GuppyVideoMessage,
        GuppyFileMessage,
        GuppyChatNotifyMessage,
    },
    props : {
        'chatId':{
            type     : String,
            required : false,
            default : () => {
                return null
            }
        },
        'deleteConversations' : {
            type     : Array,
            required : false,
            default : () => {
                return []
            }
        }
    },
    data() {
        return {
        chatData: "",
        };
    },
    computed: {
        messagesList(){
            return this.$store.getters.messagesListData(this.chatId);
        },
        senderId(){
            if(this.$store.state.messagesList[this.chatId]){
                return this.$store.state.messagesList[this.chatId]['senderId'];
            }
            return '';
        },
        chatInfo(){
            return this.$store.getters.getChatInfo(this.chatId);
        },
        disableReply(){
            return this.$store.getters.disableReply(this.chatId);
        },
    },
    methods: {
        checkDeleteItem(messageId) { 
            let conversations = this.deleteConversations;
            let existItem     = conversations.find(item => item == messageId);
            return existItem ? true : false
        },
        selectItem(e){
            this.$emit('deleteItem', e.target.value)
        },
        _stopPropagation(e){
            e.stopPropagation();
        },
        getMessageTime(messageTimeStamp) {
            return GuppyChatManager.getMessageTime(messageTimeStamp);
        },
        deleteChatMessage(messageId) {
            let recordIndex   = this.messagesList.findIndex((message) => message.messageId == messageId);
            let deletMessage  = this.messagesList[recordIndex];
            deletMessage.messageStatus = 2;
            this.$set(this.messagesList, recordIndex, deletMessage);
        },
        scrollListToBottom(scrollHeight = 0) {
            GuppyChatManager.messagesScrollList(scrollHeight, this.chatInfo.chatId);
        },
        scrollListToPosition() {
            this.messagesScrollList(this.lastScrollTop);
        },
    },
    };
</script>