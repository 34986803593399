<template>
  <div>
    <div v-if="chatInfo.isBlocked && chatInfo.blockedId == userId" class="at-chatblockuser" >
      <span> {{ TRANS.you_are_blocked }} </span>
    </div>
    <div v-else-if="chatInfo.isBlocked && chatInfo.blockedId != userId" class="at-chatblockuser" >
      <runtime-template-compiler :template="unblockButtonText" />
    </div>
    <!-- in case of group disable footer area  -->
    <template v-else-if="memberDisable.isDisable">
      <div class="at-leftgroupinfo at-leftgroup-footer">
        <span>{{memberDisable.disableText}}</span>
      </div>
    </template>
    <!-- for BP group deleted -->
    <template v-else-if="deleteBPGroup.isDeleted">
      <div class="at-group-disabled"><span>{{deleteBPGroup.disableText}}</span></div>
    </template>
    <template v-else-if="disableReply(chatInfo.chatId) && (getUserRole == 0)">
      <div class="at-group-disabled"><span>{{TRANS.disable_reply_txt}}</span></div>
    </template>
    <!-- for support memeber  -->
    <template v-else-if="noLongerSupportMember">
      <div class="at-group-disabled"><span>{{TRANS.not_support_meber_txt}}</span></div>
    </template>
    <template v-else>
      <div class="at-replay" :class="{'at-replay_disable' : isDisabledFooter(chatInfo.chatId) }">
        <guppy-chat-quoted-messages @removeQuoteMessage="removeQuote();" v-if="quoteMessage" :quoteMsgProps="quoteMessage"/>
        <span v-html="typingUsers" class="at-typing"></span>
        <div class="at-widgetreply">
          <send-text-message :chatId="chatInfo.chatId"/>
          <a v-if="inputTypingText" @click.prevent="sendTextMessage()" href="javascript:void(0)" class="at-sendmsg-responsive" ><i class="guppy-send"></i></a>
        </div>
      </div>
      <ul class="at-chat_sidebar_footervtwo" :class="{'at-replay_disable' : isDisabledFooter(chatInfo.chatId) }">
        <li>
          <media-messages :chatId="chatInfo.chatId"/>
        </li>
        <li v-if="shareVoiceNote">
          <send-voice-message :chatId="chatInfo.chatId" />
        </li>
      </ul>
    </template>
  </div>
</template>
 
<script>
import Vue from "vue";
import GuppyChatQuotedMessages from "../GuppyChatMessages/GuppyChatQuotedMessages/GuppyChatQuotedMessages.vue";
import GuppyChatSendTextMessage from "../GuppyChatMessages/GuppyChatMessageFooter/GuppyChatSendTextMessage.vue";
import { GUPPYCHAT_SETTINGS , GUPPYCHAT_TRANSLATION } from "../../resources/constants";
import GuppyChatSendMediaMessage from "../GuppyChatMessages/GuppyChatMessageFooter/GuppyChatSendMediaMessage.vue";
import GuppyChatSendVoiceMessage from "../GuppyChatMessages/GuppyChatMessageFooter/GuppyChatSendVoiceMessage.vue";
import { mapGetters } from 'vuex';
import RuntimeTemplateCompiler from "vue-runtime-template-compiler"
Vue.use(RuntimeTemplateCompiler)

export default {
  name : "GuppywidgetChatBoxFooter",
  components:{
    SendTextMessage   : GuppyChatSendTextMessage,
    MediaMessages     : GuppyChatSendMediaMessage,
    SendVoiceMessage  : GuppyChatSendVoiceMessage,
    GuppyChatQuotedMessages,
  },
  props:{
    'chatInfo':{
        type: Object,
        required : true,
        default : ()=>{
          return new Object();
        }
      },
  },
  data: () => {
    return {
      shareVoiceNote : GUPPYCHAT_SETTINGS.shareVoiceNote,
    }
  },
  computed:{
    noLongerSupportMember(){
      if(this.chatInfo.chatType == 3 ){
        return !this.chatInfo.isSupportMember
      }
      return false;
    },
    inputTypingText(){
      return this.$store.getters.getMessageInput(this.chatInfo.chatId)
    }, 
    getUserRole(){
      return this.$store.getters.getUserRole(this.chatInfo.chatId)
    },
    memberDisable(){
      return this.$store.getters.memberDisable(this.chatInfo.chatId);
    },
    deleteBPGroup(){
      return this.$store.getters.deleteBPGroup(this.chatInfo.chatId);
    },
    disableReply(){
      return this.$store.getters.disableReply(this.chatInfo.chatId);
    },
    unblockButtonText() {
      let text        = GUPPYCHAT_TRANSLATION.blocked_user_message;
      let buttonText  = GUPPYCHAT_TRANSLATION.unblock_now;
      let button      = '<a href="javascript:;" @click.prevent="updateSelectChatId()" guppy-data-target="#blockuser">' + buttonText + " </a>";
      let updatedText = `<span>${text.replace("%Unblock%", button)}</span>`;
      return updatedText;
    },
     typingUsers(){
        let data            = this.$store.getters.typingUsersIndicatorText(this.chatInfo.chatId);
        let isTypingText    = GUPPYCHAT_TRANSLATION.is_typing
        let areTypingText   = GUPPYCHAT_TRANSLATION.are_typing
        let moreTypingText  = GUPPYCHAT_TRANSLATION.more_user_typing
        let typeIndicator = data.typeIndicator , typingUsers = data.typingUsers, totalUsers = data.totalUsers, typingText = '';
        if( totalUsers ){
          if( typeIndicator == 'isTypingText' && typingUsers ){
            typingText = `<em class="at-typing-name">${typingUsers}</em>${isTypingText}`;
          } else if( typeIndicator == 'areTypingText' && typingUsers ){
            typingText = `<em class="at-typing-name">${typingUsers}</em>${areTypingText}`;
          } else {
            let moreText = `<em class="at-typing-name">${typingUsers}</em>${moreTypingText}`;
            typingText = moreText.replace('((user_count))', Number(totalUsers)-3 )
          }
          typingText +='<em><img src="'+GUPPYCHAT_SETTINGS.typingIcon+'" alt="users typing"></em>';
        }
        return typingText
      },
      quoteMessage : function(){
        return this.$store.getters.getQuoteMessage(this.chatInfo.chatId)
      },
      quoteMessageId(){
        return this.$store.getters.getQuoteMessageId()
      },
    ...mapGetters({
      'disableReply'    : 'disableReply',
      'isDisabledFooter': 'isDisabledFooter',
    }),
  },
  methods:{
    sendTextMessage(){
      document.getElementById(`input-text-message-${this.chatInfo.chatId}`).focus();
      this.$root.$emit('sendMessage', {chatId : this.chatInfo.chatId});
    },
    updateSelectChatId() {
      this.$store.commit('updateSelectedChatId', {chatId : this.chatInfo.chatId})
    },
    removeQuote(){
      this.$store.commit('removeQuote', {chatId: this.chatInfo.chatId});
    },
  }
}
</script>
<style scoped>
.at-chat_sidebar_footervtwo{
    background-color: var(--secguppycolor);
    position: static;
    display: flex;
    padding: 0;
    margin: 0;
}
.at-chat_sidebar_footervtwo li {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    background: transparent;
    border: none;
    justify-content: center;
}
.at-chat_sidebar_footervtwo li .at-replay_upload ,.at-chat_sidebar_footervtwo li  .at-replay_audio{
  flex: 1;
}
.at-chat_sidebar_footervtwo li .at-recorddisabled a {
   background-color: var(--secguppycolor) !important;
}
  .at-floatchat_content .at-replay_msg {
    padding: 0;
}
.at-floatchat_content .at-replay_msg input {
    height: 40px;
    border-radius: 3px;
}
.at-replay_msg > a:not(.at-sendmsg) + input {
    padding-right: 60px;
}
.at-floatchat_content .at-replay_msg input {
    height: 40px;
    border-radius: 3px;
}
.at-floatchat_content .at-replay{
  display: block;
  border-top: 1px solid #DDDDDD;
  background-color: #f7f7f7;
}
.at-floatchat_content .at-replay_msg > a:not(.at-sendmsg) {
  right: 21px;
}
.at-floatchat_content .at-chat_sidebar_footer {
  position: static;
}
.at-floatchat_content .at-chat_sidebar_footer li {
  padding: 0 !important;
  width: 100% !important;
}
.at-floatchat_content .at-chat_sidebar_footer li > div{width: 100%;}
.at-floatchat_content .at-chat_sidebar_footer a {
  padding: 18px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.at-floatchat_content .at-chat_sidebar_footer a.active {
  color: #fff;
  background-color: #FF7300;
}
.at-floatchat_content .at-chat_sidebar_footervtwo.at-replay_disable > li {
      filter: opacity(0.5);
}
</style>